import React from "react";
import {Layout} from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './styles.css';
import Header from "./Header";
import SideMenu from "./SideMenu";
import BarInfo from "./BarInfo";
const {Content} = Layout;
export default (props) => {
    return (
        <Layout>
            <Header {...props} />
            <Layout>
                <SideMenu {...props}/>
                <Layout style={{padding: '0 24px 24px'}}>
                    <BarInfo {...props}/>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: "100%",
                            maxHeight: "100%",
                        }}
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

