import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {UpCaseFirst, GetRowOptions, IsNotSuperUser, FindFieldFromRecordWithData} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert} from "antd";
import {LockOutlined} from "@ant-design/icons";

const {Option} = Select;

class PbxSettingRegistryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Users: [],
            Companies: [],
            isLoading: false,
            component: "list"
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "manager/entity", "agent", this, false);
        await fetchDataEntity("Users", "manager/entity", "user", this, false);
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onDelete = () => {
        this.setState({
            component: "list"
        })
    }
    validateUsername = (username) => {
        for(let i in this.state.Users){
            const row =this.state.Users[i];
            if(row.Username ===username){
                return false
            }
        }
        return true;
    }
    cleanInputEmail=(str)=>{
        str = str.trim(str);
        str =str.replace(" ","");
        str = str.toLowerCase();
        return str;
    }
    onFinish = async (values) => {
        values.Email =this.cleanInputEmail(values.Email);
        values.Username =this.cleanInputEmail(values.Username);
        if(!this.validateUsername(values.Username)){
            return alert("Sorry we can't process username ready taken! choose another one")
        }
        if (!window.confirm("Are you sure you want to submit this range ?")) {
            return
        }


        let _this = this;
        let user = GetUserToken();
        let hub = {...values};
        let endpoint = "/manager/entity/insert/agent";
        await PostToPbx(hub, endpoint, async (data) => {
            console.log("):(--->onFinish Trunk response: ", data)
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , user submitted!")
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange = (e) => {

    }


    getColumns = () => {
        return [
            {id: "Org", label: "Client"},
            {id: "Name", label: "Name"},
            {id: "Surname", label: "Surname"},
            {id: "Username", label: "Username"},
            {id: "Email", label: "Email"},
            {id: "Phone", label: "Phone"},
            {id: "Role", label: "Role"},
        ];

    }

    renderList = () => {
        let user = GetUserToken();
        if (this.state.component !== "list") {
            return
        }
        let ls = []; //this.state.Data;
        console.log("):()----------------------------------------")
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            /*row.Org= row.Org;
            row.Org = FindFieldFromRecordWithData("CustomerNumber", row.Org, "Name", this.state.Companies);*/
            /**
             * let check for privilege
             *
             */
            //let comp = FindFieldFromRecordWithData("Name", row.OrgCode, "Name", this.state.Companies);
            if(user.Role==="super"){
                ls.push(row)
            }else{
                console.log(":>---> ",user.Org," == ",row.Org," >>> ",user.Org===row.Org," || ",)
                if(user.Org===row.Org ){
                    ls.push(row)
                }
            }

        }

        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Agent or Admin User")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }

        let companies = GetRowOptions("CustomerNumber", "Name", this.state.Companies);
        let roles = [{key: "agent", val: "Agent"}, {key: "admin", val: "Admin"}];

        let inputList = [
            {
                name: "Org",
                label: "Name of Company",
                type: "option",
                required: true,
                options: companies,
                message: "Please input your company name"
            },
            {
                name: "Role",
                label: "Role",
                type: "option",
                required: true,
                options: roles,
                message: "Please select your role"
            },
            {name: "Username", label: "Username", type: "text", required: true, message: "Please input your Username"},
            {
                name: "Password",
                label: "Password",
                type: "password",
                required: true,
                message: "Please input your Password"
            },
            {
                name: "ConfirmPassword",
                label: "Confirm Password",
                type: "password",
                required: true,
                message: "Please input your Confirm Password "
            },
            {name: "Name", label: "Name", type: "text", required: true, message: "Please input your  name"},
            {name: "Surname", label: "Surname", type: "text", required: true, message: "Please input your  Surname"},
            {name: "Email", label: "Email", type: "text", required: true, message: "Please input your Email"},
            {name: "Phone", label: "Phone", type: "text", required: true, message: "Please input your Phone"},

        ]


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };


        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New User"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    /*onChange={this.handleChange}*/
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>User Management</Title>
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(PbxSettingRegistryPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

