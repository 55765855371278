import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {
    UpCaseFirst,
    GetRowOptions,
    IsNotSuperUser,
    FindFieldFromRecordWithData,
    IsFindInArray
} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert} from "antd";
import {GetUserToken} from "../../../api/token";
const {Option} = Select;

class LeadSettingPoolPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Campaigns:[],
            Batchs: [],
            BatchInfos: [],
            isLoading: false,
            component: "list",
            Org: "",
            Campaign:"",
            Batch:"",
            BatchRef:"",
            Name:""
        }
    }

    componentDidMount = async () => {
       // IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "lead/entity", "batch-pool", this, false);
        await fetchDataEntity("Campaigns", "lead/entity", "batch-campaign", this, false);
        await fetchDataEntity("Batchs", "lead/entity", "batchs", this, false);
        await fetchDataEntity("BatchInfos", "lead/entity", "batch-info", this, false);
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onDelete =async (row) => {
        if (!window.confirm("Are you sure you want to remove this inbound queue ?")) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });
        let hub={
            Org:row.Org,
            Number:row.Number
        }
        let endpoint = "/lead/entity/remove/batch-pool";
        this.submitPost(hub, endpoint, "Tank you, Pool removed")

    }
    appendAgentDetail=(hub)=>{
        let agent = "";
        for(let i in this.state.Agents){
            const row =this.state.Agents[i];
            if(row.Username===hub.AgentRef){
                hub.Agent = row.FullName;
                hub.Phone = row.Phone;
                hub.Account = row.Email;
            }
        }
        return hub;

    }
    onFinish = async (values) => {
        if (!window.confirm("Are you sure you want to submit this Pool ?")) {
            return
        }
        let hub = {};
        hub.Org =values.Org;
        hub.Campaign=values.Campaign;
        const arr = values.Batch.split("--");
        hub.BatchName = arr[0];
        hub.BatchRef=arr[1];
        hub.PoolName = values.Name;
        console.log("onFinish pool ----> ",hub," > ",values);


        let endpoint = "/lead/pool/new";
        this.submitPost(hub, endpoint, "Tank you, Pool submitted")
    };
    submitPost = async (hub, endpoint, successMsg) => {
        let _this = this;
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            if(data.STATUS==="fail"){
                alert("Fail, could not insert your extension,try again")
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange = (value,key) => {
        this.setState({
            [key]: value,
        })
    }
    getColumns = () => {
        return [
            {id: "Client", label: "Company"},
            {id: "Code", label: "Code"},
            {id: "PoolName", label: "Pool Name"},
            {id: "CampaignDisplay", label: "Campaign"},
            {id: "BatchDisplay", label: "Batch"},
           /* {id: "Total", label: "Total"},*/
        ];
    }

    renderList = () => {
        let user = GetUserToken();
        if (this.state.component !== "list") {
            return
        }
        const state = this.state.Data;
        console.log("**** this.getMember > ", state)

        let ls = []; //this.state.Data;
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            let myCode = row.Org;
            row.Client = FindFieldFromRecordWithData("CustomerNumber", row.Org, "Name", this.state.Companies);
            row.CampaignDisplay = FindFieldFromRecordWithData("Code", row.Org, "Name", this.state.Campaigns);
            row.BatchDisplay = FindFieldFromRecordWithData("Code", row.BatchName, "Name", this.state.Batchs);

            /**
             * let check for privilege
             *
             */
            let comp = FindFieldFromRecordWithData("Name", myCode, "Name", this.state.Companies);
            if(user.Role==="super"){
                ls.push(row)
            }else{
                if(user.Org===myCode || user.Org===comp  ){
                    ls.push(row)
                }
            }

            //ls.push(row)
        }

        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Pool")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }

        const findBatchName=(code,ls)=>{
            for(let i in ls){
                const row=ls[i];
                if(row.Code===code){
                    return row.Name;
                }
            }
            return code
        }

        let campaigns=[],batchs=[],pools=[],batchInfos=[];
        for(let i in this.state.Campaigns){
            const row = this.state.Campaigns[i];
            if(row.Org ===this.state.Org){
                campaigns.push(row)
            }
        }
        for(let i in this.state.BatchInfos){
            const row = this.state.BatchInfos[i];
            if(row.Org ===this.state.Org){
                row.Code = row.BatchName+"--"+row.Ref;
                row.DisplayName = findBatchName(row.BatchName,this.state.Batchs)+" -- "+row.OrgDateTime;
                batchs.push(row)
            }
        }

        for(let i in this.state.Data){
            const row = this.state.Data[i];
            if(row.Org ===this.state.Org){
                pools.push(row)
            }
        }

        let companies = GetRowOptions("CustomerNumber", "Name", this.state.Companies);
        let campaignOptions=GetRowOptions("Code", "Name", campaigns);
        let batchOptions=GetRowOptions("Code", "DisplayName", batchs);

        let inputList = [
            {
                name: "Org",
                label: "Name of Company",
                type: "option",
                required: true,
                options: companies,
                message: "Please input your company name"
            },
            {
                name: "Campaign",
                label: "Campaign",
                type: "option",
                required: true,
                options:campaignOptions,
                message: "Please select your campaign"
            },
            {
                name: "Batch",
                label: "Batch",
                type: "option",
                required: true,
                options:batchOptions,
                message: "Please input your batch"
            },
            {name: "Name", label: "Pool Name", type: "text", required: false, message: "Please input your pool name"},
        ]


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };


        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New Extension"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    onChange={(e)=>this.handleChange(e,row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Pool Management</Title>
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(LeadSettingPoolPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}




