import React from "react";
import Connector from "../../../redux/connector";
import PageBuilder from "../../../components/web/Common/PageBuilder";

class LeadSettingTargetPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let StateStorage = [
            {stateField: "Data", moduleEntity: "lead/entity", table: "batch-agent-target", noCondition: false},
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Lead", label: "Lead"},
            {id: "Outbound", label: "Outbound"},
            {id: "Qualify", label: "Qualify"},
            {id: "TalkTime", label: "TalkTime"},
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
            /*{keyState: "Registries", targetKey: "Org", storeVariable: "registries"},
            {keyState: "Trunks", targetKey: "Org", storeVariable: "trunks"},
            {keyState: "Agents", targetKey: "Org", storeVariable: "agents"},
            {keyState: "Data", targetKey: "Org", storeVariable: "extensions"},*/
        ];

        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },

        ];

        let bldFields = [
            {
                label: "Agent",
                message: "Please select your agent",
                type: "option",
                StateKey: "Agent",
                required:true,
                options:[
                    {key:"all",val:"All Agent"}
                ]
            },
            {
                label: "Lead",
                message: "Please input your Lead",
                type: "float",
                StateKey: "Lead",
                required:true
            },
            {
                label: "Outbound",
                message: "Please input your Outbound",
                type: "float",
                StateKey: "Outbound",
                required:true
            },{
                label: "Qualify",
                message: "Please input your Qualify",
                type: "float",
                StateKey: "Qualify",
                required:true
            },{
                label: "TalkTime",
                message: "Please input your TalkTime",
                type: "float",
                StateKey: "TalkTime",
                required:true
            },
        ]

        return (
            <PageBuilder
                pageTitle={"Target"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Data"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Target"}
                postMsgResponse={"Thank you, supplier submitted!"}
                postMsgWarning={"Are you sure you want to submit this supplier"}
                postEndpoint={"/lead/entity/insert/batch-agent-target"}
            />
        )
    }

}

export default Connector(LeadSettingTargetPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
