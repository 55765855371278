import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {UpCaseFirst} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input,Select,Alert} from "antd";
import {LockOutlined} from "@ant-design/icons";

const { Option } = Select;

class SuperSettingCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            isLoading: false,
            component: "list"
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData()
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "client/entity", "company", this)
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onDelete = () => {
        this.setState({
            component: "list"
        })
    }
    onFinish = async (values )=> {
        console.log('Success2:', values);
        if(!window.confirm("Are you sure you want to submit?")){
            return
        }
        let _this = this;
        let user = GetUserToken();
        let hub = {...values};
        let endpoint = "/client/entity/insert/company";
        await PostToPbx(hub, endpoint, async (data) => {
            console.log("):(--->onFinish company response: ", data)
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , Company submitted!")
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange=(e)=>{

    }


    getColumns = () => {
        return [
            {id: "CustomerNumber", label: "Code"},
            {id: "Name", label: "Name"},
            {id: "ContactName", label: "ContactPerson"},
            {id: "ContactNumber", label: "Phone"},
            {id: "ContactEmail", label: "Email"},
        ];

    }

    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        let ls = this.state.Data;
        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Company")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }
        let inputList = [
            {name: "Name", label: "Name of Company", type: "text",required:true, message:"Please input your company name"},
            {name: "RegNumber", label: "Reg. Number", type: "text",required:true, message:"Please input your Reg.name"},
            {name: "ContactName", label: "Contact Person", type: "text",required:true, message:"Please input your contact name"},
            {name: "ContactNumber", label: "Contact Number", type: "text",required:true, message:"Please input your contact number"},
            {name: "ContactEmail", label: "ContactEmail", type: "email",required:true, message:"Please input your email"},
            {
                name: "Country", label: "Country", type: "option",required:true, options: [
                    {key: "SA", val: "SA"},
                    {key: "DRC", val: "DRC"},
                ], message:"Please select your country"
            },
            {
                name: "Category", label: "Category", type: "option",required:true, options: [
                    {key: "Self", val: "Private"},
                    {key: "PTY", val: "PTY"},
                ], message:"Please select your category"
            },
            {
                name: "Sector", label: "Sector", type: "option", required:true,options: [
                    {key: "IT", val: "IT"},
                    {key: "Hospitality", val: "Hospitality"},
                    {key: "Finance", val: "Finance"},
                    {key: "Church", val: "Church"},

                ], message:"Please select your city"
            },
            {name: "Address", label: "Address", type: "text",required:true,message:"Please input your address"},
            {name: "Province", label: "Province", type: "text",required:true,message:"Please input your Province"},
            {name: "City", label: "City", type: "text",required:true,message:"Please input your city"},
        ];

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };


        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth:"50%"}}
            >
                <Alert style={{ margin: '16px 0' }} message="New company register" />

                {inputList.map((row) => {
                    if(row.type==="option"){
                        return(
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                            <Select
                                name={row.name}
                                defaultValue=""
                                /*style={{ width: 120 }}*/
                                onChange={this.handleChange}
                            >
                                {row.options.map((record,index)=>{
                                    return(
                                        <Option value={record.key}>{record.val}</Option>
                                    )
                                })}
                            </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight:20}}
                        onClick={()=>this.setState({component:"list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight:"100%",
                    overflow:"auto"
                }}>
                    <Title style={styles.title}>Company Management</Title>
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(SuperSettingCompany);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

