import React, {useState} from "react";
import {Form, Radio, Select} from "antd";

export default (props)=>{
    let {handleInput2,handleInput,_this}=props;

    const [componentSize, setComponentSize] = useState('large');
    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };

    const _onChangeUnique=(val)=>{
        let tmp = _this.state;
        tmp.UniqueKey = val;
        _this.setState(tmp);

    }
    const _onChangeField=(val,key)=>{
        let tmp = _this.state;
        tmp.ColCategory[key] = val;
        _this.setState(tmp);
    }

    const renderUniqueColomn = () => {
        const ls = [];
        for (let i in _this.state.ColCategory) {
            ls.push(i);
        }

        const renderOptions=()=>{
            return ls.map((item) => {
                return (
                    <Select.Option value={item}>{item.toUpperCase()}</Select.Option>
                )
            })
        }

        return(
            <Form.Item label="Unique Key">
                <Select name={"selectedSupplier"} onChange={_onChangeUnique}>
                    {renderOptions()}
                </Select>
            </Form.Item>
        )
    }

    const renderColomnCsv = () => {
        const ls = [];
        for (let i in _this.state.ColCategory) {
            const item = _this.state.ColCategory[i];
            ls.push(i);
        }
        return ls.map((y) => {
            return(
                <Form.Item label={y}>
                    <Select name={"selectedSupplier"} onChange={(e)=>_onChangeField(e,y)}>
                        <Select.Option value="phone">Phone</Select.Option>
                        <Select.Option value="identity">identity</Select.Option>
                        <Select.Option value="name">Name</Select.Option>
                        <Select.Option value="email">Email</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                    </Select>
                </Form.Item>
            )
        })
    }

    return(
        <div style={{marginTop:20}}>
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                initialValues={{ size: componentSize }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
            >
                {/*<Form.Item label="Form Size" name="size">
                    <Radio.Group>
                        <Radio.Button value="small">Small</Radio.Button>
                        <Radio.Button value="middle">Middle</Radio.Button>
                        <Radio.Button value="large">Large</Radio.Button>
                    </Radio.Group>
                </Form.Item>*/}
            {renderUniqueColomn()}
            {renderColomnCsv()}

            </Form>
        </div>

    )
}
