import React from "react";
import Title from "../../../components/Common/Title";
import {Button} from "antd";
import Page from "../../../layout/web/Page";
import Connector from "../../../redux/connector";
import PageBuilder from "../../../components/web/Common/PageBuilder";


 class DocGenInfoPage extends React.Component{
    constructor(props) {
        super(props);
        this.state={

        }
    }
    render() {
        let StateStorage = [
            {stateField: "Data", moduleEntity: "pdf/entity", table: "info", noCondition: false},
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Name", label: "Name"},
            {id: "Description", label: "Description"},
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
        ];
        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },
        ];
        let bldFields = [
            {
                label: "Name",
                message: "Please input your document category name",
                type: "text",
                StateKey: "Name",
                required:true
            },{
                label: "Description",
                message: "Please select your description",
                type: "text",
                StateKey: "Description",
                required:true,
            },

        ]
        return (
            <PageBuilder
                pageTitle={"Document Category"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Data"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Document Category"}
                postMsgResponse={"Thank you, category submitted!"}
                postMsgWarning={"Are you sure you want to submit this Category"}
                postEndpoint={"/pdf/entity/insert/info"}
            />
        )
    }


}
export default Connector(DocGenInfoPage);

const styles ={
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
};


