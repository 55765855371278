import React from "react";
import { Steps, Button, message } from 'antd';
import AvmSendStepInfo from "./AvmSendStepInfo";
import AvmSendStepData from "./AvmSendStepData";
import AvmSendStepPeriod from "./AvmSendStepPeriod";
import AvmSendStepIvrAudio from "./AvmSendStepIvrAudio";
import AvmSendSetpSubmit from "./AvmSendSetpSubmit";
import AvmSendStepOutbound from "./AvmSendStepOutbound";

const { Step } = Steps;


export default (props)=>{
    let {_this}=props;
    let steps=[
        {
            title: 'Info',
            content: <AvmSendStepInfo {...props}/>,
        },
        {
            title: 'Data',
            content: <AvmSendStepData {...props}/>,
        },
        {
            title: 'Period',
            content: <AvmSendStepPeriod  {...props}/>,
        },{
            title: 'Ivr(Audio)',
            content: <AvmSendStepIvrAudio  {...props}/>,
        },{
            title: 'Outbound No#',
            content: <AvmSendStepOutbound {...props}/>,
        }, {
            title: 'Submit',
            content: <AvmSendSetpSubmit  {...props}/>,
        },
    ];

    const next=()=> {
        const current = _this.state.current + 1;
        _this.setState({ current });
    }

    const prev=() =>{
        const current = _this.state.current - 1;
        _this.setState({ current });
    }
    const done=async ()=>{
        await props.submitMessage();
        message.success('Processing complete!');
    }

    const { current } = _this.state;
    console.log("Current index step: ",current)
    return (
        <div style={{marginTop:35}}>
            <h2 style={{color:'orange'}}><u>New broadcast step by step</u></h2>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div
                className="steps-content"
                style={{
                    minHeight:320,

                }}
            >
                {steps[current].content}
            </div>
            <div
                className="steps-action"
                style={{
                    marginBottom:10
                }}
            >
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => done()}>
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div>
        </div>
    );
}



