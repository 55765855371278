import React from "react";
import {  Select,ConfigProvider} from 'antd';
const {Option} = Select;

export const ohter =(props)=>{
    let {_this}=props;
    let ls=[];
    const onChangeCommentCategory=(value)=>{
        console.log("onChangeCommentCategory category > ",value);
        _this.setState({newCommentCategory:value})
    }

    for(let i in _this.state.CategoryComments){
        const row=_this.state.CategoryComments[i];
        if(_this.state.currentOrg===row.Org){
            ls.push({
                ke:row.Name,
                val:row.Name,
            })
        }
    }

    return (
        <Select
            name={"Category"}
            defaultValue={""}
            onChange={onChangeCommentCategory}
            style={{minWidth:"100%"}}
        >
            {ls.map((record, index) => {
                return (
                    <Option key={record.key} value={record.key}>{record.val}</Option>
                )
            })}
        </Select>
    )
}


export default class SelcectBox extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            category: "",
        }
    }

    handleChange = value => {
        let {_this}=this.props;
        this.setState({
            category: value,
        });
        console.log("onChangeCommentCategory category > ",value);
        _this.setState({newCommentCategory:value})
    }

    renderOptions=()=>{
        let {_this}=this.props;
        let ls=[];
        for(let i in _this.state.CategoryComments){
            const row=_this.state.CategoryComments[i];
            if(_this.state.currentOrg===row.Org){
                ls.push(row.Name)
            }
        }
        return ls
    }

    render() {
        const { cities } = this.state;
        return (
            <>
                <Select
                    defaultValue={""}
                    style={{ width: 120 }}
                    onChange={this.handleChange}
                >
                    {this.renderOptions().map(province => (
                        <Option key={province}>{province}</Option>
                    ))}
                </Select>
            </>
        );
    }
}
