import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Table} from 'antd';
import {PlusOutlined, SearchOutlined, SoundOutlined} from '@ant-design/icons';
import eConfig from "../../../configures";
const { Option } = Select;


export default (props)=>{
    let {_this}=props;

    const showDrawer = () => {
        _this.setState({
            btnAccount: true,
        });

    };
    const onClose = () => {
        _this.setState({
            showRecording:false,
        });
    };

    const onChange=(value)=>{
        _this.setState({
            searchLeadKey: value.target.value,
        });
    }

    let visible = _this.state.showRecording;

    const dd = _this.state.RecordingResults;
    console.log(")):--> ",dd)
    let dataSource = [];
    for(let i in dd){
        let row =dd[i];
        const wavUrl=eConfig.ServerPbx+"/document/download/" + row.Revision;
        row.Listen = (
            <audio controls>
                <source src={wavUrl} type="audio/wav" />
                Your browser does not support the audio element.
            </audio>
        )
        dataSource.push(row);
    }
    const columns = [
        {
            title: 'Modified',
            dataIndex: 'Modified',
            key: 'Modified',
        },
        {
            title: 'Size',
            dataIndex: 'Size',
            key: 'Size',
        },
        {
            title: 'Listen',
            dataIndex: 'Listen',
            key: 'Listen',
        }
    ];

    return (
        <>
            <Button type="default" onClick={showDrawer}>
                <SoundOutlined /> Recording
            </Button>
            <Drawer
                title="Create a new disposition status"
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        {/*<Button onClick={onClose} type="primary">
                            Submit
                        </Button>*/}
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    {/*<Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name="name"
                                label="Phone & Identity"
                                rules={[{ required: true, message: 'Please enter phone or identity' }]}
                            >
                                <Input placeholder="Please enter user name" onChange={onChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button type="default" onClick={_this.requestRecordingLead} style={{marginTop:30}} >
                                <SearchOutlined /> Search
                            </Button>
                        </Col>
                    </Row>*/}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Table dataSource={dataSource} columns={columns} />
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );

}
