import React from "react";
import { Table } from 'antd';

export default (props)=>{
    let {_this}=props;

    let data=[
        {key:'0',name:"Supplier",money:_this.state.selectedSupplier},
        {key:'1',name:"Batch",money:_this.state.selectedBatch},
        {key:'2',name:"File Name",money:_this.state.newUpload.filename},
        {key:'3',name:"Colonm setup",money:JSON.stringify(_this.state.ColCategory)},
        {key:'4',name:"Unique Key",money:_this.state.UniqueKey},
        {key:'5',name:"Total Record",money:_this.state.totalData},
    ]

    const columns = [
        {
            title: 'Key',
            dataIndex: 'Key',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Value',
            className: 'column-money',
            dataIndex: 'name',
            align: 'left',
        },
        {
            title: 'Value',
            className: 'column-money',
            dataIndex: 'money',
            align: 'left',
        },

    ];

    return(
        <div>
            <h3 style={{color: "black"}}>Upload Info</h3>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                title={() => 'Header'}
                footer={() => 'Footer'}
            />

        </div>
    )
}
