import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {UpCaseFirst, GetRowOptions, IsNotSuperUser, FindFieldFromRecordWithData} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert} from "antd";

const {Option} = Select;

class PbxSettingBillingCreditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            isLoading: false,
            component: "list"
        }
    }

    componentDidMount = async () => {
        IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "manager/entity", "billing-credit", this, false);
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    submitPost = async (hub, endpoint, successMsg, _this) => {
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }
    onDelete = async (row) => {
        this.setState({
            component: "list"
        })

        let hub = {...row}
        let endpoint = "/manager/entity/remove/billing-credit";
        await this.submitPost(hub, endpoint, "Tank you, Extension removed", this)

    }
    onFinish = async (values) => {
        if (!window.confirm("Are you sure you want to submit this range ?")) {
            return
        }
        /*let _this = this;
        let user = GetUserToken();*/
        let hub = {...values};
        hub.Balance = parseFloat(hub.Balance);
        hub.Warning = parseFloat(hub.Warning);
        hub.SecureLimit = parseFloat(hub.SecureLimit);
        hub.Authorized = parseFloat(hub.Authorized);

        let endpoint = "/manager/entity/insert/billing-credit";

        await this.submitPost(hub, endpoint, "Tank you, billing submitted", this)

    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange = (e) => {

    }


    getColumns = () => {
        return [
            {id: "Org", label: "Client"},
            {id: "Method", label: "Method"},
            {id: "Balance", label: "Balance"},
            {id: "Warning", label: "Warning"},
            {id: "SecureLimit", label: "Secure Limit"},
            {id: "Authorized2", label: "Authorize"},
        ];

    }

    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        let ls = []; //this.state.Data;
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            row.Org = FindFieldFromRecordWithData("CustomerNumber", row.Org, "Name", this.state.Companies);
            row.Authorized2 = row.Authorized === 1 ? "Yes" : "No";
            ls.push(row)
        }

        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Range Extension")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }

        let companies = GetRowOptions("CustomerNumber", "Name", this.state.Companies);

        let methods = [
            {key: "credit", val: "Credit Paid"},
            {key: "post", val: "Post Paid"},
        ];
        let authorizeds = [
            {key: "1", val: "Yes"},
            {key: "0", val: "No"},
        ]

        let inputList = [
            {
                name: "Org",
                label: "Name of Company",
                type: "option",
                required: true,
                options: companies,
                message: "Please input your company name"
            },
            {
                name: "Method",
                label: "Bill Method",
                type: "option",
                required: true,
                options: methods,
                message: "Please select your type of billing"
            },
            {
                name: "Authorized",
                label: "Authorized To Call?",
                type: "option",
                required: true,
                options: authorizeds,
                message: "Please select your Authorized"
            },
            {
                name: "Balance",
                label: "Credit Amount",
                type: "text",
                required: true,
                message: "Please input your credit amount"
            },
            {
                name: "Warning",
                label: "Warning level",
                type: "text",
                required: true,
                message: "Please input your Warning level"
            },
            {
                name: "SecureLimit",
                label: "Secure Limit",
                type: "text",
                required: true,
                message: "Please input your Secure Limit"
            },
        ]


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };


        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New Billing Credit"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    /*onChange={this.handleChange}*/
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Credit Management</Title>
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(PbxSettingBillingCreditPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

