import React,{ useState }  from "react";

import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
} from 'antd';
import {GetRowOptions} from "../../../api/fn";
import CompanySelection from "./CompanySelection";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
    marginTop:20
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};


export default (props) => {
    let {lsSupplier,lsCampaign,handleInput,handleInput2,state,_this}=props;



    const [componentSize, setComponentSize] = useState('large');
    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };
    const handleInputFile = (e) => {
        let inputUpload = document.getElementById("fileID");
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename = myfile.name;
            tmp.newUpload.base64string = reader.result;
            _this.setState(tmp);
            _this.convertBase64CsvIntoString();
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const onChange=(val,key)=>{
        _this.handleInput2(key,val);
        return
        let tmp = _this.state;
        tmp[key] = val;
        _this.setState(tmp);
        console.log("props---> onChange:",val,key)
    }

    let companies = GetRowOptions("CustomerNumber", "Name", _this.state.Companies);

    const renderCompanies=()=>{
        let companies = GetRowOptions("CustomerNumber", "Name", _this.state.Companies);
        return(
            <CompanySelection lsCompanies={companies} {...props}/>
        )
    }
    let suppliers=[];
    console.log("props---> ",_this.state.Org," > ", _this.state.Suppliers);
    for(let i in _this.state.Suppliers){
        const row=_this.state.Suppliers[i];

        if(row.Org===_this.state.Org){
            suppliers.push(row)
        }
    }

    let  batchs=[];
    for(let i in _this.state.Batchs){
        const row = _this.state.Batchs[i];
        if(row.Org===_this.state.Org){
            batchs.push(row)
        }
    }

    return (
        <div style={{marginTop:50}}>
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                initialValues={{ size: componentSize }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
            >
                <Form.Item label="Company">
                    <Select name={"Company"} onChange={(e)=>onChange(e,"Org")}>
                        {companies.map((row)=>{
                            return(
                                <Select.Option value={row.key}>{row.val}</Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Supplier">
                    <Select name={"selectedSupplier"} onChange={(e)=>onChange(e,"selectedSupplier")}>
                        {suppliers.map((row)=>{
                            return(<Select.Option value={row.Code}>{row.Name}</Select.Option>)
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label="Batch">
                    <Select name={"selectedBatch"} onChange={(e)=>onChange(e,"selectedBatch")}>
                        {batchs.map((row)=>{
                            return(<Select.Option value={row.Code}>{row.Name}</Select.Option>)
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label="Target Date">
                    <DatePicker onChange={onChange}/>
                </Form.Item>
                <Form.Item label="Choose your CSV">
                    <Input type={"file"} id={"fileID"} onChange={handleInputFile}/>
                </Form.Item>
            </Form>
        </div>
    )

}

