import React from "react";
import PageBuilder from "../../../components/web/Common/PageBuilder";
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {FindFieldFromRecordWithData, GetRowOptions, UpCaseFirst} from "../../../api/fn";
import {GetUserToken} from "../../../api/token";
import {SubmitPost} from "../lead/localFunctions";
import {DropzoneDialog} from "material-ui-dropzone";
import {PostToPbx} from "../../../api/http-post";
import {Alert, Button, Form, Input, Select} from "antd";
const {Option} = Select;

class ChannelAvmRegisterAudioPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Data:[],
            component:"list",
            Org:"",
            selectedRecord:{},
            showUpload:false,
            Files:[],
            filesLimit:1,
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Data", "ivr/entity", "audio", this,false);
    }
    submitNewAudio=async (inObj)=>{
        let _this = this;
        let record  = this.state.selectedRecord;
        let user = GetUserToken();
        let hub={
            Org :record.Org,
            Code: record.Code,
            Username:user.Username,
            Base64String:inObj.base64String,
            FileName:inObj.filename
        }
        let endpoint="/ivr/upload/audio"
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list",
                showUpload:false,
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , range submitted!")
            _this.setState({
                isLoading: false,
                component: "list",
                showUpload:false,
            });
            await fetchDataEntity("Data", "ivr/entity", "audio", this,false);
        });
    }
    onDelete =async (row) => {
        if (!window.confirm("Are you sure you want to remove this inbound queue ?")) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });
        let hub={...row}

        // postEndpoint={"/pdf/entity/insert/info"}
        let postEndpoint = this.props.postEndpoint;

        let endpoint =postEndpoint.replace("/insert/","/remove/");// "/manager/entity/remove/extensions";
        console.log("onDelete > ",endpoint," > ",row)
        await SubmitPost(hub, endpoint, "Tank you, Extension removed",this.reloadPage)

    }
    handleSave=async (files)=>{
        let _this = this;
        if( files.length===0){
            return
        }
        let file = files[0];
        const reader = new FileReader();
        reader.onload =async () => {
            const o={
                base64string: reader.result,
                filename:file.name,
            };
            await _this.submitNewAudio(o);
        }
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    openUpload = (row) => {
        this.setState({
            showUpload: true,
            selectedRecord: row,
        })
    }
    onDropHandler=(files) =>{
        return
        const _this=this;
        let file = files;
        const reader = new FileReader();
        reader.onload = () => {
            let _files = _this.state.Files;
            _files.push({
                id:"",
                base64string: reader.result,
                filename:file.name,
            })
            _this.setState({
                Files:_files
            })
        };
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }
    handleClose=()=>{
        this.setState({
            component:"list",
            selectedRecord:{},
            showUpload:false,
            Files:[],
        })
    }
    onFinish = async (values) => {
        if (!window.confirm("Are you sure you want to submit this registry ?")) {
            return
        }
        let _this = this;
        let user = GetUserToken();
        let hub = {...values};
        let endpoint = "/ivr/entity/insert/audio";
        await this.submitPost(hub,endpoint,"Tank you, Audio Register submitted")
    };
    submitPost=async (hub,endpoint,successMsg)=>{
        let _this=this;
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , range submitted!")
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await fetchDataEntity("Data", "ivr/entity", "audio", this,false);
        });
    }
    getColumns = () => {
        return[
            {id:"Client",label:"Company"},
            {id:"Code",label:"Code"},
            {id:"Name",label:"Name of Audio"},
            {id:"Campaign",label:"Campaign"},
        ];

    }
    renderList=()=>{
        if (this.state.component !== "list") {
            return
        }
        let columns=this.getColumns();
        let ls=[];

        const state = this.state.Data;
        console.log("**** this.getMember > ",state)

        for(let i in this.state.Data){
            let row = this.state.Data[i];
            row.Client = FindFieldFromRecordWithData("CustomerNumber",row.Org ,"Name",this.state.Companies);
            ls.push(row)
        }

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Audio IVR")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    openUpload={this.openUpload}
                />
            </GridItem>
        )
    }
    renderForm=()=>{
        if (this.state.component !== "form") {
            return
        }

        let companies = GetRowOptions("CustomerNumber", "Name", this.state.Companies);

        let inputList = [
            {
                name: "Org",
                label: "Name of Company",
                type: "option",
                required: true,
                options: companies,
                message: "Please input your company name"
            },
            {name: "Name", label: "Name", type: "text", required: true, message: "Please input your audio name"},
            {name: "Campaign", label: "Campaign", type: "text", required: true, message: "Please input your Campaign"},
        ]


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };


        return (
            <Form
                {...layout}
                className="login-form"
                onFinish={this.onFinish}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New Queue"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    /*onChange={this.handleChange}*/
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Register Audio IVR</Title>
                    {this.renderList()}
                    {this.renderForm()}

                    <DropzoneDialog
                        open={this.state.showUpload}
                        onSave={this.handleSave}
                        acceptedFiles={[/*'audio/wav','audio/x-wav','audio/mp3','audio/x-opus+ogg','audio/gsm','audio/x-opus+ogg'*/]}
                        showPreviews={true}
                        maxFileSize={5000000}
                        onClose={this.handleClose.bind(this)}
                        onDrop={this.onDropHandler}
                        filesLimit={this.state.filesLimit}
                    />

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}

export default Connector(ChannelAvmRegisterAudioPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
