import React from "react";
import {Checkbox} from 'antd';

const CheckboxGroup = Checkbox.Group;

/*const plainOptions = ['Apple', 'Pear', 'Orange'];
const defaultCheckedList = ['Apple', 'Orange'];*/

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedList: this.props.defaultCheckedList,
            indeterminate: true,
            checkAll: false,
            plainOptions:this.props.plainOptions,
        };
    }
    onChange = checkedList => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < this.props.plainOptions.length,
            checkAll: checkedList.length === this.props.plainOptions.length,
        });
        this.props.onCheckListChange(checkedList)
    };

    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? this.state.plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    render() {
        return (
            <>
                <div style={styles.siteCheckboxAllWrapper}>
                    <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                        disabled={true}
                    >
                        Select data below
                    </Checkbox>
                </div>
                <br/>
                <CheckboxGroup
                    options={this.props.plainOptions}
                    value={this.state.checkedList}
                    onChange={this.onChange}

                />
            </>
        );
    }
}

const styles = {
    siteCheckboxAllWrapper: {
        borderBottom: "1px solid #e9e9e9"
    }
}
