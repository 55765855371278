import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {
    UpCaseFirst,
    GetRowOptions,
    IsNotSuperUser,
    FindFieldFromRecordWithData,
} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert} from "antd";
const {Option} = Select;

class PbxSettingInboundQueuePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Queues: [],
            Registries: [],
            isLoading: false,
            component: "list",
            Org: "",
        }
    }

    componentDidMount = async () => {
        IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "manager/entity", "inbounds", this, false);
        await fetchDataEntity("Queues", "manager/entity", "queues", this, false);
        await fetchDataEntity("Registries", "manager/entity", "registries", this, false);
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onDelete =async (row) => {
        if (!window.confirm("Are you sure you want to remove this inbound queue ?")) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });
        let hub={
            Org:row.Org,
            InboundNumber:row.InboundNumber
        }
        let endpoint = "/manager/entity/remove/inbounds";
        this.submitPost(hub, endpoint, "Tank you, Inbound Queue removed")

    }
    onFinish = async (values) => {
        if (!window.confirm("Are you sure you want to submit this inbound queue ?")) {
            return
        }
        let hub = values;
        let endpoint = "/manager/entity/insert/inbounds";
        this.submitPost(hub, endpoint, "Tank you, Inbound Queue submitted")
    };
    submitPost = async (hub, endpoint, successMsg) => {
        let _this = this;
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange = (value,key) => {
        this.setState({
            [key]: value,
        })
    }
    getColumns = () => {
        return [
            {id: "Client", label: "Client"},
            {id: "Name", label: "Name"},
            {id: "InboundNumber", label: "Inbound Number"},
            {id: "Queue", label: "Queue"},
        ];

    }

    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        const state = this.state.Data;
        console.log("**** this.getMember > ", state)

        let ls = []; //this.state.Data;
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            row.Client = FindFieldFromRecordWithData("CustomerNumber", row.Org, "Name", this.state.Companies);

            ls.push(row)
        }

        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Queue")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }

        let registries=[],queues=[];
        for(let i in this.state.Registries){
            const row = this.state.Registries[i];
            if(row.Org ===this.state.Org){
                registries.push(row)
            }
        }
        for(let i in this.state.Queues){
            const row = this.state.Queues[i];
            if(row.Org ===this.state.Org){
                queues.push(row)
            }
        }

        let companies = GetRowOptions("CustomerNumber", "Name", this.state.Companies);
        let registriesOptions=GetRowOptions("InboundNumber", "InboundNumber", registries);
        let queuesOptions=GetRowOptions("Name", "Name", queues);


        let inputList = [
            {
                name: "Org",
                label: "Name of Company",
                type: "option",
                required: true,
                options: companies,
                message: "Please input your company name"
            },
            {name: "Name", label: "Name", type: "text", required: true, message: "Please input your inbound queue name"},
            {
                name: "InboundNumber",
                label: "Line Number",
                type: "text",
                required: true,
                options:registriesOptions,
                message: "Please input your Inbound Number"
            },
            {name: "Queue", label: "Queue", type: "option", required: true,options:queuesOptions, message: "Please input your Queue"},

        ]


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };


        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New Inbound Queue"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    onChange={(e)=>this.handleChange(e,row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Allocate Inbound Number To the Queue</Title>
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(PbxSettingInboundQueuePage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}




