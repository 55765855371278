import React, {Fragment} from "react";
import {Button, WhiteSpace, NoticeBar, Tabs, Badge, List, Switch, Modal,InputItem,TextareaItem} from 'antd-mobile';

import Connector from "../../../redux/connector";
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'
import {GetUserToken, SaveToken} from "../../../api/token";
import {BuildData, DialRequest, OnChangePbxStatus, RequestLead, StopCalling} from "../../../api/agent-station";
import {fetchDataEntity} from "../../../api/entity-data";
import {PhoneFilled} from "@ant-design/icons";
import {Avatar, PageHeader} from "antd";
import {Picker, } from 'antd-mobile';
import {PostToPbx} from "../../../api/http-post";
import CommentLeadInner from "../../../components/web/Lead/CommentLeadInner";


const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}
const Alert = Modal.alert;
const Item = List.Item;
let user1 = GetUserToken();
class MobileLeadAgentStationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            LeadComments:[],
            LeadCallLogs: [],
            LeadStatusLogs: [],
            LeadRequest: null,
            currentOrg:user1.Org,// "C100003",
            currentAgent:user1.Username,// "marvelous@easipath.com",
            currentAgentName:user1.FullName,// "Merveilleux biangacila",
            currentRole: "super",
            Extensions: [],
            Dispositions:[],
            janusMessage: "",
            phoneNumber: "",
            newDisposition:{
                comment:"",
                disposition:"",
            },
            newComment:{
                category:"",
                comment:"",
            }
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
        await fetchDataEntity("Dispositions", "lead/entity", "batch-setting-disposition", this, false);
        await fetchDataEntity("Extensions", "/manager/entity", "extensions", this, false);
        await fetchDataEntity("LeadComments", "lead/entity", "batch-agent-comment", this, false);
        await fetchDataEntity("CategoryComments", "lead/entity", "batch-category-comment", this, false);
    }
    onChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }
    onChangeComment=(value)=>{
        let tmp=this.state;
        tmp.newDisposition.comment = value;
        this.setState(tmp)
    }
    onChangeDisposition=(value)=>{
        let tmp=this.state;
        tmp.newDisposition.disposition = value[0];
        this.setState(tmp)
    }
    onChangeNewCommentCategory=(value)=>{
        let tmp=this.state;
        tmp.newComment.category = value[0];
        this.setState(tmp)
        console.log("onChangeDisposition > ",value[0])
    }
    onChangeNewCommentBody=(value)=>{
        let tmp=this.state;
        tmp.newComment.comment = value;
        this.setState(tmp)
        console.log("onChangeNewCommentBody > ",value[0])
    }

    preSubmitComment=()=>{
        let _this=this;
        Alert('Submit Comment', 'Are you sure you want to submit this comment ???', [
            { text: 'Cancel', onPress: () => console.log('cancel') },
            { text: 'Ok', onPress: () => _this.submitComment() },
        ])
    }
    preSubmitDisposition=()=>{
        if(this.state.newDisposition.disposition===""){
            return alert("Please select your disposition!")
        }
        let _this=this;
        Alert('Submit Disposition', 'Are you sure you want to submit this disposition ???', [
            { text: 'Cancel', onPress: () => console.log('cancel') },
            { text: 'Ok', onPress: () => _this.submitDisposition() },
        ])

    }
    submitComment=async ()=>{
        this.setState({
            btnComment: false
        });
        /**
         * post your comment
         */
        const req = this.state.LeadRequest;

        let _this = this;
        this.setState({
            isLoading: true
        })
        let user = GetUserToken();
        let hub = {
            Org: this.state.currentOrg,// user.OrgCode,
            Username: this.state.currentAgent,// user.Username,
            Ref: req.Ref,
            Pool: req.PoolCode,
            Agent: this.state.currentAgent,
            Category: this.state.newComment.category,
            Body: this.state.newComment.comment,
        }
        let endpoint = "/lead/entity/insert/batch-agent-comment";
        console.log("onSubmitAddComment request ", hub);
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log("onSubmitAddComment response ", data);
            _this.setState({
                isLoading: false
            });
            await fetchDataEntity("LeadComments", "lead/entity", "batch-agent-comment", _this, false);
            alert("Your new comment have been submitted")
        });
    }
    submitDisposition=async ()=>{
        const req = this.state.LeadRequest;

        let _this = this;
        this.setState({
            isLoading: true
        })
        let user = GetUserToken();
        let hub = {
            Org: req.Org,// user.OrgCode,
            Ref: req.Ref,
            Pool: req.PoolCode,
            Agent: this.state.currentAgent,
            CategoryComment: "disposition",
            Comment: this.state.newDisposition.comment,
            Disposition: this.state.newDisposition.disposition,
        }

        let endpoint = "/lead/entity/insert/batch-agent-disposition";
        console.log("submitDisposition request ", hub);
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log("submitDisposition response ", data);
            _this.setState({
                isLoading: false,
                LeadRequest: null
            });
            await RequestLead(_this);
            alert("Your new disposition have been submitted")
            window.location.reload()
        });
    }

    loadInitialData = async () => {
        let user = GetUserToken();
        this.setState({
            isLoading: true,
            currentOrg: user.OrgCode,
            currentAgent: user.Username,
            currentAgentName: user.FullName
        })
        await RequestLead(this);
    }

    getPbxRegisterStatus = () => {
        let props = this.props;
        let pbxExtension = props.pbx.Extension.username || "00000";
        let pbxOutCaller = props.pbx.OutCall.phone;
        let pbxRegistry = props.pbx.Registry;
        let pbxCalling = props.pbx.Calling;
        /*
        newState.Extension.username = data.username;
                newState.Extension.secret = data.secret;
         */

        console.log("pbxRegistry.status -=-=-=> ", pbxRegistry.status);
        let registerStatus = pbxRegistry.status;
        let registryColor = "#f44336";

        if (registerStatus === "registered") {
            registryColor = "green"
        }

        let dialStatus = false;
        if (pbxCalling.status !== "Not registered") {
            dialStatus = true
        }

        let _this = this;
        const renderHangup = () => {
            if (pbxCalling.status === "Not registered" || pbxCalling.status === "hangup") {
                return null
            }
            return (
                <a
                    style={{cursor: "pointer", color: "red", marginLeft: 20}}
                    onClick={() => StopCalling(_this)}
                >
                    <PhoneFilled/>
                </a>
            )
        }


        return {registerStatus, dialStatus, registryColor, pbxExtension, renderHangup, pbxCalling}
    }

    displayMessage = (boo, msg) => {
        this.setState({
            janusMessage: msg
        })
    }

    renderCardDial = () => {
        let _this = this;
        const handleClick = async () => {
            await DialRequest(_this.state.phoneNumber, _this.state.LeadRequest.Ref, _this, _this.displayMessage)
        }
        return (
            <div>
                <div style={styles.containerInput}>
                    <InputItem
                        style={styles.input}
                        clear
                        placeholder="Enter your username"
                        ref={el => this.inputUsername = el}
                        value={this.state.phoneNumber}
                        onChange={(e) => this.onChange(e, "phoneNumber")}
                    >Phone No.:</InputItem>
                </div>
                <WhiteSpace/>
                <div style={styles.containerInput}>
                    <Button
                        onClick={handleClick}
                    >click to dial</Button>
                </div>
                <WhiteSpace/>
            </div>
        )
    }
    renderCardPhone = () => {
        let {phones} = BuildData(this);
        return (
            <List style={{minWidth: "90%"}}>
                {phones.map((row) => {
                    return (
                        <Item
                            extra={<Button
                                onClick={() => DialRequest(row.val, this.state.LeadRequest.Ref, this, this.displayMessage)}
                                style={{cursor: "pointer", color: "#0092ff"}}>
                                Dial
                                <Avatar
                                    style={{color: "#0092ff", marginLeft: 5}}
                                    src="https://www.blazethread.com/wp-content/uploads/2019/07/220-2207243_phone-icon-png-mobile-phone-icon-circle-png.png"/>
                            </Button>}
                        >{row.val}</Item>
                    )
                })}
            </List>
        )
    }
    renderCardALlDetail = () => {
        const {allFields} = BuildData(this);
        return (
            <List style={{minWidth: "90%"}}>
                {allFields.map((row) => {
                    return (
                        <Item
                            extra={row.key}
                        >{row.val}</Item>
                    )
                })}
            </List>
        )
    }
    renderCardProcess = () => {
        let data = [
            /*{label: "2014", value: "2014"},
            {label: "2015", value: "2015"},
            {label: "2016", value: "2016"},*/
        ];
        for(let i in this.state.Dispositions){
            const row=this.state.Dispositions[i];
            data.push({
                label:row.Name,
                value:row.Name
            })
        }

        let _this=this;

        return (
            <div style={{minWidth:"100%",maxWidth:"100%"}}>
                <WhiteSpace size="lg"/>
                <label style={{color:"red",fontSize:18,fontWeight:"bold"}}>Process Lead</label>
                <WhiteSpace />
                <List style={{ backgroundColor: 'white' }} >
                    <Picker
                        data={data}
                        value={this.state.newDisposition.disposition}
                        cols={1}
                        onChange={this.onChangeDisposition}
                        extra={this.state.newDisposition.disposition ||"Choose"}
                        okText={"Ok"}
                        dismissText={"Cancel"}
                        style={styles.listDisposition}
                    >
                        <List.Item arrow="horizontal" onPress={this.onPress} style={styles.listDisposition}>
                            Disposition
                        </List.Item>
                    </Picker>
                    <WhiteSpace />
                    <TextareaItem
                        title="Comment"
                        placeholder="Type your comment..."
                        data-seed="logId"
                        ref={el => this.autoFocusInst = el}
                        autoHeight
                        style={{minHeight:50}}
                        onChange={this.onChangeComment}
                    />
                    <WhiteSpace />
                    <Button
                        type="primary"
                        onClick={this.preSubmitDisposition}
                    >
                        submit
                    </Button>
                    <WhiteSpace />

                </List>
            </div>
        )
    }

    renderCardComment=()=>{
        let data=[];
        for(let i in this.state.CategoryComments){
            const row=this.state.CategoryComments[i];
            data.push({
                label:row.Name,
                value:row.Name
            })
        }
        return(
            <div style={{minWidth:"100%",maxWidth:"100%"}}>
                <WhiteSpace size="lg"/>
                <label style={{color:"red",fontSize:18,fontWeight:"bold"}}>Comment Lead</label>
                <WhiteSpace />
                <List style={{ backgroundColor: 'white' }} >
                    <Picker
                        data={data}
                        value={this.state.newDisposition.disposition}
                        cols={1}
                        onChange={this.onChangeNewCommentCategory}
                        extra={this.state.newDisposition.disposition ||"Choose"}
                        okText={"Ok"}
                        dismissText={"Cancel"}
                        style={styles.listDisposition}
                    >
                        <List.Item arrow="horizontal" onPress={this.onPress} style={styles.listDisposition}>
                            Category
                        </List.Item>
                    </Picker>
                    <WhiteSpace />
                    <TextareaItem
                        title="Comment"
                        placeholder="Type your comment..."
                        data-seed="logId"
                        ref={el => this.autoFocusInst = el}
                        autoHeight
                        style={{minHeight:50}}
                        onChange={this.onChangeNewCommentBody}
                    />
                    <WhiteSpace />
                    <Button
                        type="primary"
                        onClick={this.preSubmitComment}
                    >
                        submit
                    </Button>
                    <WhiteSpace />

                    <CommentLeadInner _this={this}/>

                </List>
            </div>
        )
    }

    render() {

        const tabs = [
            {title: <Badge dot>Phone</Badge>},
            {title: <Badge>Dial</Badge>},
            {title: <Badge>Detail</Badge>},
            {title: <Badge>Comment</Badge>},
            {title: <Badge>Process</Badge>},
        ];

        let {registerStatus, dialStatus, registryColor, pbxExtension, renderHangup, pbxCalling} = this.getPbxRegisterStatus()
        let  req = this.state.LeadRequest;
        console.log("current lead > ",req !== null ,req);
        if(req===null){
            req={};
        }
        /*
        currentAgent:user1.Username,// "marvelous@easipath.com",
            currentAgentName:user1.FullName,
            */
        return (

                <div style={{margin: 15}}>
                    <div style={styles.containerLabel}>
                        <PageHeader
                           /* className="site-page-header"*/
                            onBack={() => window.location.href="#main-menu"}
                            title={<label style={styles.titleLabel} color={"primary"}>
                                Work Station Board
                            </label>}
                            />
                    </div>
                    <List>
                        <List.Item
                            extra={
                                <Switch
                                    checked={this.state.checked}
                                    onChange={(state) => {
                                        this.setState({
                                            checked: !this.state.checked,
                                        });
                                        OnChangePbxStatus(state, this)
                                    }}
                                />
                            }
                        >Ext.: {pbxExtension} &nbsp;|&nbsp;<span style={{color: registryColor}}>{registerStatus}</span>
                        </List.Item>
                    </List>

                    {typeof req.LeadData === "undefined" ?null:
                        <Fragment>
                            <div style={{flex: 1}}>
                                <div>
                                    <span style={styles.infoTitle}>Lead Name: </span>
                                    <span>{req.LeadData.FullName || ""}</span>
                                </div>
                                <div>
                                    <span style={styles.infoTitle}>Dial status: </span>
                                    <span>{dialStatus ? <span
                                        style={{color: "#9c27b0"}}>:{pbxCalling.status} {renderHangup()}</span> : "...."}</span>
                                </div>
                            </div>

                            <Tabs tabs={tabs}
                                  initialPage={0}
                                  onChange={(tab, index) => {
                                      console.log('onChange', index, tab);
                                  }}
                                  onTabClick={(tab, index) => {
                                      console.log('onTabClick', index, tab);
                                  }}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '150px',
                                    backgroundColor: '#fff',
                                    minWidth: "100%"
                                }}>
                                    {this.renderCardPhone()}
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '150px',
                                    backgroundColor: '#fff',
                                    minWidth: "100%"
                                }}>
                                    {this.renderCardDial()}
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '150px',
                                    backgroundColor: '#fff',
                                    minWidth: "100%"
                                }}>
                                    {this.renderCardALlDetail()}
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    backgroundColor: '#fff',
                                    minWidth: "100%",
                                    maxWidth: "100%"
                                }}>
                                    {this.renderCardComment()}
                                </div>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    backgroundColor: '#fff',
                                    minWidth: "100%",
                                    maxWidth: "100%"
                                }}>
                                    {this.renderCardProcess()}
                                </div>

                            </Tabs>


                            <WhiteSpace size="lg"/>
                            <NoticeBar mode="closable" icon={null}>{this.state.janusMessage}</NoticeBar>

                        </Fragment>
                    }

                    <video
                        className="rounded centered"
                        id="video-device"
                        width={320}
                        height={240}
                        autoPlay
                        playsinline
                        muted="muted"
                    />
                    <audio
                        id={"audio-device"}
                    />
                    <audio
                        className="rounded centered"
                        id="roomaudio"
                        width="100%"
                        height="100%"
                        autoPlay
                    />

                </div>

        )
    }

}

export default Connector(MobileLeadAgentStationPage)

const styles = {
    listDisposition:{
        width: "100%",
        minWidth:"100%"
    },
    infoTitle: {
        color: "black",
        fontWeight: "bold",
        fontSize: 18
    },
    error: {
        color: "red",
        fontWeight: "bold"
    },
    button: {
        marginTop: 10,
        minWidth: "100%",
        /*backgroundColor: "blue",*/
    },
    bigContainer: {
        minWidth: "100%",
        backgroundColor: "red",
        minHeight: "100%",
        flex: 1,/*justifyContent:"center",*/
        display: "flex",

        alignItems: "center"
    },
    input: {
        minWidth: "90%",
        /*backgroundColor:"red"*/
    },
    titleLabel: {
        color: "lightblue",
        fontWeight: "bold",
        fontSize: 18
    },
    containerInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 5,
        minWidth: "90%",
    },
    containerLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },
    containerLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
    },
    logo: {
        maxHeight: 50,
        minHeight: 50,
    }
}


