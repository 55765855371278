import React, {useState} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {
    UpCaseFirst,
    GetRowOptions,
    IsNotSuperUser,
    FindFieldFromRecordWithData,
    IsFindInArray
} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert} from "antd";
import StepsUpload from "../../../components/web/Lead/StepsUpload";
import {GetUserToken} from "../../../api/token";
import CompanySelection from "../../../components/web/Lead/CompanySelection";



const {Option} = Select;

const getComponentSize=()=>{
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [componentSize, setComponentSize] = useState('large');
    return componentSize
}

class PbxSettingExtensionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Suppliers: [],
            Batchs: [],
            BatchInfos: [],

            newUpload: {
                name: "",
                base64string: "",
                filename: "",
            },

            colList: [],
            totalData: 0,
            delimiter: ",",
            dataCsv: [],
            ColCategory: {},
            UniqueKey: "",

            isLoading: false,
            component: "list",
            Org: "",
            current:0
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "lead/entity", "batch-info", this, false);
        await fetchDataEntity("Suppliers", "lead/entity", "batch-supplier", this, false);
        await fetchDataEntity("Batchs", "lead/entity", "batchs", this, false);
        /*await fetchDataEntity("BatchInfos", "lead/entity", "Batch-info", this, false);*/
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    handleInput = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }

    submitUpload=async ()=>{

        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = this.state.Org;
        hub.BatchName = this.state.selectedBatch; // "LEAD-CREDITCURE--2019-05-25"
        hub.Supplier = this.state.selectedSupplier;
        hub.Author = user.Username; //"biangacila@gmail.com"
        hub.AuthorName = user.Fullname;// "MErveilleux Biangacila"

        const arr =this.state.newUpload.base64string.split(";base64,");

        hub.Base64String =arr[1] ; // this.state.newUpload.base64string;
        hub.Delimiter = ",";
        hub.ColCategory = this.state.ColCategory;
        hub.ColUnique = this.state.UniqueKey;
        hub.FileName = this.state.newUpload.filename;// "lead-2019-05-25.csv"
        hub.TotalRecord = parseInt(this.state.Token);

        let endpoint = "/lead/upload/new";

        console.log("submitUpload send > ", hub);
        await PostToPbx(hub, endpoint, function (dataIn) {
            console.log("submitUpload response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you , upload submitted!");
                let tmp = _this.state;
                tmp.component="list";
                tmp.selectedBatch = "";
                tmp.newUpload = {
                    name: "",
                    base64string: "",
                    filename: "",
                };
                tmp.colList = [];
                tmp.totalData = 0;
                tmp.delimiter = ",";
                tmp.dataCsv = [];

                tmp.ColCategory = {};
                tmp.UniqueKey = "";
                _this.setState(tmp);

            }
        });

    }

    onFinish = async (values) => {
        if (!window.confirm("Are you sure you want to submit this extension ?")) {
            return
        }
        let hub = values;
        hub.Nat = hub.Nat === "yes";
        hub = this.appendAgentDetail(hub);
        let endpoint = "/lead/entity/insert/batch-upload";
        this.submitPost(hub, endpoint, "Tank you, upload submitted")
    };
    submitPost = async (hub, endpoint, successMsg) => {
        let _this = this;
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            if (data.STATUS === "fail") {
                alert("Fail, could not insert your extension,try again")
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    convertBase64CsvIntoString = async () => {
        let data = this.state.newUpload.base64string;
        const arr = data.split(";base64,");
        data = arr[1];
        //data = data.replace("data:text/csv;base64,", "");

        let _this = this;
        let hub = {};
        hub.Base64String = data;
        let endpoint = "/lead/csv-json/convert";
        console.log("convertBase64CsvIntoString send > ", hub);
        await PostToPbx(hub, endpoint, function (dataIn) {
            console.log("convertBase64CsvIntoString response > ", dataIn);
            let header = {};
            let arrHeader = [];
            let arrDataJson = [];
            let ColCategory = {};
            let tot = 0;
            const data = dataIn.RESULT;
            if (typeof data === "undefined") {
                console.log("undefined result ")
                _this.setState({
                    colList: arrHeader,
                    totalData: tot,
                    dataCsv: arrDataJson,
                    ColCategory: ColCategory
                });
                return
            }

            const arrDataString = data;


                const row1 = arrDataString[0];
                let x = 0;
                for (let i in row1) {
                    if (i !== "") {
                        const item = i;
                        arrHeader.push(item);
                        header[i] = item;
                        ColCategory[item] = "";
                        x++;
                    }

                }

                for (let x in arrDataString) {
                    if (x > 0) {
                        const row = arrDataString[x];
                        arrDataJson.push(row);
                        tot++;
                    }
                }

                //todo let create data row





            _this.setState({
                colList: arrHeader,
                totalData: tot,
                dataCsv: arrDataJson,
                ColCategory: ColCategory
            });

            console.log("&&&&&----> ", arrHeader, tot, arrDataJson, ColCategory)
            console.log("&&&&&----> ",_this.state)


        });
    }
    getColumns = () => {
        return [
            {id: "Client", label: "Company"},
            {id: "BatchName", label: "Batch Name"},
            {id: "Ref", label: "Batch Ref"},
            {id: "AuthorName", label: "Author"},
            {id: "Total", label: "Total"},
            /*{id: "Names", label: "Name Field"},*/
        ];

    }

    renderList = () => {
        let user = GetUserToken();
        if (this.state.component !== "list") {
            return
        }
        const state = this.state.Data;
        console.log("**** this.getMember > ", state)

        let ls = []; //this.state.Data;
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            row.Client = FindFieldFromRecordWithData("CustomerNumber", row.Org, "Name", this.state.Companies);

            /**
             * let check for privilege
             *
             */
            let comp = FindFieldFromRecordWithData("Name", row.OrgCode, "Name", this.state.Companies);
            if(user.Role==="super"){
                ls.push(row)
            }else{
                if(user.Org===row.Org || user.Org===comp  ){
                    ls.push(row)
                }
            }
        }

        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Uploaded Batch History")}
                    onAddRecord={this.onAddRecord}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }

        return (
            <StepsUpload
                handleInput={this.handleInput}
                handleInput2={this.handleInput2}
                convertBase64CsvIntoString={this.convertBase64CsvIntoString}
                _this={this}
                state={this.state}
                submitUpload={this.submitUpload}
            />
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Upload Batch</Title>

                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(PbxSettingExtensionPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}




