import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import Title from "../../../components/Common/Title";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {PDFDownloadLink, StyleSheet} from "@react-pdf/renderer";
import {PdfDocument} from "../../../components/web/Document/PdfDumment";
import {Modal, Button} from 'antd';
import AddPagePdfHtml from "../../../components/web/Document/AddPagePdfHtml";
import  "../../../components/web/Document/my.css";
import ReactHtmlParser from "react-html-parser";
import jsPDF from "jspdf";

class DocGenPdfPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: [],
            show: false,
            genShow:false
        }
    }

    handleOk = () => {
        this.setState({
            show: false
        })
    }

    showModal = () => {
        this.setState({
            show: true
        })
    }

    renderAddPageForm = () => {
        if (!this.state.show) {
            return null
        }

        return (
            <div>
                <Modal
                    title="Add Page"
                    visible={this.state.show}
                    onOk={this.handleOk}
                >
                    <AddPagePdfHtml
                        _this={this}
                        keyShow={"show"}
                    />
                </Modal>
            </div>
        )
    }

    genPDF=()=>{
        let doc = new jsPDF('p', 'pt', 'letter');
        let margins = {
            top: 50,
            left: 60,
            width: 545
        };
        for(let i in this.state.pages){
            let pageData = this.state.pages[i];
            doc.fromHTML(window.document.getElementById("congo-"+i),
                margins.left // x coord
                , margins.top, // y coord,{});
                {
                    'width': margins.width // max width of content on PDF

                },)
            doc.addPage()
        }
        //doc.save("Test.pdf")

        let iframe = document.createElement('iframe');
        iframe.setAttribute('style','position:absolute;right:0; top:0; bottom:0; height:100%; width:650px; padding:20px;');
        document.body.appendChild(iframe);
        iframe.src = doc.output('datauristring');
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Generate PDF</Title>
                    <div style={{minWidth: "100%",padding:10}}>
                        <Button type="default" onClick={this.showModal} style={{float:"right"}}>
                            Add Page
                        </Button>
                        <Button type="default" onClick={()=>this.genPDF()} style={{float:"right"}}>
                            Generate
                        </Button>
                    </div>
                    <div className={"body"} style={{marginTop:50}}>
                        {this.state.pages.map((item,index)=>{
                            return(
                                <div
                                    id={"congo-"+index}
                                    className="html-2-pdfwrapper"
                                    style={{
                                        paddingTop: 50,
                                        paddingLeft: 60,
                                        paddingRight: 60,
                                        width: "29.7cm",
                                        height: "21cm",
                                        maxWidth:"29.7cm",
                                        minWidth:"29.7cm",
                                        minHeight:"21cm",
                                        maxHeight:"21cm",
                                    }}

                                >
                                    {ReactHtmlParser(item)}
                                </div>
                            )
                        })}

                       {/* <page size="A4"></page>
                        <page size="A4" layout="landscape"></page>
                        <page size="A5"></page>
                        <page size="A5" layout="landscape"></page>
                        <page size="A3"></page>
                        <page size="A3" layout="landscape"></page>*/}
                    </div>
                    {this.renderAddPageForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}


export default Connector(DocGenPdfPage);

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff"
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    movieContainer: {
        backgroundColor: "#f6f6f5",
        display: "flex",
        flexDirection: "row",
        padding: 5
    },
    movieDetails: {
        display: "flex",
        marginLeft: 5
    },
    movieTitle: {
        fontSize: 15,
        marginBottom: 10
    },
    movieOverview: {
        fontSize: 10
    },

    image: {
        height: 200,
        width: 150
    },
    subtitle: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: 150,
        alignItems: "center",
        marginBottom: 12
    },
    vote: {
        display: "flex",
        flexDirection: "row"
    },
    rating: {
        height: 10,
        width: 10
    },
    vote_text: {
        fontSize: 10
    },
    vote_pop: {
        fontSize: 10,
        padding: 2,
        backgroundColor: "#61C74F",
        color: "#fff"
    },
    vote_pop_text: {
        fontSize: 10,
        marginLeft: 4
    },
    overviewContainer: {
        minHeight: 110
    },
    detailsFooter: {
        display: "flex",
        flexDirection: "row"
    },
    lang: {
        fontSize: 8,
        fontWeight: 700
    },
    vote_average: {
        fontSize: 8,
        marginLeft: 4,
        fontWeight: "bold"
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
});


