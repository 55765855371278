
import {
    SET_APP_NAME,
    SET_APP_MODULE,
    SET_APP_MENU,
    SET_APP_CONTENT,
    SET_APP_LINK
}from "../../constants";

export const SetNavAppName=(payload)=>{
    return {
        type: SET_APP_NAME,
        payload
    }
}
export const SetNavAppModule=(payload)=>{
    return {
        type: SET_APP_MODULE,
        payload
    }
}
export const SetNavAppMenu=(payload)=>{
    return {
        type: SET_APP_MENU,
        payload
    }
}
export const SetNavAppContent=(payload)=>{
    return {
        type: SET_APP_CONTENT,
        payload
    }
}
export const SetNavAppLink=(payload)=>{
    return {
        type: SET_APP_LINK,
        payload
    }
}
