import React from "react";
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import Title from "../../../components/Common/Title";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Page from "../../../layout/web/Page";
import {GetUserToken} from "../../../api/token";
import {
    attachImageProfile,
    buildAssociationData,
    findRecordDisplayKey, findRecordKey, findRecordKeyField,
    findRecordType,
    findRecordUniqueKey,
    SortData, UpcaseFirst
} from "../../../api/fn";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {PostToPbx} from "../../../api/http-post";
import {Alert, Button, Col, Form, Input, Row, Select} from "antd";
import {SubmitPost} from "../lead/localFunctions";
import ModelSimple from "../../../components/Common/popup-modal/ModalView";
import PopupMe from "../../../components/Common/PopupMe";
import UploadDocument from "../../../components/web/Document/UploadDocument";
import PopupDownloadFile from "../../../components/web/Crm/PopupDownloadFile";
import ChooseCategoryModal from "../../../components/web/Crm/ChooseCategoryModal";
import StepsUpload from "../../../components/web/Lead/StepsUpload";
import AvmSendSteps from "../../../components/web/Channel/AvmSendSteps";
import {DropzoneDialog} from "material-ui-dropzone";

const {Option} = Select;

class ChannelAvmSendAudioPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Records: [],
            Modules: [],
            Companies: [],
            Attributes: [],
            IvrList: [],
            Org: "",
            selectedModule: "",
            component: "list",
            newEntry: {},
            current: 0,
            /* field */
            OriginData: "",
            Campaign: "",
            NotifyEmail: "",
            Comment: "",
            EntityData: "",
            SelectedData: [],
            PeriodType: "now",
            SelectAudio:"",
            SelectOutbound:"",
            showUpload:false,
            selectedRecord:null,
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Attributes", "entity", "entity-attributes", this, false)
        await fetchDataEntity("Records", "entity", "entity-record", this, false);
        await fetchDataEntity("Modules", "entity", "entity-categories", this, false);
        await fetchDataEntity("IvrList", "ivr/entity", "audio", this, false);
        await fetchDataEntity("Extensions", "/manager/entity", "extensions", this, false);
    }

    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        })
        console.log("handleChange &&&&--> ", key, " > ", value)
        if (key === "Org") {
            await fetchDataEntity("Modules", "entity", "entity-categories", this, false)
        }
    }
    handleInput = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    submitMessage = async () => {

        let _this = this;

        let contactList={};
        for(let i in _this.state.SelectedData) {
            const row = _this.state.SelectedData[i];
            let key = "", val = "";
            for (let a in row) {
                key = a;
                val = row[key];
            }
            contactList[key]=val;
        }
        let ext = null;
        let user=GetUserToken();
        for (let i in this.state.Extensions) {
            const row = this.state.Extensions[i];
            if(row.Org!==user.Org){
                continue
            }
            if (row.AgentRef === user.Username) {
                ext = row;
            }
        }


        let hub =
            {
                Org: this.state.Org,
                AudioName: this.state.SelectAudio,
                Contact: contactList,
                Extension:this.state.SelectOutbound|| "20007",//TODO please change this late
                Mode: "now",//TODO please change this late
                ScheduleDate: "",
                ScheduleTime: "",
                OriginData: this.state.OriginData,
                Campaign: this.state.Campaign,
                NotifyEmail:this.state.NotifyEmail,
                Comment: this.state.NotifyEmail,
            }

        let endpoint = "/ivr/request/send";
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            let res = data.RESULT;
            alert("Thank your avm message is in progress!");
            _this.setState({
                component: "list",
                OriginData: "",
                Campaign: "",
                NotifyEmail: "",
                Comment: "",
                EntityData: "",
                SelectedData: [],
                PeriodType: "now",
                SelectAudio:"",
                SelectOutbound:"",
                current: 0,
            });
        })

    }
    onAddRecord = () => {
        if (this.state.Org === "") {
            return alert("Please select company before adding!")
        }
        this.setState({
            component: "form"
        })
    }
    submitNewAudio=async (inObj)=>{
        let _this = this;
        let record  = this.state.selectedRecord;
        let user = GetUserToken();
        let hub={
            Org :record.Org,
            Code: record.Code,
            Username:user.Username,
            Base64String:inObj.base64String,
            FileName:inObj.filename
        }
    }
    handleSave=async (files)=>{
        let _this = this;
        if( files.length===0){
            return
        }
        let file = files[0];
        const reader = new FileReader();
        reader.onload =async () => {
            const o={
                base64string: reader.result,
                filename:file.name,
            };
            await _this.submitNewAudio(o);
        }
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }
    openUpload = (row) => {
        this.setState({
            showChooseCategory: true,
            showUpload: false,
            selectedRecord: row,
        })
    }
    renderCompaniesBox = () => {
        let user = GetUserToken();
        let ls = [];
        for (let i in this.state.Companies) {
            const row = this.state.Companies[i];
            if (user.Role === "super") {
                ls.push({
                    key: row.CustomerNumber,
                    val: row.Name,
                })
            } else {
                if (row.CustomerNumber === user.Org) {
                    ls.push({
                        key: row.CustomerNumber,
                        val: row.Name,
                    })
                }
            }
        }
        return (
            <Select
                name={"Org"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "Org")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }
        return (
            <AvmSendSteps
                handleInput={this.handleInput}
                handleInput2={this.handleInput2}
                submitMessage={this.submitMessage}
                _this={this}
            />
        )
    }
    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        let columns = [
            {id: "Campaign", label: "Campaign"},
            {id: "Date", label: "Date"},
            {id: "Ivr", label: "IVR"},
            {id: "Origin", label: "Origin"},
            {id: "TotalNumber", label: "Total Number"},
            {id: "DurationAudio", label: "Duration"},
            {id: "Cost", label: "Cost"},
        ];
        let campaign = "Monthly Payment Reminder";
        let campaign2 = "Monthly Debt order off";
        let date = "Thursday July 16,2020";
        let date1 = "Saturday June 26,2020"
        let ls = [
            {
                Campaign: campaign,
                Date: date,
                Ivr: "Dave 1",
                Origin: "Crm",
                TotalNumber: 2,
                DurationAudio: "45s",
                Cost: "R 1.3"
            },
            {
                Campaign: campaign2,
                Date: date1,
                Ivr: "Dave 2",
                Origin: "Crm",
                TotalNumber: 3,
                DurationAudio: "95s",
                Cost: "R 3.3"
            },
        ];

        let data = {columns: columns, data: ls}
        return (
            <TableDataView
                {...data}
                tableTitle={"History"}
                onAddRecord={this.onAddRecord}

            />
        )
    }
    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Send AVM(Automatic Voice Message)</Title>

                    {this.state.component === "list" ?
                        <Row gutter={16} style={{marginTop: 20}}>
                            <Col span={24}>
                                <Form.Item
                                    label={"Company"}
                                >
                                    {this.renderCompaniesBox()}
                                </Form.Item>
                            </Col>
                            {/*<Col span={4}>
                            <Form.Item
                                label={"Entity"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>*/}
                        </Row> : null
                    }
                    {this.renderForm()}
                    {this.renderList()}


                </div>
            </Page>
        )
    }

}


export default Connector(ChannelAvmSendAudioPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

