import React from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import Config from "../configures";

let  SocketPublisher;
let serverUrl =Config.ServicePbxWs+"/ws/publisher"; // "wss://cloudcalls.easipath.com/backend-easisec-ws/api/ws/publisher";
let propsIn;


const ConnectToSocketPublisher=(props)=>{
    let {state}=props;
    propsIn = props;
    state.org="easipath";
    state.username="biangacila@gmail.com";
    state.extension = "22222";
    state.role="super";
     serverUrl =serverUrl+'/'+state.org+'/'+state.username+'/'+state.extension+'/'+state.role;
    const uri = serverUrl ;
    const urls = [uri];
    let urlIndex = 0;
    const options = {
        WebSocket: undefined,
        maxReconnectionDelay: 10000,
        minReconnectionDelay: 1000 + Math.random() * 4000,
        reconnectionDelayGrowFactor: 1.3,
        minUptime: 5000,
        connectionTimeout: 4000,
        maxRetries: Infinity,
        debug: false,
    };
    // round robin url provider
    const urlProvider = () => urls[urlIndex++ % urls.length];
    SocketPublisher  = new ReconnectingWebSocket(urlProvider, [], options);
    SocketPublisher .onopen = () => {
        console.log("####---> ", 'Hello Mr. Server! monitor socket Publisher ' + uri);
        const title="WS_CONNCABS_CONNECTED";
        const data = state.info;
        try {
            SocketPublisher.send(JSON.stringify({type: title,Type: title, Payload: data}));
        } catch (e) {
            console.log("WS_CONNCABS_CONNECTED ERROR sendMessageSocket > ", e, title, data)
        }
    };
    SocketPublisher .onmessage = ({data}) => {
        let message =JSON.parse(data);
        onSocketMessage(message)
        //console.log("onMessage Socket Publisher ^^^> ",message.type," > ",data);
    }
}

const onSocketMessage=(message)=>{
    // CALLS_INBOUND
    // CALLS_OUTBOUND
    // PEER_STATE
    //CDR_INBOUND
    //CDR_OUT

    console.log("(:)SOCKET MANAGER SAY > ",message.type,message.payload);
    if(message.type!=="WS_CONNCABS_CONNECTED"){
        //console.log("(:)SOCKET MANAGER SAY > ",message.type,message.payload);
    }

    if(message.type==="CALLS_OUT"){
        propsIn.SetPbxLiveOutbound(message.payload)
    }
    if(message.type==="CALLS_INBOUND"){
        propsIn.SetPbxLiveInbound(message.payload)
    }
    if(message.type==="PEER_STATE"){
        propsIn.SetPbxLivePeer(message.payload)
    }
}

export {
    ConnectToSocketPublisher
}
