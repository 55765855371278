import {DASHBOARD_LIVE_CALL_IN, DASHBOARD_LIVE_CALL_OUT, DASHBOARD_LIVE_PEER_STATUS} from "../../constants";

export const SetPbxLiveInbound = (payload) => {
    return {
        type: DASHBOARD_LIVE_CALL_IN,
        payload
    }
}
export const SetPbxLiveOutbound = (payload) => {
    return {
        type: DASHBOARD_LIVE_CALL_OUT,
        payload
    }
}
export const SetPbxLivePeer = (payload) => {
    return {
        type: DASHBOARD_LIVE_PEER_STATUS,
        payload
    }
}
