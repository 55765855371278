import React from "react";
import {Comment, Tooltip, List} from 'antd';
import moment from 'moment';


export default (props) => {
    let {_this} = props;
    let data = [];
    const req = _this.state.LeadRequest;
    if(req===null){
        return null
    }
    for (let i in _this.state.LeadComments) {
        const row = _this.state.LeadComments[i];
        if (_this.state.currentOrg === row.Org) {
            if (req.Ref === row.Ref) {
                const dt=moment(row.OrgDateTime,"YYYY-MM-DD HH:mm:ss");
                data.push({
                    actions: [<span key="comment-list-reply-to-0">{row.Category}</span>],
                    author: row.Username,
                    avatar: 'https://p7.hiclipart.com/preview/340/956/944/computer-icons-user-profile-head-ico-download.jpg',
                    content: (
                        <p>
                            {row.Body}
                        </p>
                    ),
                    datetime: (
                        <Tooltip
                            title={dt
                                .subtract(2, 'days')
                                .format('YYYY-MM-DD HH:mm:ss')}
                        >
        <span>
          {dt
              .subtract(2, 'days')
              .fromNow()}
        </span>
                        </Tooltip>
                    ),
                })
            }
        }
    }
    return (
        <List
            className="comment-list"
            header={`${data.length} replies`}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
                <li>
                    <Comment
                        actions={item.actions}
                        author={item.author}
                        avatar={item.avatar}
                        content={item.content}
                        datetime={item.datetime}
                    />
                </li>
            )}
        />
    )
}
