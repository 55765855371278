import React from "react";
import PageBuilder from "../../../components/web/Common/PageBuilder";
import Connector from "../../../redux/connector";

class CrmEntityAttributesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let StateStorage = [
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
            {stateField: "Modules", moduleEntity: "entity", table: "entity-categories", noCondition: false},
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Name", label: "Name"},
            {id: "Category", label: "Category"},
            {id: "IsBase", label: "IsBase"},
            {id: "HasPayment", label: "HasPayment"},
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
            /*{keyState: "Registries", targetKey: "Org", storeVariable: "registries"},
            {keyState: "Trunks", targetKey: "Org", storeVariable: "trunks"},
            {keyState: "Agents", targetKey: "Org", storeVariable: "agents"},
            {keyState: "Data", targetKey: "Org", storeVariable: "extensions"},*/
        ];

        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },
        ];

        let bldFields = [
            {
                label: "Name",
                message: "Please input your source name",
                type: "text",
                StateKey: "Name",
                required:true
            },
            {
                label: "Category",
                message: "Please select your category",
                type: "option",
                StateKey: "Category",
                required:true,
                options:[
                    {key:"human",val:"Human"},
                    {key:"object",val:"Object"},
                ]
            },
            {
                label: "Is Base?",
                message: "Please select your Base",
                type: "boolean",
                StateKey: "IsBase",
                required:true,
                options:[
                    {key:"yes",val:"Yes"},
                    {key:"no",val:"No"},
                ]
            },
            {
                label: "Have Payment?",
                message: "Please select your Payment",
                type: "boolean",
                StateKey: "HasPayment",
                required:true,
                options:[
                    {key:"yes",val:"Yes"},
                    {key:"no",val:"No"},
                ]
            },
        ]

        return (
            <PageBuilder
                pageTitle={"Module Category"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Modules"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Module Category"}
                postMsgResponse={"Thank you, category module submitted!"}
                postMsgWarning={"Are you sure you want to submit this module category"}
                postEndpoint={"/entity/insert/entity-categories"}
            />
        )
    }

}

export default Connector(CrmEntityAttributesPage);
