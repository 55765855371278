import React from "react";
import {Button, InputItem, WhiteSpace, WingBlank} from 'antd-mobile';
import {createForm} from 'rc-form';
import Connector from "../../../redux/connector";
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'

import MyLogo from "../../../assets/img/logo.png";
import {PostToPbx} from "../../../api/http-post";
import {SaveToken} from "../../../api/token";
import moment from "moment";

const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}


class MobileLoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notice: '',
            type: 'money',
            autoLogin: true,
            username: "",
            password: "",
            error:""
        };
    }

    onChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }
    submitLogin=async ()=>{
        let _this=this;
        let hub={
            ...this.state
        }
        hub.username = hub.username.trim();
        hub.password = hub.password.trim();
        hub.username = hub.username.toLocaleLowerCase();
        let endpoint = "/user/login";
        await PostToPbx(hub,endpoint,async (data)=>{
            console.log("):(--->Login response: ",data);
            if(data ===null){
                return
            }
            if (!data.boo) {
                _this.setState({
                    error:"Login Fail please try again! "+data.msg
                })
                alert("Login Fail please try again! "+data.msg);
                return
            }
            let orgCode=data.user.OrgCode;

            for(let i in _this.state.Agents){
                const row=_this.state.Agents[i];
                console.log("UUUUU--> ",row.Username,"===",data.user.Username);
                if(row.Username ===data.user.Username){
                    orgCode = row.Org
                }
            }
            data.user.OrgCode = orgCode;
            SaveToken(data.token,data.user);
            _this.props.SaveLoginInfo({
                Token: data.token,
                User: data.user,
                Date: moment().format("DD MMM YYYY"),
                Time: moment().format("HH:MM:s")
            });
            window.location.href = "#/main-menu";
        })

    }
    render() {
        const {getFieldProps} = this.props.form;
        const {type} = this.state;
        return (
            <div style={{margin:15}}>
                <div style={styles.containerLogo}>
                    <img src={MyLogo} style={styles.logo} alt={"Logo"}/>
                </div>
                <div style={styles.containerLabel}>
                    <label style={styles.titleLabel} color={"primary"}>
                        Login Authentication
                    </label>
                </div>

                <div style={styles.containerInput}>
                    <InputItem
                        style={styles.input}
                        {...getFieldProps('focus')}
                        clear
                        placeholder="Enter your username"
                        ref={el => this.inputUsername = el}
                        value={this.state.username}
                        onChange={(e) => this.onChange(e, "username")}
                    >Username:</InputItem>
                </div>
                <div style={styles.containerInput}>

                    <InputItem
                        style={styles.input}
                        {...getFieldProps('focus')}
                        clear
                        placeholder="Enter your password"
                        ref={el => this.inputPassword = el}
                        type={"password"}
                        value={this.state.password}
                        onChange={(e) => this.onChange(e, "password")}
                    >Password:</InputItem>
                </div>

                <div style={styles.containerInput}>
                    <Button
                        type="primary"
                        style={styles.button}
                        onClick={this.submitLogin}
                    >Submit</Button><WhiteSpace/>
                </div>

                <div style={styles.containerInput}>
                    <label style={styles.error}>{this.state.error}</label>
                </div>


            </div>
        );
    }
}

const H5NumberInputExampleWrapper = createForm()(MobileLoginPage);
export default Connector(H5NumberInputExampleWrapper)

const styles = {
    error:{
      color:"red" ,
      fontWeight: "bold"
    },
    button: {
        marginTop:10,
        minWidth: "100%",
        /*backgroundColor: "blue",*/
    },
    bigContainer: {
        minWidth: "100%",
        backgroundColor: "red",
        minHeight: "100%",
        flex: 1,/*justifyContent:"center",*/
        display: "flex",

        alignItems: "center"
    },
    input: {
        minWidth: "90%",
        /*backgroundColor:"red"*/
    },
    titleLabel: {
        color: "lightblue",
        fontWeight: "bold",
        fontSize: 18
    },
    containerInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 5,
        minWidth: "90%",
    },
    containerLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },
    containerLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
    },
    logo: {
        maxHeight: 50,
        minHeight: 50,
    }
}
