import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
    EditingState,
    ViewState,
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    DayView,
    MonthView,
    Appointments,
    ConfirmationDialog,
    AppointmentTooltip,
    AppointmentForm,
    WeekView,
    CurrentTimeIndicator,
    DateNavigator,
    Toolbar,
    ViewSwitcher,
    Resources,
    GroupingPanel,
    DragDropProvider,
     EditRecurrenceMenu,


} from '@devexpress/dx-react-scheduler-material-ui';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import classNames from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from "@material-ui/core/Grid";
import Room from "@material-ui/icons/Room";
import TiltoolsConent from "./TiltoolsContent";
import AppointmentFormLayout from "./AppointmentFormLayout";
import {
    NUMBER_EDITOR,
    TITLE_TEXT_EDITOR,
    MULTILINE_TEXT_EDITOR,
} from '@devexpress/dx-scheduler-core';
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
/**
 *
 * To customize the the content or template use the following link
 *
 * https://github.com/DevExpress/devextreme-reactive/tree/master/packages/dx-react-scheduler-material-ui/src/templates
 */



export default ({
                    schedulerData,
                    currentDate,
                    commitChanges,
                    typeOfView,
                    startDayHour,
                    endDayHour,
                    shadePreviousCells,
                    shadePreviousAppointments,
                    updateInterval,
                    currentDateChange,
                    resourcesData,
                    resourcesCategories,
                    resourcesIndex,
                    onAgentChange,
                    grouping,
                    changePhone
                }) => {

    const FlexibleSpace = withStyles(styles, { name: 'FlexibleSpace' })(
        ({ classes, ...restProps }) => (
            <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
                <LocationSelector />
            </Toolbar.FlexibleSpace>
        ),
    );

    const isRestTime = date => (
        date.getDay() === 0 || date.getDay() === 6 || date.getHours() < 9 || date.getHours() >= 18
    );

    const TimeTableCell = withStyles(styles, { name: 'TimeTableCell' })(({ classes, ...restProps }) => {
        const { startDate } = restProps;
        if (isRestTime(startDate)) {
            return <WeekView.TimeTableCell {...restProps} className={classes.weekendCell} />;
        } return <WeekView.TimeTableCell {...restProps} />;
    });

    const DayScaleCell = withStyles(styles, { name: 'DayScaleCell' })(({ classes, ...restProps }) => {
        const { startDate } = restProps;
        if (startDate.getDay() === 0 || startDate.getDay() === 6) {
            return <WeekView.DayScaleCell {...restProps} className={classes.weekEnd} />;
        } return <WeekView.DayScaleCell {...restProps} />;
    });
    const Filter = withStyles(styles, { name: 'TextField' })(({ onCurrentFilterChange, currentFilter, classes }) => (
        <TextField
            placeholder="Filter"
            className={classes.textField}
            value={currentFilter}
            onChange={({ target }) => onCurrentFilterChange(target.value)}
            variant="outlined"
            hiddenLabel
            margin="dense"
        />
    ));

    const handleButtonClick = (locationName, locations) => {
        if (locations.indexOf(locationName) > -1) {
            return locations.filter(location => location !== locationName);
        }
        const nextLocations = [...locations];
        nextLocations.push(locationName);
        return nextLocations;
    };

    const getButtonClass = (locations, classes, location) => (
        locations.indexOf(location) > -1 && classes.selectedButton
    );

    const LocationSelector = withStyles(styles, { name: 'LocationSelector' })(({ onLocationsChange, locations, classes }) => (
        <ButtonGroup className={classes.locationSelector}>
            {resourcesCategories.map((location, index) => (
                <Button
                    className={classNames(classes.button, getButtonClass(resourcesCategories, classes, location))}
                    onClick={() => onAgentChange(handleButtonClick(location, resourcesCategories))}
                    key={location}
                >
                    <React.Fragment>
                        <span className={classes.shortButtonText}>{resourcesIndex[index]}</span>
                        <span className={classes.longButtonText}>{location}</span>
                    </React.Fragment>
                </Button>
            ))}
        </ButtonGroup>
    ));

    const renderBottom=({appointmentData})=>{
        return(
            <Grid container alignItems="center">
                <Grid item xs={2} style={{backgroundColor:"red"}}>
                    {appointmentData.notes}
                </Grid>
            </Grid>
        )
    }
    const AppointmentContent = withStyles(styles, { name: 'AppointmentContent' })(({ classes, ...restProps }) => (
        <Appointments.AppointmentContent {...restProps} className={classes.apptContent} />
    ));
    const Appointment = withStyles(styles, { name: 'Appointment' })(({ classes, ...restProps }) => (
        <Appointments.Appointment
            {...restProps}
            className={classes.appointment}
        />
    ));
    return (
        <React.Fragment>

        <Paper>
            <Scheduler
                data={schedulerData}

            >
                <ViewState
                    currentDate={currentDate}
                    onCurrentDateChange={currentDateChange}
                    defaultCurrentViewName="Day"
                />
                <GroupingState
                    grouping={grouping}
                />
                <DayView
                    startDayHour={startDayHour || 8}
                    endDayHour={endDayHour || 19}
                />
                <WeekView
                    startDayHour={startDayHour || 8}
                    endDayHour={endDayHour || 19}
                />
                <MonthView
                    startDayHour={startDayHour || 8}
                    endDayHour={endDayHour || 19}
                />

                <Toolbar flexibleSpaceComponent={FlexibleSpace} />
                <EditingState
                    onCommitChanges={commitChanges}
                />
                <EditRecurrenceMenu />

                <ViewSwitcher/>
                <ConfirmationDialog/>
                <Appointments />
                <DateNavigator/>
                <Resources
                    data={resourcesData}
                    mainResourceName="members"
                />
                <IntegratedGrouping />

                <CurrentTimeIndicator
                    shadePreviousCells={shadePreviousCells}
                    shadePreviousAppointments={shadePreviousAppointments}
                    updateInterval={updateInterval}
                />
                <AppointmentTooltip
                    showCloseButton
                    showOpenButton
                    contentComponent={TiltoolsConent}
                />
                <AppointmentForm/>



                <GroupingPanel />

            </Scheduler>
        </Paper>
        </React.Fragment>
    );
}

const styles = ({ spacing, palette }) => ({
    flexibleSpace: {
        margin: '0 auto 0 0',
        display: 'flex',
        alignItems: 'center',
    },
    textField: {
        width: '75px',
        marginLeft: spacing(1),
        marginTop: 0,
        marginBottom: 0,
        height: spacing(4.875),
    },
    locationSelector: {
        marginLeft: spacing(1),
        height: spacing(4.875),
    },
    button: {
        paddingLeft: 10,
        paddingRight: 10,
        width: 200,
        '@media (max-width: 800px)': {
            width: 250,
            fontSize: '0.75rem',
        },
        marginLeft:10,
        marginRight:10
    },
    selectedButton: {
        background: palette.primary[400],
        color: palette.primary[50],
        '&:hover': {
            backgroundColor: palette.primary[500],
        },
        border: `1px solid ${palette.primary[400]}!important`,
        borderLeft: `1px solid ${palette.primary[50]}!important`,
        '&:first-child': {
            borderLeft: `1px solid ${palette.primary[50]}!important`,
        },
    },
    longButtonText: {
        '@media (max-width: 800px)': {
            display: 'none',
        },
    },
    shortButtonText: {
        '@media (min-width: 800px)': {
            display: 'none',
        },
    },
    title: {
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    textContainer: {
        lineHeight: 1,
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    time: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    container: {
        width: '100%',
    },
    weekendCell: {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
        '&:hover': {
            backgroundColor: fade(palette.action.disabledBackground, 0.04),
        },
        '&:focus': {
            backgroundColor: fade(palette.action.disabledBackground, 0.04),
        },
    },
    weekEnd: {
        backgroundColor: fade(palette.action.disabledBackground, 0.06),
    },
});
