import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Breadcrumb, Avatar, List} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import GridItem from "../../Common/dashboard/Grid/GridItem";
import TableDataView from "../../Common/tables/TableDataView";
import {UpCaseFirst} from "../../../api/fn";

const {Option} = Select;


export default (props) => {
    let {_this} = props;
    const showDrawer = () => {
        _this.setState({
            btnNewLead: true,
        });

    };

    const onClose = () => {
        _this.setState({
            btnNewLead: false,
        });
    };
    const onAddRecord = () => {
        _this.setState({
            showNewLeadList: false
        })
    }
    const onChange = (v1, key) => {
        let tmp = _this.state;
        tmp.newLeadEntry[key] = v1.target.value;
        _this.setState(tmp);
    }

    const renderList = () => {
        const columns = [
            {id: "LeadName", label: "Full name"},
            {id: "OrgDateTime", label: "Created"},
            {id: "Phone", label: "Phone"},
            {id: "DialNumber", label: ""},
        ]
        let ls = _this.state.NewLeadResults;
        for (let i in ls) {
            let row = ls[i];
            row.Phone = row.Data.Phone;
            if(row.Data.Phone ==="" || row.Data.Phone ===undefined){
                row.DialNumber="";
            }else{
                row.DialNumber = (
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a onClick={() => _this.dialRequest(row.Data.Phone,row.LeadRef)}
                               style={{cursor: "pointer", color: "#0092ff"}}>
                                Dial
                                <Avatar
                                    style={{color: "#0092ff", marginLeft: 5}}
                                    src="https://www.blazethread.com/wp-content/uploads/2019/07/220-2207243_phone-icon-png-mobile-phone-icon-circle-png.png"/>
                            </a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                )
            }
        }
        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Lead List")}
                    onAddRecord={onAddRecord}
                    /* onDelete={this.onDelete}*/
                />
            </GridItem>
        )
    }

    const renderForm = (name, label) => {
        return (
            <Col span={12}>
                <Form.Item
                    name={name}
                    label={label}
                    rules={[{required: true, message: 'Please input ' + label}]}
                >
                    <Input
                        value={_this.state.newLeadEntry[name]}
                        placeholder={"Please input your field " + label}
                        onChange={(value) => onChange(value, name)}
                        name={name}
                    />
                </Form.Item>
            </Col>
        )
    }

    let visible = _this.state.btnAccount;
    let isOpen = _this.state.showNewLeadList;

    console.log("Disposition SowDrawer > ", visible, " > ", _this.state.btnAccount)
    return (
        <>
            <Button type="default" onClick={showDrawer}>
                <PlusOutlined/> New Lead
            </Button>
            <Drawer
                title="Create a new account"
                width={720}
                onClose={onClose}
                visible={_this.state.btnNewLead}
                bodyStyle={{paddingBottom: 80}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{marginRight: 8}}>
                            Close
                        </Button>
                        {/*<Button onClick={onClose} type="primary">
                            Submit
                        </Button>*/}
                    </div>
                }
            >
                {!isOpen ?
                    <Form layout="vertical" hideRequiredMark>
                        <Row gutter={16}>
                            {renderForm("name", "Name", true)}
                            {renderForm("surname", "Surname", false)}
                        </Row>
                        <Row gutter={16}>
                            {renderForm("phone", "Phone", true)}
                            {renderForm("email", "Email", false)}
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="comment"
                                    label="Comment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'please enter comment if any',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        value={_this.state.newLeadEntry.name}
                                        rows={4} placeholder="please enter comment"
                                        onChange={(value) => onChange(value, "comment")}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Button onClick={_this.submitNewLead} type="primary" style={{float: "right"}}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>

                    </Form> :
                    <Form layout={"vertical"}>
                        <Row gutter={16}>
                            <Col span={24}>
                                {renderList()}
                            </Col>
                        </Row>
                    </Form>
                }

            </Drawer>

        </>
    );

}
