import React from "react";
import {Upload, Button, Select, Form} from 'antd';
import { UploadOutlined ,DownloadOutlined} from '@ant-design/icons';
import UploadDocument from "./UploadDocument";
import {DropzoneDialog} from "material-ui-dropzone";
import {ToDataUrl} from "../../../api/fn";
import Configures from "../../../configures";

const {Option} = Select;


export default class UploadFormPinDoc extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            selectedCategory:"",
            howUpload:false,
            base64String:"",
            fileName:"",
            filesLimit:1,
            Outputs:[],
            selectedInfo:"",
            downloading:false,
        }
    }
    handleChange = (value, key) => {
        let tmp = this.state;
        tmp[key] = value;
        this.setState(tmp)
        console.log("----(:)---> ",value,key)
    }
    onClickUpload=()=>{
        if(this.state.selectedCategory===""){
            return alert("Select your category first!")
        }
        this.setState({
            howUpload:true,
        })
    }
    handleCloseUpload=()=>{
        this.setState({
            howUpload:false,
            selectedCategory:""
        })
    }

    handleSaveFiles=async (files)=>{
        let _this = this;
        for(let i in files){
            let file = files[i];
            const reader = new FileReader();
            reader.onload =async () => {
                let files=_this.state.Outputs;
                _this.setState({
                    base64string: reader.result,
                    fileName:file.name,
                    Outputs:files,
                    howUpload:false,
                });
                _this.props._this.setState({
                    newUpload:{
                        category:_this.state.selectedCategory,
                        base64string: reader.result,
                        fileName:file.name,
                    }
                })
            }
            reader.readAsDataURL(file);
            reader.onerror = function (error) {
                console.log('Error uploading file: ', error);
            };
        }

    }



    renderInfosSelection = () => {
        let {_this}=this.props;
        let ls = [];
        for (let i in _this.state.Infos) {
            const row = _this.state.Infos[i];
            if (row.Org !== _this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        return (
            <Form.Item
                label={"Select Category"}
                name={"selectedInfo"}
                rules={[{required: true, message: "Please select your category!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChange(value, "selectedInfo")}
                    value={this.state.selectedInfo}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.Name}
                                value={record.Name}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }


    renderList=()=>{
        let record = this.props._this.state.selectedRecord;
        let ls=[];
        for(let key in record.DocsAdmin){
            let val = record.DocsAdmin[key];
            ls.push(key)
        }
        console.log("-=-=-=->> ",record)


            return(
                <Form.Item
                    style={{minWidth: "50%"}}
                    label={"Category"}
                    name={"selectedCategory"}
                    rules={[{required: true, message: "select your Category" }]}
                >
                    <Select
                        name={"Applicant"}
                        defaultValue=""
                        style={{width: "70%"}}
                        onChange={(e) => this.handleChange(e, "selectedCategory")}
                    >
                        {ls.map((item, index) => {
                            return (
                                <Option value={item}>{item}</Option>
                            )
                        })}
                    </Select>
                    <Button
                        style={{width: "30%"}}
                        onClick={this.onClickUpload}
                    >
                        <UploadOutlined /> Upload
                    </Button>
                </Form.Item>
            )

    }
    onClickApplicantFileAsUpload=()=>{
        const row =JSON.parse(this.state.selectedApplicantFile);
        // eslint-disable-next-line no-undef
        let _this=this;
        const server = Configures.ServerPbx;
        let endpoint = "/document/download/" + row.StoreRef;
        let imagepath =  server + "" + endpoint;
        let filename=row.DocRef+".pdf";
        this.setState({
            downloading:true,
        })
        ToDataUrl(imagepath,function (myBase64) {
            _this.setState({
                base64String:myBase64,
                fileName:filename,
                downloading:false,
            });
            _this.props._this.setState({
                newUpload:{
                    category:_this.state.selectedCategory,
                    base64string: myBase64,
                    fileName:filename,
                }
            })
        })
    }
    renderSelectedFileBox=()=>{
        let {_this} = this.props;
        let record = _this.state.selectedRecord;
        let ls=[];
        let files = _this.state.ApplicantFiles;
        for(let i in files){
            const row = files[i];
            if(row.Applicant !==record.Email){
                continue
            }
            ls.push(row);
        }

        return(
            <Form.Item
                style={{minWidth: "50%"}}
                label={"Applicant File"}
                name={"selectedApplicantFile"}
                rules={[{required: true, message: "select your File" }]}
            >
                <Select
                    name={"selectedApplicantFile"}
                    defaultValue=""
                    style={{width: "70%"}}
                    onChange={(e) => this.handleChange(e, "selectedApplicantFile")}
                >
                    {ls.map((item, index) => {
                        return (
                            <Option value={JSON.stringify(item)}>{item.DocRef}</Option>
                        )
                    })}
                </Select>
                <Button
                    style={{width: "30%"}}
                    onClick={this.onClickApplicantFileAsUpload}
                >
                    <DownloadOutlined />Download
                </Button>
            </Form.Item>
        )

    }
    render() {
        return(
            <div>
                {this.renderList()}
                <h3>{this.state.fileName}</h3>
                <hr/>
                <h3 style={{color:"blue"}}>Select file from Generate</h3>
                {this.renderSelectedFileBox()}
                {this.state.downloading?
                    <h3 style={{color:"red"}}>Please wait when downloading your file to local....</h3>:null}
                <DropzoneDialog
                    open={this.state.howUpload}
                    onSave={this.handleSaveFiles}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp','application/pdf']}
                    showPreviews={true}
                    maxFileSize={5000000}
                    onClose={this.handleCloseUpload}
                    onDrop={this.onDropHandler}
                    filesLimit={this.state.filesLimit}
                />
            </div>
        )
    }
}
