import React from "react";
import MyRadioList from "./MyRadioList";


export default (props)=>{
    let {_this}=props;
    let list=[
        /*{key:"now",val:"Now"},
        {key:"schedule",val:"Schedule"},*/
    ];
    for(let i in _this.state.Extensions){
        const row = _this.state.Extensions[i];
        if(row.Org!==_this.state.Org){
            continue;
        }
        list.push({
            key:row.Number,
            val:row.OutboundNumber,
        })
    }
    let defaultValue = _this.state.SelectOutbound;
    const onChange=(value)=>{
        _this.handleInput2( "SelectOutbound",value)
    }

    return(
        <div style={{marginTop: 20}}>
            <h3> Step 5: Ivr Audio</h3>
            <p>Please select your outbound number to show when the avm have been placed!.
                <br/>this is base ont the extension set on the pbx
            </p>
            <MyRadioList list={list} defaultValue={defaultValue} onChange={onChange}/>
        </div>
    )
}
