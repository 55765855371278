import {Menu} from "antd";
import React from "react";
import { Layout} from 'antd';
import {CheckDisplayModule} from "../../api/nav";
import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";
const { Header } = Layout;

export default (props)=>{

    let ls=[];
    const nav =props.nav;
    let defaultApp =nav.CurrentApp;
    console.log("(:)-->setAppName: ",defaultApp)
    for(let i in nav.menus){
        let module = i;
        ls.push(module)
    }

    const logoutUser=()=>{
        window.location.href="#/login"
    }
    const setAppName=(appName)=>{
        //todo let find the default menu for this app
        const store = nav.menus[appName];
        console.log("(:)-->setAppName: ",store)
        let defaultMenu = "";
        let defaultModule ="";

        for(let i in store){
            defaultModule = i;
            for(let a in store[i]){
                const row = store[i][a];
                if(row.isDefault){
                    defaultMenu = row.name;
                }
            }
            break
        }
        props.SetNavAppName(appName);
        props.SetNavAppModule(defaultModule);
        props.SetNavAppMenu(defaultMenu);
    }

    return(
        <Header className="header">
            <div className="logo" />
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[defaultApp]}>
                {ls.map((item,index)=>{
                    if(!CheckDisplayModule(item,props)){
                        return null
                    }
                    return(
                        <Menu.Item
                            key={item}
                            onClick={()=>setAppName(item)}
                        >
                            {item.toUpperCase()}
                        </Menu.Item>
                    )
                })}
                <Menu.Item
                    key={"logout"}
                    onClick={()=>logoutUser()}
                    style={{float:"right"}}
                >
                    <LogoutOutlined />
                    Logout
                </Menu.Item>

            </Menu>
        </Header>
    )
}

/*
21232f297a57a5a743894a0e4a801fc3

 */
