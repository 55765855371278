import React, {Fragment} from 'react';
import {Header, MenuModule, MenuInfoBar} from "./index";
import Grid from '@material-ui/core/Grid';
import {Paper} from "@material-ui/core";
import LeftSide from "./LeftSide";
import Connector from "../../../redux/connector";
import SocketManager from "../../../websocket/SocketManager";
import GridContainer from "../dashboard/Grid/GridContainer";

const styles = {
    Paper: {
        padding: 20,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        minHeight: "90%",
    }
}

class Wallpaper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        let contentSize=10;
        let displayLeftPanel=true;
        if(typeof this.props.displayLeftPanel !=="undefined"){
            if(!this.props.displayLeftPanel){
                contentSize=12;
                displayLeftPanel=false;
            }
        }

        return (
            <SocketManager>
                <GridContainer style={{
                    minHeight: "100%",
                    minWidth: "100%",
                }}>
                <Grid container sm={12}>
                    <Header {...this.props}/>
                    <MenuModule/>
                </Grid>
                <Grid container sm={12} style={{
                    marginTop: -5,
                    paddingTop: 35,
                    minHeight: "100%",

                }}>
                    {displayLeftPanel ?
                        <Grid item sm={2}>
                            <Paper style={{
                                marginLeft: 5,
                                minHeight: "100%",
                                minWidth: "100%",
                                borderRadius: 5,
                            }}>
                                <LeftSide {...this.props} style={{
                                    minHeight: "100%",

                                }}/>
                            </Paper>
                        </Grid>:null
                    }
                    <Grid item sm={contentSize} md={contentSize} lg={contentSize} style={{minHeight: "100%"}}>
                        <Paper style={{...styles.Paper}}>
                            <MenuInfoBar props={this.props} pageTitle={this.props.pageTitle || "----"}/>
                            <Fragment>
                                {this.props.children}
                            </Fragment>
                        </Paper>
                    </Grid>
                </Grid>
                </GridContainer>
            </SocketManager>
        );
    }
}

export default Connector(Wallpaper);

