import {
    PBX_CHANGE_EXTENSION,
    PBX_CHANGE_AGENT,
    PBX_CALL_OUT_REQUEST,
    PBX_CALL_OUT_END,
    PBX_CALL_OUT_FORM,
    PBX_CALL_OUT_DO,
    PBX_CALL_OUT_CALLERID,
    PBX_CALL_INCOME_REQUEST,
    PBX_CALL_INCOME_ANSWER,
    PBX_CALL_INCOME_HANGUP,
    PBX_CHANGE_REGISTRY,
    PBX_CALLING_STATUS,
    CURRENT_CALLS,
    PBX_CALL_INCOME_ACTIVE,
} from "../../constants";

const PbxSetRegistry=(payload) => {
    return {
        type: PBX_CHANGE_REGISTRY,
        payload
    }
}

const PbxSetOutCaller=(payload) => {
    return {
        type: PBX_CALL_OUT_CALLERID,
        payload
    }
}

const PbxOutCallDo=(payload) => {
    return {
        type: PBX_CALL_OUT_DO,
        payload
    }
}


const PbxOutCallEnd = (payload) => {
    return {
        type: PBX_CALL_OUT_END,
        payload
    }
}

const PbxChangeExtension = (payload) => {
    return {
        type: PBX_CHANGE_EXTENSION,
        payload
    }
}

const PbxChangeAgent= (payload) => {
    return {
        type: PBX_CHANGE_AGENT,
        payload
    }
}
const PbxOutCallRequest= (payload) => {
    return {
        type: PBX_CALL_OUT_REQUEST,
        payload
    }
}

const PbxOutCallForm=(payload) => {
    return {
        type: PBX_CALL_OUT_FORM,
        payload
    }
}
const PbxInCallRequest=(payload) => {
    return {
        type: PBX_CALL_INCOME_REQUEST,
        payload
    }
}
const PbxInCallAnswer=(payload) => {
    return {
        type: PBX_CALL_INCOME_ANSWER,
        payload
    }
}

const PbxInCallHangup=(payload) => {
    return {
        type: PBX_CALL_INCOME_HANGUP,
        payload
    }
}

const PbxCallingStatus=(payload) => {
    return {
        type: PBX_CALLING_STATUS,
        payload
    }
}

const SetCurrentCalls=(payload)=>{
    return {
        type: CURRENT_CALLS,
        payload
    }
}
const SetActiveIncomeCall=(payload)=>{
    return {
        type: PBX_CALL_INCOME_ACTIVE,
        payload
    }
}



export {
    PbxChangeExtension ,
    PbxChangeAgent,
    PbxOutCallRequest,
    PbxOutCallEnd,
    PbxOutCallForm,
    PbxOutCallDo,
    PbxSetOutCaller,
    PbxInCallRequest,
    PbxInCallAnswer,
    PbxInCallHangup,
    PbxSetRegistry,
    PbxCallingStatus,
    SetCurrentCalls,
    SetActiveIncomeCall
}
