import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Table} from 'antd';
import { PlusOutlined,SearchOutlined } from '@ant-design/icons';

const { Option } = Select;


export default (props)=>{
    let {_this}=props;
    const showDrawer = () => {
        _this.setState({
            btnSearchLead: true,
        });

    };

    const onClose = () => {
        _this.setState({
            btnSearchLead: false,
        });
    };

    const onChange=(value)=>{
        _this.setState({
            searchLeadKey: value.target.value,
        });
    }

    const requestLoad=async (ref,pool)=>{
        _this.setState({
            inRef:ref,
            inPool:pool,
            LeadRequest:null
        });
       await _this.requestLead()
    }

    let visible = _this.state.btnSearchLead;

    let dataSource = [];
    const sData=_this.state.SearchLeadResults;
    for(let i in sData){
        let row =sData[i];
        if(row.Reload){
            row.Load=(
                <a
                    onClick={()=>requestLoad(row.Ref,row.Pool)}
                    style={{color:"blue",cursor:"pointer"}}
                >Load</a>
            )
        }else{
            row.Load="";
        }

        dataSource.push(row)
    }

    const columns = [
        {
            title: 'Action',
            dataIndex: 'Load',
            key: 'Load',
        },
        {
            title: 'Field',
            dataIndex: 'SearchKey',
            key: 'SearchKey',
        },
        {
            title: 'Value',
            dataIndex: 'SearchValue',
            key: 'SearchValue',
        },
        {
            title: 'Display Name',
            dataIndex: 'Display',
            key: 'Display',
        },
        {
            title: 'Last Disposition',
            dataIndex: 'OrgDateTime',
            key: 'LastDisposition',
        },
    ];
    return (
        <>
            <Button type="default" onClick={showDrawer}>
                <SearchOutlined /> Search Lead
            </Button>
            <Drawer
                title="Search Lead"
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        {/*<Button onClick={onClose} type="primary">
                            Submit
                        </Button>*/}
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name="name"
                                label="Phone & Identity"
                                rules={[{ required: true, message: 'Please enter phone or identity' }]}
                            >
                                <Input placeholder="Please enter user name" onChange={onChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button type="default" onClick={_this.requestSearchLead} style={{marginTop:30}} >
                                <SearchOutlined /> Search
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Table dataSource={dataSource} columns={columns} />
                            {/*<Form.Item
                                name="description"
                                label="Description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please enter url description',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} placeholder="please enter url description" />
                            </Form.Item>*/}
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );

}
