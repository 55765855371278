import React, {Fragment} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import Title from "../../../components/Common/Title";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {Button, Select, Form, Input} from 'antd';
import {fetchDataEntity} from "../../../api/entity-data";
import {PostToPbx} from "../../../api/http-post";
import {FindFieldFromRecordWithData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import Paper from "@material-ui/core/Paper";
import Configures from "../../../configures";
import PopupApplicantDetail from "../../../components/web/Document/PopupApplicantDetail";

const {Option} = Select;

class DocGenApplicationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Applications: [],
            Applicants: [],
            ApplicantFiles: [],
            Companies: [],
            Infos: [],
            Variables: [],
            pages: [],
            show: false,
            genShow: false,
            selectedCompany: "",
            selectedInfo: "",
            selectedApplicant: "",
            selectedRow: null,
            newEntry: {},
            showPopupDetail: false,
            popupTitle: "",
            popupData: {},
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Infos", "pdf/entity", "info", this, false);
        await fetchDataEntity("Variables", "pdf/entity", "variable", this, false)
        await fetchDataEntity("Applications", "pdf/entity", "application", this, false)
        await fetchDataEntity("Applicants", "pdf/entity", "applicant", this, false)
        await fetchDataEntity("ApplicantFiles", "pdf/entity", "applicant-files", this, false)
        //await fetchDataEntity("Data", "manager/entity", "extensions", this, false);
    }
    handleChange = (value, key) => {

        this.setState({
            [key]: value,
        })
    }
    handleChangeCompany = (value, key) => {

        this.setState({
            [key]: value,
            selectedInfo: ""
        })
    }
    SubmitApplication = async () => {
        console.log("SubmitApplication > ", this.state);
        let _this = this;
        let hub = {
            Org: this.state.selectedCompany,
            Applicant: this.state.Ref,
            DocRef: this.state.selectedInfo,
            Data: this.state.newEntry
        }
        let endpoint = "/pdf/entity/insert/application";
        console.log("SubmitApplication request > ", hub);
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            alert("New application added")
            _this.setState({
                isLoading: false,
                pages: []
            })
            await fetchDataEntity("Applications", "pdf/entity", "application", _this)
            _this.setState({
                show: false,
                newEntry: {},
                Ref: "",
            })
        });
    }
    generateFile = async (row) => {
        let _this = this;
        let hub = {...row}
        let endpoint = "/pdf/generate/request";
        console.log("generateFile request > ", hub);
        _this.setState({
            isLoading: true,
        })
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            await fetchDataEntity("ApplicantFiles", "pdf/entity", "applicant-files", _this)
            _this.setState({
                isLoading: false,
                pages: []
            })
            alert("Application pdf file generated!")
        });
    }
    renderCompaniesSelection = () => {
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )

    }
    renderInfosSelection = () => {
        let ls = [];
        for (let i in this.state.Infos) {
            const row = this.state.Infos[i];
            if (row.Org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        return (
            <Form.Item
                label={"Select Category"}
                name={"selectedInfo"}
                rules={[{required: true, message: "Please select your category!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChange(value, "selectedInfo")}
                    value={this.state.selectedInfo}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.Name}
                                value={record.Name}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    downloadFile = (link, rec) => {
        let url = link;
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a'); // create html element anchor
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = rec.Org + "-" + rec.DocRef + ".pdf"; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();
    }

    openDocLink = (link, rec) => {
        console.log("Opening link pdf link request > ", link, " > ", rec);
        document.title = rec.Org + "-" + rec.DocRef;
        window.open(link, '_blank');
    }
    findApplicantFileLink = (rec) => {
        const server = Configures.ServerPbx;
        let link = "";
        for (let i in this.state.ApplicantFiles) {
            const row = this.state.ApplicantFiles[i];
            if (row.Org === rec.Org && row.Applicant === rec.Applicant && row.DocRef === rec.DocRef) {
                if (row.StoreSystem === "dropbox") {
                    let endpoint = "/document/download/" + row.StoreRef;
                    let url = server + "" + endpoint;
                    return url
                } else {
                    let endpoint = "/pdf/download/" + row.StoreRef;
                    let url = server + "" + endpoint;
                    return url
                }
            }
        }
    }
    popupApplicationDetail = (applicant, data) => {
        this.setState({
            showPopupDetail: true,
            popupTitle: applicant,
            popupData: data
        })
    }
    renderList = () => {
        if (this.state.show) {
            return
        }
        let _this = this;
        const findDisplayName = (ref, org) => {
            for (let i in _this.state.Applicants) {
                const row = _this.state.Applicants[i];
                if (row.Ref === ref || org === row.Org) {
                    return row.Display;
                }
            }
            return ref;
        }
        const columns = [
            {id: "Action", label: ""},
            {id: "Client", label: "Company"},
            {id: "Applicant", label: "Applicant"},
            {id: "DocRef", label: "Doc Reference"},
            {id: "Detail", label: "Detail"},
        ];
        let ls = [];
        for (let i in this.state.Applications) {
            let row = this.state.Applications[i];
            if (row.Org !== this.state.selectedCompany) {
                continue
            }
            if (row.DocRef !== this.state.selectedInfo) {
                continue
            }
            const company = FindFieldFromRecordWithData(
                "CustomerNumber",
                row.Org,
                "Name",
                this.state.Companies
            );
            const display = findDisplayName(row.Applicant, row.Org);
            let details = [];
            for (let i in row.Data) {
                const val = row.Data[i];
                details.push({
                    key: i,
                    val: val
                })
            }

            let downloadLink = this.findApplicantFileLink(row)
            console.log("downloadLink> ", row);

            ls.push({
                Client: company,
                Applicant: display,
                DocRef: row.DocRef,
                Detail: <a style={{cursor: "pointer", color: "blue"}}
                           onClick={() => this.popupApplicationDetail(display, details)}>More Detail</a>,
                Action: <div>
                    <a
                        onClick={() => this.generateFile(row)}
                        style={{color: "blue", cursor: "pointer"}}
                    >Generate</a>
                    {downloadLink !== "" ?
                        <Fragment>
                            &nbsp;|&nbsp;
                            <a
                                onClick={() => this.downloadFile(downloadLink, row)}
                                style={{color: "blue", cursor: "pointer"}}
                            >Download</a>
                            &nbsp;|&nbsp;
                            <a
                                onClick={() => this.openDocLink(downloadLink, row)}
                                style={{color: "blue", cursor: "pointer"}}
                            >View</a>
                        </Fragment> : null
                    }
                </div>
            })
        }
        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Records")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    noAddButton={true}
                />
            </GridItem>
        )
    }

    renderNewForm = () => {
        if (!this.state.show) {
            return
        }
        let _this = this;
        const handleInputRef = (value) => {
            let tmp = _this.state;
            tmp.Ref = value;
            _this.setState(tmp)
        }
        const handleInputEntry = (e, key) => {
            let value = e.target.value;
            let tmp = _this.state;
            tmp.newEntry[key] = value;
            _this.setState(tmp)
            console.log("handleChange :> ", value, key)
        }
        let dataTemplate = [];
        for (let i in this.state.Variables) {
            const row = this.state.Variables[i];
            if (row.Org !== this.state.selectedCompany || row.DocRef !== this.state.selectedInfo) {
                continue
            }
            dataTemplate.push(row);
        }

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };

        return (
            <Paper style={{maxWidth: "50%"}}>
                <h4 style={{color: "blue"}}>
                    New Application
                    <Button
                        type="primary"
                        onClick={() => this.setState({show: false})}
                        style={{float: "right", backgroundColor: "red", marginLef: 10}}
                    >
                        Cancel
                    </Button>
                </h4>
                <Form
                    {...layout}
                    onSubmit={this.SubmitApplication}
                    className="login-form"
                    onFinish={this.SubmitApplication}
                    onFinishFailed={this.SubmitApplication}
                    name="basic"
                    initialValues={{remember: true}}
                    style={{minWidth: "50%"}}
                >
                    <Form.Item
                        style={{minWidth: "50%"}}
                        label={"Applicant"}
                        name={"Application"}
                        rules={[{required: true, message: "select your applicant"}]}
                    >
                        <Select
                            name={"Applicant"}
                            defaultValue=""
                            style={{width: "100%"}}
                            onChange={(e) => handleInputRef(e)}
                        >
                            {this.state.Applicants.map((record, index) => {
                                if (record.Org !== this.state.selectedCompany) {
                                    return null
                                }
                                return (
                                    <Option value={record.Ref}>{record.Display}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    {dataTemplate.map((row) => {
                        return (
                            <Form.Item
                                style={{minWidth: "50%"}}
                                label={row.Label}
                                name={row.Field}
                                rules={[{required: true, message: "select your " + row.Label}]}
                            >
                                <Input
                                    onChange={(e) => handleInputEntry(e, row.Field)}
                                    type={"text"}
                                />
                            </Form.Item>
                        )
                    })}

                    <Form.Item {...tailLayout}>

                        <Button type="primary" htmlType="submit" style={{float: "right", marginLeft: 10}}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </Paper>
        )

    }


    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Document Application</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                    </div>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderInfosSelection()}
                    </div>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {!this.state.show ?
                            <Button type="primary" onClick={() => this.setState({show: true})} style={{float: "right"}}>
                                New Application
                            </Button> :
                            null
                        }
                    </div>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderList()}
                    </div>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderNewForm()}
                    </div>
                </div>

                <PopupApplicantDetail
                    visible={this.state.showPopupDetail}
                    title={this.state.popupTitle}
                    data={this.state.popupData}
                    _this={this}
                />

                <LoadingProcessing open={this.state.isLoading}/>

            </Page>
        )
    }

}


export default Connector(DocGenApplicationPage);
const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
