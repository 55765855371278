import {GetUserToken} from "./token";
import Configures from "../configures";
import {Select} from "antd";
import React from "react";

const server = Configures.ServerPbx;
export const GetOptionsFromData=(data,targetValue,targetLabel)=>{
    let ls=[];
    for(let i in data){
        const row = data[i];
        ls.push({
            key:row[targetValue],
            val:row[targetLabel]
        })
    }
    return ls;
}
export const GetDataField=(data,targetField,targetValue,ReturnField)=>{
    let result ="";
    for(let i in data){
        const row = data[i];
        if(row[targetField]===targetValue){
            result=row[ReturnField]
        }
    }
    return result
}
export function ToDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
export const RecordExtractDataField=(org,record,TypeKey,attributes)=>{
    for(let i in attributes){
        const row=attributes[i];
        if(row.Org!==org){continue}
        if(row[TypeKey]){
            const tField = row.FieldName;
            const val = record.Data[tField];
            return val;
        }
    }
    return ""
}
export   const RenderModules = (org,modules) => {
    let ls = [];
    for (let i in modules) {
        const row = modules[i];
        if (row.Org === org) {
            ls.push({
                key: row.Name,
                val: row.Name,
            })
        }
    }
    return ls
}
export const attachImageProfile = (files, dataIn) => {

    let ls = [];
    for (let i in dataIn) {
        let row = dataIn[i];
        const link = FindImages(row, files)
        row.link = link;
        ls.push(row)
    }

    return ls;
}
export const buildAssociationData=(props,self,targetDisplay)=>{
    const Databases = props.state.Records;
    const files = props.state.Files;
    const StoreAssociations = props.state.Associations;
    let associations=[];
    //let self = this;

    const GetRecord=(module,ref)=>{
        let names = "---";
        for (let i in Databases) {
            const row = Databases[i];
            if (row.Module === module && row.Ref===ref) {
                names = getDisplayNameEntity(props,module,row,targetDisplay)
            }
        }
        return names;
    }
    for(let i in StoreAssociations){
        let row=StoreAssociations[i];
        /**
         * Get Names of participants
         */
        row.MainName  =GetRecord(row.MainEntity,row.Main);
        row.SupplierName  =GetRecord(row.SupplierEntity,row.Supplier);
        /**
         * Get Image profile link
         */
        row.Mainimg =FindImages(row.Main, files);
        row. Supplierimg =FindImages(row.Supplier, files);
        /**
         * save into array
         */
        associations.push(row);
    }

    return associations;

}
export const getDisplayNameEntity=(props,module,record,targetDisplay)=>{
    const fieldList=getListDisplayField(props,module,targetDisplay);
    let names="";
    for(let  i in fieldList){
        const row=fieldList[i];
        names +=record.data[row.FieldName]+" "
    }
    return names
}
export const getListDisplayField=(props,module,targetDisplay)=>{
    let ls = [];
    const attributes = props.state.Attributes;
    for (let i in attributes) {
        const row = attributes[i];
        if (row.Module === module) {
            if(row[targetDisplay]){
                ls.push(row)
            }
        }
    }
    ls =SortData("Position",ls);
    return ls
}
export const findRecordType=(name,fieldsList)=>{
    let rows=fieldsList;
    for(let i in rows){
        let row=rows[i];
        if(row.field===name){
            return row.type
        }
    }
    return "text"
}
export const findRecordUniqueKey = (fieldsList,_this) => {
    //const listFields = _this.getListField();
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row.Unique) {
            let fValue = _this.state[row.FieldName];
            return fValue
        }
    }
    return ""
}
export const findRecordDisplayKey = (fieldsList,key,_this) => {
    let name="";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];

        if (row[key]) {
            let fValue = _this.state[row.FieldName];
            name = name+fValue+" ";
        }
    }
    console.log("findRecordDisplayKey > ",name," > ",key," > ",fieldsList)
    return name;
}

export const findRecordKey = (fieldsList,key,_this) => {
    let name="";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            let fValue = _this.state[row.FieldName];
            return fValue;
        }
    }
    return name;
}
export const findRecordKeyField = (fieldsList,key,_this) => {
    let name="";
    let ls = _this.state.Attributes;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            return row.FieldName;
        }
    }
    return name;
}

export const SortData = (sortKey, data) => {
    const keyOne = sortKey;
    if(data ===null){
        return [];
    }
    data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
    return data;
}
export const ConvertBase64IntoString = (b64) =>{
    let b = new Buffer(b64,'base64');
    let s = b.toString();
    console.log("convertBase64IntoString > ",s); // Outputs: "SGVsbG8gV29ybGQh"
    return s;
}

export const ConvertStringToBase64=(htmlBody)=> {
    let b = new Buffer(htmlBody);
    let s = b.toString('base64');
    console.log("convertStringToHtml > ",s); // Outputs: "SGVsbG8gV29ybGQh"
    return s;
}


export const IsFindInArray = (searchKey, searchValue, data) => {
    for(let i in data){
        const row=data[i];
        if (typeof row[searchKey] !== "undefined") {
            if(row[searchKey]===searchValue){
                return true
            }
        }
    }

    return false
}
export const  UpcaseFirst=(inWord)=>{
    const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
    return upper;
}
export const FindImages = (rowIn, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.org !== rowIn.Org){continue}
        if (row.module !== rowIn.Module){continue}
        if (row.ref !== rowIn.Ref){continue}
            if(row.type.trim() === "profile"){
                const dropboxId = row.dropboxref;
                const endpoint ="/document/download/"+dropboxId;
                let url = server + "" + endpoint;
                return url;
        }
    }
    return "https://lh3.googleusercontent.com/proxy/JhRvqc9ggu11-VxutWzJk9JTDeHXijqLAjvIcsQwMJGBXAL642lreMWW4KjPI-KvA1f9smVix0z0K6ZzDAQNFMEFpCrTjwaTVFcdnaz3QnSWc7-ENiTWNXD2fKY-iOgyl9m9EF99DbnMeXqrjqN-Nhyf"
}
export const extraDataFromCol=(data,cols)=>{
    let out=[];
    let fields={};
    for(let i in cols){
        const row=cols[i];
        fields[row.id]=true;
    }
    for(let i in data){
        const row=data[i];
        let o={};
        for(let key in row){
            let val = row[key];
            /*let ans = Array.isArray(val);
            if(!ans && (typeof val !== "boolean")){
                val = val.replace("<nil>","");
            }*/

            if(typeof fields[key]!=="undefined"){
                o[key]=val
            }
        }
        out.push(o)
    }
    console.log("extraDataFromCol > ",out);
    return out;
}
const AttachImageProfile = (files, dataIn) => {
    let ls = [];
    for (let i in dataIn) {
        let row = dataIn[i];
        const link = FindImages(row.ref, files)
        row.link = link;
        ls.push(row)
    }
    return ls;
}

export const FindFieldFromRecord = (keyValue, keyReturn, row) => {
    if (typeof row[keyReturn] !== "undefined") {
        return row[keyReturn]
    }
    return keyValue
}
export const FindFieldFromRecordWithData = (keySearch, keyValue, keyReturn, data) => {
    console.log("ZZZZ---> ",keySearch, keyValue, keyReturn, data)
    for (let i in data) {
        const row = data[i];
        if (typeof row[keySearch] !== "undefined") {
            if (row[keySearch] === keyValue) {
                return row[keyReturn]
            }
        }
    }
    return keyValue
}
export const IsNotSuperUser = () => {
    let user = GetUserToken();
    console.log("LOGIN ROLE: ", user.Role);
    if (user.Role !== "super") {
        window.location.href = "#/access-authorize-denied";
        return false
    }
    return true
}
export const UpCaseFirst = (inWord) => {
    const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
    return upper;
}
export const GetRowOptions = (key, val, data) => {
    let ls = [];
    for (let i in data) {
        const row = data[i];
        console.log("GetRowOptions > ", key, val, " > ", row)
        ls.push({
            key: row[key],
            val: row[val]
        })
    }
    return ls
}
export const GetRowOptionsCondition = (key, val, data,conditions) => {
    let ls = [];
    for (let i in data) {
        const row = data[i];
        console.log("GetRowOptions > ", key, val, " > ", row);
        let boo=true;
        for(let a in conditions){
            let record = conditions[a];
            if( row[record.key]!==record.val){
                boo=false;
            }
        }
        if(!boo){continue}
        ls.push({
            key: row[key],
            val: row[val]
        })
    }
    return ls
}
