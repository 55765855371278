import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormLabel from "@material-ui/core/FormLabel";

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

export default ({row,onChangeText,dataKeyVal,styleIn}) =>{
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const handleChange = event => {
        setAge(event.target.value);
        onChangeText(row,event);
    };

    let ls=[];
    if(typeof dataKeyVal !=="undefined"){
        for(let i in row.options){
            const item=row.options[i];
            let ans = Array.isArray(item)
            if(ans){
                ls.push(item)
            }else{
                ls.push({
                    key:item,
                    val:item
                })
            }
        }
    }else{
        for(let i in row.options){
            const item=row.options[i];
            let ans = Array.isArray(item)
            if(ans){
                ls.push(item)
            }else{
                ls.push({
                    key:item,
                    val:item
                })
            }

        }
    }

    return (
        <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin} style={styleIn}>
            <FormLabel component="legend" className={"legend"}>{row.label}</FormLabel>
            <FormControl className={classes.margin} style={styleIn}>
                <InputLabel htmlFor="demo-customized-select-native">{row.label}</InputLabel>
                <NativeSelect
                    name={row.name}
                    id="demo-customized-select-native"
                    value={age}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                >
                    <option value="" >Choose...</option>
                    {ls.map((item) => {
                        return (
                            <option value={item.key}>{item.val}</option>
                        )
                    })}

                </NativeSelect>
            </FormControl>
        </FormControl>
    );
}
