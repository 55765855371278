import React from "react";
import {SocketContext} from './Context';
import store from '../redux/store';
import Connector from "../redux/connector";
import {ConnectToSocketPublisher} from "./publisher-inbound";
import CallCard from "../webcall/CallCard";
import CloudCall, { doHangup, answer, sendDtmf,doCall} from "../webcall/JanusSipCall";
import InCall from "../webcall/inCall";
import OuCall from "../webcall/outCall";

class SocketManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Username: "",
            Extension: "",
            Role: "",
            FullName: "",
            prices:{},
        }
        const _this = this;
        store.subscribe(async () => {
            const data = store.getState();
            await _this.receivedProps(data);
        });
    }
    static contextType = SocketContext;
    componentDidMount = async () => {
        ConnectToSocketPublisher({...this.props, state: this.state})
    }
    receivedProps = async (nextProps) => {
        if (nextProps.pbx.Extension.username !== this.state.extension) {
            console.log("@@--@@-< different extrn: ",
                nextProps.pbx.Extension.username, " > ", this.state.extension)
            this.setState({
                extension: nextProps.pbx.Extension.username
            });
            await this.registerCloudCalls()
        }
    }
    registerCloudCalls = async () => {
        /**
         * Let now register to our pbx to make and receive call
         * */
        await CloudCall({
            ...this.props,
            host: this.state.host,
            extension: this.state.extension,
            secret: this.state.secret,
            AlertBox: this.AlertBox,
            port: this.state.port,
            displayname: "Agent " + this.state.extension,
            receivedIncomingCall: this.receivedIncomingCall,
            hangupIncomingCall: this.hangupIncomingCall,
            answerIncomingCall:this.answerIncomingCall,
            onRegistry:this.onRegistry,
            onUnregistered:this.onUnregistered,
            outboundCallStatus:this.outboundCallStatus
        });
        return null
    }
    outboundCallStatus=(status)=>{
        this.props.PbxCallingStatus({status:status});
    }
    onIncomingCall = (result) => {
        console.log("onIncomingCall ZXXXXX---> ", result)
        alert(" onIncomingCall > " + JSON.stringify(result))
    }
    onRegistry=(username)=>{
        this.props.PbxSetRegistry({status:"registered",peer:username})
    }
    onUnregistered=(username)=>{
        this.props.PbxSetRegistry({status:"unregistered",peer:username})
    }
    hangupIncomingCall = (event, msg, result, jsep) => {

        let callId = msg.call_id;
        let code = msg.result.code;
        let reason = msg.result.reason;
        let requestHangup = {
            callId: callId,
            code: code,
            reason: reason
        }
        this.props.PbxInCallHangup({...requestHangup})
        console.log("hangupIncomingCall (&&)==> ", requestHangup);
    }

    answerIncomingCall = (event, msg) => {
        console.log("answerIncomingCall (&&)==> ", msg);
        this.props.PbxInCallAnswer({callId:msg.call_id})
    }
    AlertBox = (typeOfMessage, message) => {
        console.log("AlertBox :> ", typeOfMessage, message);
        this.setState({
            alertOpen: true,
            alertType: typeOfMessage ? "success" : "error",
            alertMessage: message,
        })
    }
    renderSipControllerCard = () => {

        return (
            <CallCard
                callStatus={this.state.callStatus}
                makeCall={this.dial}
                stopCall={this.stop}
                sipStatus={this.state.sipStatus}
            />
        )
    }
    setSipInfo = () => {
        const username = this.state.sipExtension;
        const secret = this.state.sipSecret;
        const server = this.state.sipServer;
        if (username === "" || secret === "" || server === "") {
            return alert("Please provide correct sip info, not blank")
        }
        this.props.PbxChangeExtension({
            username: username,
            secret: secret,
            server: server,
        });

        //this.registerCloudCalls();
    }
    requestCallForm = () => {
        this.props.PbxOutCallShowForm({isSet: true})
    }
    renderOutCallBox = () => {
        if (!this.props.pbx.OutCall.dialing) {
            return null
        }
        //todo make call logic here

        return (
            <div style={styles.outCall}>
                <OuCall
                    {...this.props}
                    style={{float: "right"}}
                    duration={this.state.duration}
                    handleInput={this.handleInput}
                    dial={this.dial}
                    stop={this.stop}
                    sendDtmf={sendDtmf}
                />
            </div>
        )//let see
    }
    renderInCallBox = () => {
        if (!this.props.pbx.InCall.dialing) {
            return null
        }
        //todo make call logic here

        return (
            <div style={styles.inCall}>
                <InCall
                    {...this.props}
                    style={{float: "right", borderWidth: this.state.mySize,}}
                    duration={this.state.duration}
                    answerIncomingCall={answer}
                    hangupIncomingCall={doHangup}
                    inCall={this.props.pbx.InCall}
                />
            </div>
        )
    }
    receivedIncomingCall = (event, msg, result, jsep) => {
        let caller = msg.result.username.split('@')[0].split(':')[1];
        let server = msg.result.username.split('@')[1];
        let callId = msg.call_id;
        let incomeCall = {
            caller: caller,
            server: server,
            callId: callId
        };
        this.props.PbxInCallRequest({...incomeCall});
        console.log("receivedIncomingCall (&&)==> ", incomeCall)
    }

    render() {
        return (
            <SocketContext.Provider value={{
                prices: this.state.prices
            }}>
                <div style={{minWidth:"100%",minHeight:"100%"}}>
                    {this.props.children}

                    {this.renderOutCallBox()}
                    {this.renderInCallBox()}
                    <video
                        className="rounded centered"
                        id="video-device"
                        width={320}
                        height={240}
                        autoPlay
                        playsinline
                        muted="muted"
                    />
                    <audio
                        id={"audio-device"}
                    />
                    <audio
                        className="rounded centered"
                        id="roomaudio"
                        width="100%"
                        height="100%"
                        autoPlay
                    />
                </div>
            </SocketContext.Provider>
        )
    }

}

export default Connector(SocketManager);


const styles = {
    outCall: {
        position: "fixed",
        bottom: 0,
        left: 10,
        minWidth: "30%",
        minHeight: "25%",
        maxWidth: "40%",
        borderWidth: 5,
        borderColor: "#009688",
        borderStyle: "solid",
        shadowColor: "red",
        shadowOpacity: 0.8,
        shadowRadius: 2,
        zIndex: 110,
        boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.4)',
        transform: 'translate3d(0px,0,0) scale(1) rotateX(0deg)',
        shape: 'M20,20 L20,380 L380,380 L380,20 L20,20 Z',
        textShadow: '0px 5px 15px rgba(255,255,255,0.5)',
        backgroundColor: "white"
    },
    inCall: {
        position: "fixed",
        bottom: 150,
        right: 0,
        minWidth: "5%",
        maxHeight: "60%",
        maxWidth: "20%",

        borderColor: "red",
        borderStyle: "solid",
        shadowColor: "red",
        shadowOpacity: 0.8,
        shadowRadius: 2,
        zIndex: 110,
        boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.4)',
        transform: 'translate3d(0px,0,0) scale(1) rotateX(0deg)',
        shape: 'M20,20 L20,380 L380,380 L380,20 L20,20 Z',
        textShadow: '0px 5px 15px rgba(255,255,255,0.5)',
        backgroundColor: "white"
    }
}

