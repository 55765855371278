import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {UpCaseFirst, GetRowOptions, IsNotSuperUser,FindFieldFromRecordWithData} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert,Anchor} from "antd";
import {LockOutlined} from "@ant-design/icons";

const {Option} = Select;
const { Link } = Anchor;

class PbxSettingRegistryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Ranges:[],
            isLoading: false,
            component: "list",
            newMember:"",
        }
    }

    componentDidMount = async () => {
        IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "manager/entity", "queues", this,false);
        await fetchDataEntity("Ranges", "manager/entity", "ranges", this,false);
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onDelete = () => {
        this.setState({
            component: "list"
        })
    }
    onFinish = async (values) => {
        if (!window.confirm("Are you sure you want to submit this range ?")) {
            return
        }
        let _this = this;
        let user = GetUserToken();
        let hub = {...values};
        let members = {};
        let arr=values.Members.split(",");
        for(let i in arr){
            const ext = arr[i];
            members[ext] = ext;
        }
        hub.Members = members;
        let endpoint = "/manager/entity/insert/queues";
        this.submitPost(hub,endpoint,"Tank you, Queue submitted")
    };
    submitPost=async (hub,endpoint,successMsg)=>{
        let _this=this;
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , range submitted!")
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value,
        })
    }

    removeMember=async (item,queue)=>{
        if(!window.confirm("Are you sure you want to remove member "+item+" from the queue "+queue.Name+"?")){
            return
        }
        let members={};
        for(let i in queue.Members){
            const val = queue.Members[i];
            if(item !==val){
                members[i] =val;
            }
        }
        queue.Members =members;
        delete(queue.Members2);
        let endpoint = "/manager/entity/insert/queues";
        console.log("removeMember > ",queue)
        await this.submitPost(queue,endpoint,"Tank you, Member Queue remove submitted")
    }
    newMember=async (queue)=>{
        let newMember = this.state.newMember;
        if(newMember===""){
            return alert("Please provider extension, empty!")
        }
        //TODO let check if the extension is part of this company range
        let boo=false;
        for(let i in this.state.Ranges){
            let row = this.state.Ranges[i];

            if(row.Org !==queue.Org){
                continue
            }
            if(typeof row.Extensions[newMember]!=="undefined"){
                boo = true;
                break;
            }
        }
        if(!boo){
            return alert("Sorry your extension is not in range of this company!")
        }
        if(!window.confirm("Are you sure you want to add member "+newMember+" to the queue "+queue.Name+"?")){
            return
        }
        if( queue.Members===null){
            queue.Members = {};
        }
        queue.Members[newMember]=newMember;
        delete(queue.Members2);
        delete(queue.Client);
        let endpoint = "/manager/entity/insert/queues";
        await this.submitPost(queue,endpoint,"Tank you, Queue submitted")
    }


    getColumns = () => {
        return [
            {id: "Client", label: "Client"},
            {id: "Name", label: "Name"},
            {id: "Login", label: "Login"},
            {id: "Identify", label: "Identify"},
            {id: "Members2", label: "Members"},
        ];

    }
    getMembers=(queue)=>{
        let ls=[];
        for(let i in queue.Members){
            ls.push(queue.Members[i]);
        }

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        return(
            <div>
                {ls.map((item)=>{
                    return(
                        <div style={{
                            display:"flex",
                            flex:1,
                            flexDirection:"row",
                            justifyContent: "space-around",
                            alignItems: "center",
                            margin:3
                        }}>
                            <span>{item}</span>

                            <a
                                onClick={()=>this.removeMember(item,queue)}
                                style={{cursor:"pointer",color:"lightblue"}}
                            >Remove</a>

                        </div>
                    )
                })}

                <div style={{
                    display:"flex",
                    flex:1,
                    flexDirection:"row",
                    justifyContent: "space-around",
                    alignItems: "center",
                }}>

                    <Input name={"newMember"} onChange={this.handleChange}/>
                    <Button type="primary"  onClick={()=>this.newMember(queue)}>
                        Add
                    </Button>

                </div>

            </div>
        )
    }
    getMembers2=(queue)=>{
        let ls="";
        for(let i in queue.Members){
            ls  = ls +i+" ,";
        }
        console.log("2222 this.getMember > ",queue)
        return ls
    }

    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        const state = this.state.Data;
        console.log("**** this.getMember > ",state)

        let ls = []; //this.state.Data;
        for(let i in this.state.Data){
            let row = this.state.Data[i];
            row.Client = FindFieldFromRecordWithData("CustomerNumber",row.Org ,"Name",this.state.Companies);
            row.Members2 =this.getMembers(row); //JSON.stringify(row.Members) ;//

            ls.push(row)
        }

        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Queue")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }

        let companies = GetRowOptions("CustomerNumber", "Name", this.state.Companies);

        let inputList = [
            {
                name: "Org",
                label: "Name of Company",
                type: "option",
                required: true,
                options: companies,
                message: "Please input your company name"
            },
            {name: "Name", label: "Range Name", type: "text", required: true, message: "Please input your range name"},
            {name: "Login", label: "Login", type: "text", required: true, message: "Please input your Login"},
            {name: "Identify", label: "Identify", type: "text", required: true, message: "Please input your Identify"},
            {name: "Members", label: "Members e.g. 3001,3002,..", type: "text", required: true, message: "Please input your Members"},
        ]


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };


        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New Queue"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    /*onChange={this.handleChange}*/
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Queue Management</Title>
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(PbxSettingRegistryPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}




