import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";

export default class Examples extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible : false
        }
    }


    render() {
        let {title,image,descriptionText,descObject,visible,closeModal}=this.props;
        let ls = [];
        for(let key in descObject){
            const val =descObject[key];
            ls.push({
                key:key,
                val:val
            })
        }

        descObject= ls;
        return (
            <section>
                <Modal
                    visible={visible}
                    effect="fadeInUp"
                    onClickAway={() => closeModal()}

                >
                    <Grid container sm={12} style={{marginTop: 35, minHeight: "100%"}} component={"div"}>
                        <Grid item sm={6}>
                            <img src={image} alt={""} style={{maxWidth:"90%"}}/>

                        </Grid>
                        <Grid item sm={6}>
                            <h2 id="simple-modal-title">{title}</h2>
                            <div id="simple-modal-description">
                                {descriptionText}
                                <ul>
                                    {descObject.map((row, index) => {
                                        return (
                                            <li key={index}>
                                                <span style={{fontWeight: "bol"}}>{row.key}:&nbsp;</span>
                                                <span style={{color: "gray"}}><b>{row.val}</b></span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Grid>
                        <Grid item sm={12}>
                            <Button
                                onClick={() => closeModal()}
                                style={{float: "right", color: "red", fontWeight: "bold", fontSize: 18}}
                            >Close</Button>
                        </Grid>

                    </Grid>
                </Modal>
            </section>
        );
    }
}
