import   {DASHBOARD_LIVE_CALL_IN,DASHBOARD_LIVE_CALL_OUT,DASHBOARD_LIVE_PEER_STATUS} from '../../constants';
const initial ={
    LiveOutbounds:null,
    LiveInbounds:null,
    LivePeers:null,
}


const reduceDashboardLive = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case DASHBOARD_LIVE_CALL_IN:
            newState.LiveInbounds = action.payload;
            return Object.assign({}, newState);
        case DASHBOARD_LIVE_CALL_OUT:
            newState.LiveOutbounds = action.payload;
            return Object.assign({}, newState);
        case DASHBOARD_LIVE_PEER_STATUS:
            newState.LivePeers = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceDashboardLive;
