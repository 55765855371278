import React from "react";
import Title from "../../../components/Common/Title";
import {Button} from "antd";
import Page from "../../../layout/web/Page";
import Connector from "../../../redux/connector";
import PageBuilder from "../../../components/web/Common/PageBuilder";


class DocGenApplicantPage extends React.Component{
    constructor(props) {
        super(props);
        this.state={

        }
    }
    render() {
        let StateStorage = [
            {stateField: "Data", moduleEntity: "pdf/entity", table: "applicant", noCondition: false},
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Display", label: "Display name"},
            {id: "Ref", label: "Reference"},
            {id: "Phone", label: "Phone Number"},
            {id: "Email", label: "Address"},
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
        ];
        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },
        ];
        let bldFields = [
            {
                label: "Unique Reference",
                message: "Please input your unique reference",
                type: "text",
                StateKey: "Ref",
                required:true
            },{
                label: "Display Name",
                message: "Please select your Display name",
                type: "text",
                StateKey: "Display",
                required:true,
            },
            {
                label: "Phone Number",
                message: "Please select your Phone number",
                type: "text",
                StateKey: "Phone",
                required:true,
            },
            {
                label: "Email Address",
                message: "Please select your email address",
                type: "text",
                StateKey: "Email",
                required:true,
            },

        ]
        return (
            <PageBuilder
                pageTitle={"Document Applicant"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Data"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Document Applicant"}
                postMsgResponse={"Thank you, Applicant submitted!"}
                postMsgWarning={"Are you sure you want to submit this Applicant"}
                postEndpoint={"/pdf/entity/insert/applicant"}
            />
        )
    }


}
export default Connector(DocGenApplicantPage);

const styles ={
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
};


