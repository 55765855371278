import React from "react";
import Title from "../../../components/Common/Title";
import {Button} from "antd";
import Page from "../../../layout/web/Page";
import Connector from "../../../redux/connector";
import PageBuilder from "../../../components/web/Common/PageBuilder";


class DocGenInfoPage extends React.Component{
    constructor(props) {
        super(props);
        this.state={

        }
    }
    render() {
        let StateStorage = [
            {stateField: "Data", moduleEntity: "pdf/entity", table: "variable", noCondition: false},
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
            {stateField: "Infos", moduleEntity: "pdf/entity", table: "info", noCondition: true},
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "DocRef", label: "Doc. Category"},
            {id: "Field", label: "Field"},
            {id: "Label", label: "Label"},
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            },/*{
                keyName: "DocRef",
                keySearch: "Name",
                keyValue: "Name",
                keyReturn: "Name",
                stateEntity: "Infos"
            },*/
        ];
        let entityOptions = [
        ];
        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },{
                label: "Category",
                message: "Please select your category name",
                key: "Name",
                value: "Name",
                lookInState: true,
                targetStorage: "Infos",
                StateKey: "DocRef",
                Conditions:[{
                    targetStorage:"Companies",
                    targetStateKey:"Org",
                    localField:"Org"
                }]
            },
        ];
        let bldFields = [
            {
                label: "Field Name",
                message: "Please input your document variable field  name",
                type: "text",
                StateKey: "Field",
                required:true
            },{
                label: "Label",
                message: "Please input your Label",
                type: "text",
                StateKey: "Label",
                required:true,
            },

        ]
        return (
            <PageBuilder
                pageTitle={"Document Variable"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Data"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Document Variable"}
                postMsgResponse={"Thank you, Variable submitted!"}
                postMsgWarning={"Are you sure you want to submit this Variable"}
                postEndpoint={"/pdf/entity/insert/variable"}
            />
        )
    }


}
export default Connector(DocGenInfoPage);

const styles ={
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
};


