import React from "react";
import {Col, Form, Row, Select} from "antd";
import {RecordExtractDataField, RenderModules} from "../../../api/fn";
import MyCheckList from "./MyCheckList";
const {Option} = Select;


export default (props)=>{
    let {_this}=props;
    let ls=RenderModules(_this.state.Org,_this.state.Modules);
    let lsData=[];
    let dataHolder={};
    for(let i in _this.state.Records){
        const row = _this.state.Records[i];
        console.log("1 (:))--> ",row.Org,"!=",_this.state.Org,">",row.Org!==_this.state.Org," > ",_this.state.Modules)
        console.log("2 (:))--> ",row.Module,"!=",_this.state.EntityData,">",row.Module !==_this.state.EntityData)
        if(row.Org!==_this.state.Org){
            continue
        }
        if(row.Module !==_this.state.EntityData){
            continue
        }
        lsData.push(row.Ref+"--"+row.Names);
        const phone = RecordExtractDataField(row.Org,row,"Phone",_this.state.Attributes);
        dataHolder[row.Ref]={
            phone:phone,
            name:row.Names,
            row:row
        }
        /*lsData.push({
            key:row.Ref,
            val:RecordExtractDataField(row.Org,row,"Phone",_this.state.Attributes)
        });*/
    }

    console.log("User llisted > ",lsData)

    const onChange=(value)=>{
        console.log("data onChange > ",value)
        _this.handleInput2( "EntityData",value)
    }
    const onCheckListChange=(listIn)=>{
        let tmp=[];
        for(let i in listIn){
            const dataRow=listIn[i];
            const arrData=dataRow.split("--");
            const ref = arrData[0];
            let phone = ref;
            let rec = dataHolder[ref];
            phone = rec.phone;
            tmp.push({[ref]:phone})
        }
        _this.setState({
            SelectedData:tmp
        })
        console.log("SelectedData > ",tmp)
    }
    const getSelectedList=()=>{
        let ls=[];
        for(let i in _this.state.SelectedData){
            const row = _this.state.SelectedData[i];
            let key="",val="";
            for(let a in row){
                key=a;
                val=row[key];
            }
            const rec = dataHolder[key];
            let item=key+"--"+rec.name;
            ls.push(item)
        }
        return ls
    }
    return(
        <div style={{marginTop: 20}}>
            <h3> Step 2: Data</h3>
            <Row gutter={16} style={{marginTop: 20}}>
                <Col span={6}>
                    <Form.Item
                        label={"Entity CRM"}
                    >
                        <Select
                            name={"EntityData"}
                            defaultValue=""
                            onChange={(e) => onChange(e)}
                        >
                            {ls.map((record, index) => {
                                return (
                                    <Option value={record.key}>{record.val}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <MyCheckList plainOptions={lsData} defaultCheckedList={getSelectedList()} onCheckListChange={onCheckListChange}/>


        </div>
    )
}
