import React from "react";
import {GetUserToken} from "./token";
import {PostToPbx} from "./http-post";
import {doCall, doHangup} from "../webcall/JanusSipCall";
import AlertBox from "../webcall/AlertBox";


export const RequestLead = async (_this) => {
    _this.setState({
        isLoading: true
    })
    let hub = {
        Org: _this.state.currentOrg,// user.OrgCode,
        Username: _this.state.currentAgent,// user.Username,
        InRef:_this.state.inRef||"",
        InPool:_this.state.inPool||"",
    }
    console.log("RequestLead---> ",hub);
    let endpoint = "/lead/request/new";
    await PostToPbx(hub, endpoint, async (dataIn) => {
        const data = dataIn.RESULT;
        console.log("LLLLLLL---> ", data, dataIn);
        _this.setState({
            LeadRequest: data,
            isLoading: false,
            InRef:"",
            InPool:"",
        });
        if(data.Ref!==""){
            await RequestReportCallLogs(data.Ref,_this)
            await RequestReportStatusLogs(data.Ref,_this)
        }
    });
}

export const RequestReportCallLogs=async (ref,_this)=>{
    let hub = {
        Org: _this.state.currentOrg,
        Ref: ref,
    }
    let endpoint = "/lead/entity/list/batch-agent-call";
    await PostState(hub, endpoint,"LeadCallLogs",_this);
}
export const RequestReportStatusLogs=async (ref,_this)=>{
    let hub = {
        Org: _this.state.currentOrg,
        Ref: ref,
    }
    let endpoint = "/lead/entity/list/batch-agent-disposition";
    await PostState(hub, endpoint,"LeadStatusLogs",_this);
}
export const AltBox=(boo,message)=>{
    console.log("--Janus Message--> ",boo,message)
}
export const DialRequest =async (phone,ref,_this,displayMessage) => {
    console.log("dialRequest--> ", phone);
    const regStatus = _this.props.pbx.Registry.status;
    if(regStatus === "Not registered"){
        return alert("Sorry you can't make without register first, click on the online switch!")
    }

    if (_this.state.isDialing) {
        return alert("Please wait while another call in progress!");
    }
    if (phone === "") {
        return alert("Please provide number to call!");
    }
    if (!isValidNumber(phone)) {
        return alert("Error your number is not valid! 10 digit required")
    }
    let destination = "0" + phone.substring(phone.length - 9);
    //destination = destination.substring(destination.length - 9);

    doCall({
        to: destination,
        host: _this.props.pbx.Server.host,
        AlertBox: displayMessage,
        isVideoCall: false
    });
    //this.props.PbxOutCallShowForm({isSet:true})
    _this.setState({
        dialingNumber:phone
    });

    await submitCallLog(phone,ref,_this)

}
export const StopCalling = (_this) => {
    doHangup();
    _this.props.PbxOutCallEnd({is: true})
}
export const isValidNumber = (num) => {
    let str = num;
    let pattern = new RegExp("^[0-9]{10}$");
    let result = pattern.test(str);
    console.log("isValidNumber -> ",str," > ",result," > ",/^\d{10}$/.test(str));
    return true
}
export const  submitCallLog=async (phone,ref,_this)=>{
    let hub = {
        Org: _this.state.currentOrg,
        Agent: _this.state.currentAgent,
        Ref: ref,
        Phone:phone,
        CallRef:_this.props.pbx.Extension.username
    }
    let endpoint = "/lead/entity/insert/batch-agent-call";
    await PostState(hub, endpoint,"fakeCallLogSubmit",_this);
}
export const PostState=async (hub,endpoint,stateKey,_this)=>{
    _this.setState({
        isLoading: true
    })
    await PostToPbx(hub, endpoint, async (dataIn) => {
        const data = dataIn.RESULT;
        console.log(endpoint+" response ---> ", data, dataIn);
        _this.setState({
            [stateKey]: data,
            isLoading: false
        })
    });
}

export const OnChangePbxStatus = (state,_this) => {
    const regStatus = _this.props.pbx.Registry.status;
    console.log("onChangePbxStatus--> ", state, " > ", regStatus)
    if (state) {
        /**
         * let find extension to register
         * */
        let ext = null;
        for (let i in _this.state.Extensions) {
            const row = _this.state.Extensions[i];
            console.log("(((--->? ",row.AgentRef," == ",_this.state.currentAgent," > ",(row.AgentRef === _this.state.currentAgent));
            if (row.AgentRef === _this.state.currentAgent) {
                ext = row;
            }
        }
        /**
         * Let save to props for register
         * */

        if (ext === null) {
            return alert("Sorry we  can't register, agent not allocate")
        }
        const server = "voip2.easipath.com";
        const username = ext.Number;
        const secret = ext.Secret;
        console.log("2 (((--->? ",username," > " ,ext," > ",_this.state.Extensions);
        _this.props.PbxChangeExtension({
            username: username,
            secret: secret+username,
            server: server,
        });
    }
}

export const BuildData = (_this) => {
    const getDataCategory = (arr, type) => {
        let ls = [];
        for (let key in arr) {
            const val = arr[key];
            ls.push({
                key: key,
                val: val,
                type: type,
            })
        }
        return ls
    }
    const req = _this.state.LeadRequest;
    let leadPool = "---";
    let leadName = "---";//this.state.LeadRequest.LeadData.FullName;//"Merveilleux Biangacila";
    let leadState = "---";
    let phones = [], names = [], identities = [], allFields = [], emails = [], others = [];

    if (req === null) {
        return {leadPool, leadName, leadState, phones, emails, others, names, allFields, identities}
    }

    phones = getDataCategory(req.LeadData.Phone, "Phone");
    emails = getDataCategory(req.LeadData.Email, "email");
    names = getDataCategory(req.LeadData.Name, "name");
    identities = getDataCategory(req.LeadData.Identity, "identity");
    others = getDataCategory(req.LeadData.Other, "other");

    const combine1 = names.concat(phones);
    const combine2 = emails.concat(identities);
    allFields = combine1.concat(combine2);
    allFields = allFields.concat(others);

    leadName = req.LeadData.FullName.toLocaleUpperCase();
    leadPool = req.PoolName.toLocaleUpperCase();
    leadState = req.State.toLocaleUpperCase();

    return {leadPool, leadName, leadState, phones, emails, others, names, allFields, identities}

}
