import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Paper} from "@material-ui/core";
import Connector from "../../../redux/connector";
import {GetUserToken} from "../../../api/fetching-data";
import LogoImg from "../../../assets/img/logo.png";
import Grid from "@material-ui/core/Grid";
import SystemType from "./SystemType";
import {SAVE_MODULE_MENU,SAVE_APP_NAME} from "../../../constants/index"

class MenuModule extends Component {
    constructor(props){
        super(props);
    }

    onPressTab = (e) => {
        console.log("onPressTab > ", e)
        this.setModuleMenu(e, "", "");
    }
    setSystemAppName=(props,appName)=>{
        const listMenu=this.props.navigations.menus[appName];
        let module ,menu;
        let submenu="";
        for(let i in listMenu){
            module =i;
            const rows=listMenu[i];
            for(let a in rows){
                const row = rows[a];
                if(row.isDefault){
                    menu = row.name;
                    break
                }
            }
            break;
        }
        console.log("TTTX-> ",appName," > ",module," > ",menu," > ",this.props.navigations.menus);
        props.dispatch({
            type: SAVE_APP_NAME,
            payload: {
                app:appName,
                module: module,
                menu: menu,
                submenu:submenu,
            }
        });
        localStorage.setItem("@easisec-app", appName);
        localStorage.setItem("@easisec-module", module);
        localStorage.setItem("@easisec-menu", menu);
        localStorage.setItem("@easisec-submenu", submenu);

    }
    setModuleMenu = (module, menu, submenu) => {
        let selectedApp = this.props.navigations.currentApp;
        menu = "";
        let link = "";
        for (let i in this.props.navigations.menus[selectedApp][module]) {
            const row = this.props.navigations.menus[selectedApp][module][i];
            if (row.isDefault) {
                menu = row.name;
                link = row.link;
            }
        }

        this.props.dispatch({
            type: SAVE_MODULE_MENU,
            payload: {
                module: module,
                menu: menu,
                submenu
            }
        });

        localStorage.setItem("@easisec-module", module);
        localStorage.setItem("@easisec-menu", menu);
        localStorage.setItem("@easisec-submenu", submenu);
        window.location.href = "#/" + link;
    }
    getLoginName = () => {
        let user = this.props.login.User;
        if (user === null) {
            user = GetUserToken();
        }
        return {
            name: user.Surname[0].toLocaleUpperCase() + ". " + user.Name,
            company: user.OrgCode,
            role: user.Role,
        };
    }

    render() {
        let lsModules = [
            /*"booking2","trip","schedule","chauffeur","transfer","rental"*/
        ];
        let selectedMenus = this.props.navigations.menus.currentApp;
        for (let i in selectedMenus) {
            const module = i;
            const rows = this.props.navigations.menus[i];
            let appName = rows.length > 0 ? rows[0].app : "none";
            lsModules.push({module: module, app: appName});
        }


        return (
            <Fragment>
                <FullWidthTabs
                    onPressTab={this.onPressTab}
                    {...this.props}
                    lsModules={lsModules}
                    setSystemAppName={this.setSystemAppName}
                />
            </Fragment>
        )
    }

}

export default Connector(MenuModule);


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}

        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {

    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },

}));

function FullWidthTabs(props) {
    let {lsModules, onPressTab,setSystemAppName}=props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const isVerified = (moName) => {
        const store = props.verify;
        if (typeof store[moName] !== "undefined") {
            if (moName === "verification") {
                return false;
            }
            if (store[moName].status) {
                return true
            }
        }
        return false;
    }
    const getVerifiedMenus = () => {

        let ls = [];
        let currentApp=props.navigations.currentApp;
        let stores = props.navigations.menus[currentApp];

        for (let i in stores) {
            ls.push(i)
        }
        console.log("TTTxxxxx-> ",ls);
        return ls
    }

    let company=GetUserToken().OrgCode;//"Easipath PTY-LTD";

    /**
     *
     * Get the current schema
     */
    let currentSchema ={};
    let appName=props.navigations.currentApp;
    for(let i in props.navigations.schema){
        let row = props.navigations.schema[i];
        if(row.name===appName){
            currentSchema = row;
        }
    }


    return (
        <Grid container sm={12}>
            <Grid item sm={2} >
                <div style={{
                    flex:1,
                    alignItems:"center",
                    minHeight:"100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding:5,
                    maxHeight:15,
                }}>
                    <SystemType {...props} setSystemAppName={setSystemAppName}/>


                </div>
            </Grid>
            <Grid item sm={8}>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleChange}

                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        indicatorStyle={{
                            color:currentSchema.color, /*'#795548'*/
                        }}

                    >

                        {getVerifiedMenus().map((item, index) => {
                            return (
                                <Tab
                                    onClick={() => onPressTab(item)}
                                    label={item}
                                    {...a11yProps(index)}
                                />
                            )
                        })}
                    </Tabs>

                </Paper>
            </Grid>


            <Grid item sm={2} >
                <div style={{
                    flex:1,
                    alignItems:"center",
                    minHeight:"100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>
                    <Typography
                        component="h4"
                        style={{
                            color:currentSchema.color /*"orange"*/,
                            fontWeight:"bold",
                            fontSize:20
                        }}
                    >
                        {company}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
}


/*<Typography
                        component="h4"
                        style={{
                            color:"orange",
                            fontWeight:"bold",
                            fontSize:20
                        }}
                    >
                        {appName}
                    </Typography>*/
