import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker } from 'antd';
import { PlusOutlined,PhoneFilled } from '@ant-design/icons';

const { Option } = Select;


export default (props)=>{
    let {_this}=props;
    const showDrawer = () => {
        _this.setState({
            btnDisposition: true,
        });

    };

    const onClose = () => {
        _this.setState({
            btnDisposition: false,
        });
    };
    const submitDisposition=()=>{
        _this.submitDisposition();
        onClose()
    }

    const onChangeDisposition=(value)=>{
        let tmp=_this.state;
        tmp.newEntry.disposition =value;
        _this.setState(tmp);
    }
    const onChangeComment=(e)=>{
        let value=e.target.value;
        let tmp=_this.state;
        tmp.newEntry.comment =value;
        _this.setState(tmp);
    }

    let visible = _this.state.btnAccount;
    let lsDispositions=[];
    for(let i in _this.state.Dispositions){
        const row=_this.state.Dispositions[i];
        if(_this.state.currentOrg===row.Org){
            lsDispositions.push(row.Name);
        }
    }

    console.log("Disposition SowDrawer > ",visible," > ",_this.state.btnAccount)
    return (
        <>
            <Button type="default" onClick={showDrawer}>
                <PlusOutlined /> Disposition
            </Button>
            <Drawer
                title="Create a new disposition status"
                width={720}
                onClose={onClose}
                visible={_this.state.btnDisposition}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button onClick={submitDisposition} type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="disposition"
                                label="Disposition"
                                rules={[{ required: true, message: 'Please choose the approver' }]}
                            >
                                <Select placeholder="Please choose the disposition" onChange={onChangeDisposition}>
                                    {lsDispositions.map(item=>{
                                        return(<Option value={item}>{item.toLocaleUpperCase()}</Option>)
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="comment"
                                label="Comment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please enter comment if any',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} placeholder="please enter comment" onChange={onChangeComment} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );

}
