import React from "react";
import { Radio, Input } from 'antd';

export default class MyRadioList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
        };
    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        const value = e.target.value;
        this.setState({
            value:value,
        });
        this.props.onChange(value)
    };

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const { value } = this.state;
        return (
            <Radio.Group
                onChange={this.onChange}
                value={value}
            >
                {this.props.list.map((record,index)=>{
                    return(
                        <Radio style={radioStyle} value={record.key}>
                            {record.val}
                        </Radio>
                    )
                })}
            </Radio.Group>
        );
    }
}
