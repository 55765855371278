
import React from "react";
import {Paper} from "@material-ui/core";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import ImgDenied from "../../../assets/img/denied.gif";
import { Result, Button } from 'antd';
class WelcomePage extends React.Component {

    render() {
        return (
            <Page {...this.props}>
                <Paper style={styles.Paper}>
                    <Result
                        status="403"
                        title="403"
                        subTitle="Sorry, you are not authorized to access this page."
                        extra={<Button type="primary">Back Home</Button>}
                    />
                    <div style={{
                        display:"flex",
                        flex:1,
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth:100,
                        minHeight:100
                    }}>
                        <img src={ImgDenied}  alt={""} style={{minWidth:100,minHeight:100}}/>
                    </div>

                </Paper>
            </Page>
        )
    }
}

export default Connector(WelcomePage)

const styles={
    Paper:{
        padding:20,
        marginTop: 10,
        marginBottom:10,
        marginLeft:10,
        minHeight:"90%",
    }
}
