
import {connect} from 'react-redux';
import {
    SetNavAppContent,
    SetNavAppMenu,
    SetNavAppModule,
    SetNavAppName,
    SaveLoginInfo,
    SetNavAppLink,
    SetPbxLiveInbound,
    SetPbxLiveOutbound,
    SetPbxLivePeer,

    SetCurrentCalls,
    SetActiveIncomeCall
} from "./action";

import {
    PbxChangeExtension ,
    PbxChangeAgent,
    PbxOutCallRequest,
    PbxOutCallEnd,
    PbxOutCallForm,
    PbxOutCallDo,
    PbxSetOutCaller,
    PbxInCallRequest,
    PbxInCallAnswer,
    PbxInCallHangup,
    PbxSetRegistry,
    PbxCallingStatus
} from "./action/action-asterisk";


const mapDispatchToProps = dispatch => ({
    dispatch: dispatch,
    SetNavAppContent:(data)=>dispatch(SetNavAppContent(data)),
    SetNavAppMenu:(data)=>dispatch(SetNavAppMenu(data)),
    SetNavAppModule:(data)=>dispatch(SetNavAppModule(data)),
    SetNavAppName:(data)=>dispatch(SetNavAppName(data)),
    SaveLoginInfo:(data)=>dispatch(SaveLoginInfo(data)),
    SetNavAppLink:(data)=>dispatch(SetNavAppLink(data)),

    SetPbxLiveInbound:(data)=>dispatch(SetPbxLiveInbound(data)),
    SetPbxLiveOutbound:(data)=>dispatch(SetPbxLiveOutbound(data)),
    SetPbxLivePeer:(data)=>dispatch(SetPbxLivePeer(data)),

    PbxChangeExtension: (data) => dispatch(PbxChangeExtension(data)),
    PbxOutCallShowForm: (data) => dispatch(PbxOutCallForm(data)),
    PbxOutCallDo: (data) => dispatch(PbxOutCallDo(data)),
    PbxSetOutCaller: (data) => dispatch(PbxSetOutCaller(data)),
    PbxChangeAgent: (data) => dispatch(PbxChangeAgent(data)),
    PbxOutCallRequest: (data) => dispatch(PbxOutCallRequest(data)),
    PbxOutCallEnd: (data) => dispatch(PbxOutCallEnd(data)),
    PbxInCallRequest: (data) => dispatch(PbxInCallRequest(data)),
    PbxInCallAnswer: (data) => dispatch(PbxInCallAnswer(data)),
    PbxInCallHangup: (data) => dispatch(PbxInCallHangup(data)),
    PbxSetRegistry: (data) => dispatch(PbxSetRegistry(data)),
    PbxCallingStatus: (data) => dispatch(PbxCallingStatus(data)),

    SetCurrentCalls:(data)=>dispatch(SetCurrentCalls(data)),
    SetActiveIncomeCall:(data)=>dispatch(SetActiveIncomeCall(data)),
});
const mapStateToProps = state => ({
    menus:state.menus,
    nav:state.nav,
    liveCalls:state.liveCalls,
    pbx:state.pbx
});

export default (requestedClass)=>{
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(requestedClass)
}
