import React, {Fragment} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {
    UpCaseFirst,
    GetRowOptions,
    IsNotSuperUser,
} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {PostToPbx} from "../../../api/http-post";
import {
    Button,
    Col,
    Popover,
    Row,
    Divider,
    Card,
    List,
    Spin,
    Avatar,
    Breadcrumb,
    Switch,
    PageHeader,
    Descriptions
} from "antd";
import {PhoneFilled, StopOutlined} from '@ant-design/icons';
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import CommentLead from "../../../components/web/Lead/CommentLead";
import DispositionLead from "../../../components/web/Lead/DispositionLead";
import NewLead from "../../../components/web/Lead/NewLead";
import DashBox, {DashBoxLine} from "../../../components/web/Lead/DashBox";
import {PlusOutlined} from "@ant-design/icons";
import SearchLead from "../../../components/web/Lead/SearchLead";
import RecordLead from "../../../components/web/Lead/RecordingLead";
import {GetUserToken} from "../../../api/token";
import CommentLeadInner from "../../../components/web/Lead/CommentLeadInner";
import CloudCall, {doHangup, answer, sendDtmf, doCall} from "../../../webcall/JanusSipCall";
import AlertBox from "../../../webcall/AlertBox";
import Typography from "@material-ui/core/Typography";
import {Input} from 'antd';
import ReportDailyDisposition from "../../../components/web/Lead/ReportDailyDisposition";
import moment from "moment";
import {Tabs, Select, Space} from 'antd';

const {TabPane} = Tabs;

const {Search} = Input;

const currentDate = moment().format("YYYY-MM-DD");
let userN = GetUserToken();

class LeadAgentStattionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnDisposition: false,
            btnNewLead: false,
            btnComment: false,
            btnAccount: false,
            btnReportDaily: false,
            btnSearchLead: false,
            newCommentBody: "",
            newCommentCategory: "",
            CategoryComments: [],
            LeadRequest: null,
            currentOrg: userN.Org,
            currentAgent: userN.Username,
            currentAgentName: userN.FullName,
            currentRole: "super",
            inRef: "",
            inPool: "",
            LeadComments: [],
            Dispositions: [],
            Extensions: [],
            newEntry: {
                disposition: "",
                comment: "",
            },
            dialingNumber: "",
            startDate: currentDate,
            endDate: currentDate,
            StatsAgentDisposition: [],
            searchLeadKey: "",
            SearchLeadResults: [],
            RecordingLeadResults: [],
            NewLeadResults: [],
            newLeadEntry: {
                name: "",
                surname: "",
                phone: "",
                email: "",
                comment: "",
            },
            showNewLeadList: true,
            LeadCallLogs: [],
            LeadStatusLogs: [],
            DashboardAgents: null
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        let user = GetUserToken();
        this.setState({
            isLoading: true,
            currentOrg: user.OrgCode,
            currentAgent: user.Username,
            currentAgentName: user.FullName
        })
        await this.requestLead();
        await this.requestAgentNewLead();
        await this.requestDashboard();
        await fetchDataEntity("Companies", "client/entity", "company", this);
        await fetchDataEntity("CategoryComments", "lead/entity", "batch-category-comment", this, false);
        await fetchDataEntity("LeadComments", "lead/entity", "batch-agent-comment", this, false);
        await fetchDataEntity("Dispositions", "lead/entity", "batch-setting-disposition", this, false);
        await fetchDataEntity("Extensions", "/manager/entity", "extensions", this, false);
    }
    submitNewLead = async () => {
        let dataField = {};
        let fields = {};

        const entry = this.state.newLeadEntry;
        dataField["Name"] = entry.name;
        dataField["Phone"] = entry.phone;
        if (entry.surname !== "") {
            dataField["Surname"] = entry.surname;
        }
        if (entry.email !== "") {
            dataField["Email"] = entry.email;
        }

        fields["Name"] = "name";
        fields["Phone"] = "phone";
        if (entry.surname !== "") {
            fields["Surname"] = "name";
        }
        if (entry.email !== "") {
            fields["Email"] = "email";
        }

        let _this = this;
        let hub = {
            Org: this.state.currentOrg,
            Agent: this.state.currentAgent,
            AgentName: this.state.currentAgentName,
            Fields: fields,
            Data: dataField,
            CommentCategory: "new lead",
            CommentBody: entry.comment,
        }
        let endpoint = "/lead/entity/insert/batch-agent-newlead";
        this.setState({
            isLoading: true
        });
        await PostToPbx(hub, endpoint, async (dataIn) => {
            await _this.requestAgentNewLead();
            _this.setState({
                newLeadEntry: {
                    name: "",
                    surname: "",
                    phone: "",
                    email: "",
                    comment: "",
                },
                showNewLeadList: true
            })
            alert("New lead added!");
        });
    }
    requestAgentNewLead = async () => {
        let user = GetUserToken();
        let hub = {
            Org: user.Org,// this.state.currentOrg,
            Agent: user.currentRole !== "super" ? user.Username : "",
        }
        let endpoint = "/lead/entity/list/batch-agent-newlead";
        console.log("requestAgentNewLead > ", hub)
        await this.PostState(hub, endpoint, "NewLeadResults");
    }
    requestSearchLead = async () => {
        let hub = {
            Org: this.state.currentOrg,
            KeySearch: this.state.searchLeadKey,
        }
        let endpoint = "/lead/search/new";
        await this.PostState(hub, endpoint, "SearchLeadResults");
    }
    requestDashboard = async () => {
        let hub = {
            Org: this.state.currentOrg,
            StartDate: currentDate,
            EndDate: currentDate
        }
        let endpoint = "/lead/report/dashboard/agent";
        await this.PostState(hub, endpoint, "DashboardAgents");
    }

    requestRecordingLead = async () => {
        let hub = {
            Org: this.state.currentOrg,// user.OrgCode,
            KeyQuery: this.state.searchLeadKey,
            Params: [this.state.currentOrg.toLocaleLowerCase()],
        }
        let endpoint = "/lead/recording/search";
        await this.PostState(hub, endpoint, "RecordingLeadResults");
    }

    requestReportDisposition = async () => {
        let hub = {
            Org: this.state.currentOrg,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate,
            Username: this.state.currentAgent,
        }
        let endpoint = "/lead/report/stats/agent";
        await this.PostState(hub, endpoint, "StatsAgentDisposition");
    }

    requestReportCallLogs = async (ref) => {
        let hub = {
            Org: this.state.currentOrg,
            Ref: ref,
        }
        let endpoint = "/lead/entity/list/batch-agent-call";
        await this.PostState(hub, endpoint, "LeadCallLogs");
    }
    requestReportStatusLogs = async (ref) => {
        let hub = {
            Org: this.state.currentOrg,
            Ref: ref,
        }
        let endpoint = "/lead/entity/list/batch-agent-disposition";
        await this.PostState(hub, endpoint, "LeadStatusLogs");
    }

    submitCallLog = async (phone, ref) => {
        let hub = {
            Org: this.state.currentOrg,
            Agent: this.state.currentAgent,
            Ref: ref,
            Phone: phone,
            CallRef: this.props.pbx.Extension.username
        }
        let endpoint = "/lead/entity/insert/batch-agent-call";
        await this.PostState(hub, endpoint, "fakeCallLogSubmit");
    }
    PostState = async (hub, endpoint, stateKey) => {
        let _this = this;
        this.setState({
            isLoading: true
        })
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log(endpoint + " response ---> ", data, dataIn);
            _this.setState({
                [stateKey]: data,
                isLoading: false
            })
        });
    }
    requestLead = async () => {
        let _this = this;
        this.setState({
            isLoading: true
        })
        let user = GetUserToken();
        let hub = {
            Org: this.state.currentOrg,// user.OrgCode,
            Username: this.state.currentAgent,// user.Username,
            InRef: this.state.inRef,
            InPool: this.state.inPool,
        }
        let endpoint = "/lead/request/new";
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log("LLLLLLL---> ", data, dataIn);
            _this.setState({
                LeadRequest: data,
                isLoading: false,
                InRef: "",
                InPool: "",
            });
            if (data.Ref !== "") {
                await _this.requestReportCallLogs(data.Ref)
                await _this.requestReportStatusLogs(data.Ref)
            }
        });
    }
    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleVisibleChange = visible => {
        this.setState({visible});
    };
    handleChangeCommentBody = (e) => {
        this.setState({
            newCommentBody: e.target.value
        })
    }

    onSubmitAddComment = async () => {
        this.setState({
            btnComment: false
        });
        /**
         * post your comment
         */
        const req = this.state.LeadRequest;

        let _this = this;
        this.setState({
            isLoading: true
        })
        let user = GetUserToken();
        let hub = {
            Org: this.state.currentOrg,// user.OrgCode,
            Username: this.state.currentAgent,// user.Username,
            Ref: req.Ref,
            Pool: req.PoolCode,
            Agent: this.state.currentAgent,
            Category: this.state.newCommentCategory,
            Body: this.state.newCommentBody,
        }
        let endpoint = "/lead/entity/insert/batch-agent-comment";
        console.log("onSubmitAddComment request ", hub);
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log("onSubmitAddComment response ", data);
            _this.setState({
                isLoading: false
            });
            await fetchDataEntity("LeadComments", "lead/entity", "batch-agent-comment", _this, false);
            alert("Your new comment have been submitted")
        });
    }
    submitDisposition = async () => {
        const req = this.state.LeadRequest;

        let _this = this;
        this.setState({
            isLoading: true
        })
        let user = GetUserToken();
        let hub = {
            Org: this.state.currentOrg,// user.OrgCode,
            Ref: req.Ref,
            Pool: req.PoolCode,
            Agent: this.state.currentAgent,
            CategoryComment: "disposition",
            Comment: this.state.newEntry.comment,
            Disposition: this.state.newEntry.disposition,
        }

        let endpoint = "/lead/entity/insert/batch-agent-disposition";
        console.log("submitDisposition request ", hub);
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log("submitDisposition response ", data);
            _this.setState({
                isLoading: false,
                LeadRequest: null
            });
            await _this.requestLead()
            alert("Your new disposition have been submitted")
        });
    }
    renderNewCommentButton = () => {
        return (
            <Popover
                content={
                    <>
                        <CommentLead
                            onChange={this.handleChangeCommentBody}
                            value={this.state.newCommentBody}
                            onSubmit={this.onSubmitAddComment}
                            Categories={this.state.CategoryComments}
                            _this={this}
                        />
                    </>
                }
                title="New Comment"
                trigger="click"
                visible={this.state.btnComment}
                onVisibleChange={this.handleVisibleChange}
            >
                <Button type="default" onClick={() => this.setState({btnComment: true})}>
                    <PlusOutlined/>
                    Comment
                </Button>
            </Popover>
        )
    }
    renderDisposition = () => {

        return (
            <DispositionLead
                _this={this}
                visite={this.state.btnAccount}
            />
        )
    }
    renderSearch = () => {
        return (
            <SearchLead
                _this={this}
                visite={this.state.btnAccount}
            />
        )
    }
    renderRecording = () => {
        return (
            <RecordLead
                _this={this}
                visite={this.state.btnAccount}
            />
        )
    }

    renderReport = () => {
        return (
            <ReportDailyDisposition
                _this={this}
                visite={this.state.btnReportDaily}
            />
        )
    }
    renderLead = () => {

        return (
            <NewLead
                _this={this}
                visite={this.state.btnAccount}
            />
        )
    }
    renderDashBoard = () => {
        const style = {background: 'white', padding: '8px'};
        let totLead = 0, totCallback = 0, totTalkTime = "00:00:00", todCalls = 0, totQualify = 0,
            targetLead = 0, targetOutbound = 0, targetQualify = 0, colorLead = "white", colorOutbound = "white",
            colorQualify = "white";
        const allDash = this.state.DashboardAgents;
        if (allDash !== null) {//let make sure that the have the report ready
            const myDash = allDash[this.state.currentAgent];
            console.log("allDash > ",myDash)
            if (typeof myDash !== "undefined") {// let make sure that our user is part of this report
                todCalls = myDash.Calls;
                totTalkTime = myDash.TalkTimeDial;
                totTalkTime = totTalkTime.split(".")[0]
                totLead = myDash.LeadRequested;
                totQualify = myDash.Qualify;
                colorLead = myDash.ColorTargetLead;
                colorOutbound = myDash.ColorTargetOutbound;
                colorQualify = myDash.ColorTargetQualify;
                targetLead = myDash.TargetLead;
                targetOutbound = myDash.TargetOutbound;
                targetQualify = myDash.TargetQualify;
            }
        }
        let ls = [
            {key: "Lead", val: totLead + "/" + targetLead, color: colorLead},
            {key: "Outbound", val: todCalls + "/" + targetOutbound, color: colorOutbound},
            {key: "Qualify", val: totQualify + "/" + targetQualify, color: colorQualify},
        ];

        return (
            <Row style={{marginTop: 15}}>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"New lead"}
                        contentText={totLead}
                        footerText={"lead process today"}
                    />
                </Col>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Callback"}
                        contentText={totCallback}
                        footerText={"schedule call due"}
                    />
                </Col>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Talking Time"}
                        contentText={totTalkTime === "0s" ? "00:00:00" : totTalkTime}
                        footerText={"duration on the call"}
                    />
                </Col>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Calls"}
                        contentText={todCalls}
                        footerText={"number of call make"}
                    />
                </Col>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Qualify"}
                        contentText={totQualify}
                        footerText={"interest to take product"}
                    />
                </Col>

                <Col span={4} style={style}>
                    <DashBoxLine
                        data={ls}
                    />
                </Col>


            </Row>
        )
    }
    dialRequest = async (phone, ref) => {
        console.log("dialRequest--> ", phone);
        const regStatus = this.props.pbx.Registry.status;
        if (regStatus === "Not registered") {
            return alert("Sorry you can't make without register first, click on the online switch!")
        }

        if (this.state.isDialing) {
            return alert("Please wait while another call in progress!");
        }
        if (phone === "") {
            return alert("Please provide number to call!");
        }
        if (!this.isValidNumber(phone)) {
            return alert("Error your number is not valid! 10 digit required")
        }
        let destination = "27" + phone.substring(phone.length - 9);
        //destination = destination.substring(destination.length - 9);

        doCall({
            to: destination,
            host: this.props.pbx.Server.host,
            AlertBox: AlertBox,
            isVideoCall: false
        });
        //this.props.PbxOutCallShowForm({isSet:true})
        this.setState({
            dialingNumber: phone
        });

        await this.submitCallLog(phone, ref)

    }
    stop = () => {
        doHangup();
        this.props.PbxOutCallEnd({is: true})
    }
    isValidNumber = (num) => {
        let str = num;
        let pattern = new RegExp("^[0-9]{10}$");
        let result = pattern.test(str);
        console.log("isValidNumber -> ", str, " > ", result, " > ", /^\d{10}$/.test(str));
        return true
    }
    onChangePbxStatus = (state) => {
        const regStatus = this.props.pbx.Registry.status;
        console.log("onChangePbxStatus--> ", state, " > ", regStatus)
        if (state) {
            /**
             * let find extension to register
             * */
            let ext = null;
            for (let i in this.state.Extensions) {
                const row = this.state.Extensions[i];
                console.log("(((--->? ", row.AgentRef, " == ", this.state.currentAgent, " > ", (row.AgentRef === this.state.currentAgent));
                if (row.AgentRef === this.state.currentAgent) {
                    ext = row;
                }
            }
            /**
             * Let save to props for register
             * */

            if (ext === null) {
                return alert("Sorry we  can't register, agent not allocate")
            }
            const server = "voip2.easipath.com";
            const username = ext.Number;
            const secret = ext.Secret;
            console.log("2 (((--->? ", username, " > ", ext, " > ", this.state.Extensions);
            this.props.PbxChangeExtension({
                username: username,
                secret: secret + username,
                server: server,
            });
        }
    }
    buildData = () => {
        const getDataCategory = (arr, type) => {
            let ls = [];
            for (let key in arr) {
                const val = arr[key];
                ls.push({
                    key: key,
                    val: val,
                    type: type,
                })
            }
            return ls
        }
        const req = this.state.LeadRequest;
        let leadPool = "---";
        let leadName = "---";//this.state.LeadRequest.LeadData.FullName;//"Merveilleux Biangacila";
        let leadState = "---";
        let phones = [], names = [], identities = [], allFields = [], emails = [], others = [];

        if (req === null) {
            return {leadPool, leadName, leadState, phones, emails, others, names, allFields, identities}
        }

        phones = getDataCategory(req.LeadData.Phone, "Phone");
        emails = getDataCategory(req.LeadData.Email, "email");
        names = getDataCategory(req.LeadData.Name, "name");
        identities = getDataCategory(req.LeadData.Identity, "identity");
        others = getDataCategory(req.LeadData.Other, "other");

        const combine1 = names.concat(phones);
        const combine2 = emails.concat(identities);
        allFields = combine1.concat(combine2);
        allFields = allFields.concat(others);

        leadName = req.LeadData.FullName.toLocaleUpperCase();
        leadPool = req.PoolName.toLocaleUpperCase();
        leadState = req.State.toLocaleUpperCase();

        return {leadPool, leadName, leadState, phones, emails, others, names, allFields, identities}

    }
    renderCardPhone = () => {
        let props = this.props;
        let pbxCalling = props.pbx.Calling;
        let dialStatus = false;
        if (pbxCalling.status !== "Not registered") {
            dialStatus = true
        }

        let _this = this;
        const renderHangup = () => {
            if (pbxCalling.status === "Not registered" || pbxCalling.status === "hangup") {
                return null
            }
            return (
                <a
                    style={{cursor: "pointer", color: "red", marginLeft: 20}}
                    onClick={() => _this.stop()}
                >
                    <PhoneFilled/>
                </a>
            )
        }

        const {phones} = this.buildData();
        return (
            <List
                dataSource={phones}
                renderItem={item => (
                    <List.Item key={item.key}>
                        <List.Item.Meta
                            /*avatar={
                                <Avatar src="https://www.blazethread.com/wp-content/uploads/2019/07/220-2207243_phone-icon-png-mobile-phone-icon-circle-png.png" />
                            }*/
                            title={<a href="https://ant.design">{item.val}</a>}
                            description={UpCaseFirst(item.key)}
                        />
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a onClick={() => this.dialRequest(item.val, this.state.LeadRequest.Ref)}
                                   style={{cursor: "pointer", color: "#0092ff"}}>
                                    Dial
                                    <Avatar
                                        style={{color: "#0092ff", marginLeft: 5}}
                                        src="https://www.blazethread.com/wp-content/uploads/2019/07/220-2207243_phone-icon-png-mobile-phone-icon-circle-png.png"/>
                                </a>
                                |&nbsp;{dialStatus ?
                                <span style={{color: "#9c27b0"}}>:{pbxCalling.status} {renderHangup()}</span> : null}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </List.Item>
                )}
            >
                {this.state.loading && this.state.hasMore && (
                    <div className="demo-loading-container">
                        <Spin/>
                    </div>
                )}
            </List>
        )
    }
    renderCardAllField = () => {
        const {allFields} = this.buildData();
        return (
            <List
                dataSource={allFields}
                renderItem={item => (
                    <List.Item key={item.key}>
                        <List.Item.Meta
                            title={<a href="https://ant.design">{item.val}</a>}
                            description={UpCaseFirst(item.key)}
                        />
                        <div>
                            {item.type.toLocaleUpperCase()}
                        </div>
                    </List.Item>
                )}
            >
                {this.state.loading && this.state.hasMore && (
                    <div className="demo-loading-container">
                        <Spin/>
                    </div>
                )}
            </List>
        )
    }
    renderCardCallLog = () => {
        //LeadCallLogs
        const logs = this.state.LeadCallLogs;
        return (
            <List
                itemLayout="horizontal"
                dataSource={logs}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar
                                src="https://cdn4.iconfinder.com/data/icons/basic-flat-ui-extra-set-200-item/76/ui_ux_minimalist_button_phone_log_call-512.png"/>}
                            title={<a href="https://ant.design">{item.Phone}</a>}
                            description={item.OrgDateTime}
                        />
                    </List.Item>
                )}
            />
        )
    }
    renderCardDispositionLog = () => {
        //LeadCallLogs
        const logs = this.state.LeadStatusLogs;
        return (
            <List
                itemLayout="horizontal"
                dataSource={logs}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar
                                src="https://library.kissclipart.com/20180917/rvq/kissclipart-programming-icon-clipart-computer-programming-comp-3ffcf995e3778605.png"/>}
                            title={<a href="https://ant.design">{item.Disposition}</a>}
                            description={item.OrgDateTime}
                        />
                    </List.Item>
                )}
            />
        )
    }
    getDialHeader = () => {
        let props = this.props;
        let pbxExtension = props.pbx.Extension.username || "00000";
        let pbxOutCaller = props.pbx.OutCall.phone;
        let pbxRegistry = props.pbx.Registry;
        let pbxCalling = props.pbx.Calling;
        let registerStatus = pbxRegistry.status;
        let registryColor = "#f44336";

        if (registerStatus === "registered") {
            registryColor = "green"
        }

        let dialStatus = false;
        if (pbxCalling.status !== "Not registered") {
            dialStatus = true
        }
        let _this = this;
        const renderHangup = () => {
            if (pbxCalling.status === "Not registered" || pbxCalling.status === "hangup") {
                return null
            }
            return (
                <a
                    style={{cursor: "pointer", color: "red", marginLeft: 20}}
                    onClick={() => _this.stop()}
                >
                    <PhoneFilled/>
                </a>
            )
        }

        return (
            <Fragment>
                Dial Status {dialStatus ?
                <span style={{color: "#9c27b0"}}>:{pbxCalling.status} {renderHangup()}</span> : null}
            </Fragment>
        )
    }
    fakeRnder = () => {
        return (
            <div style={styles.containerBox}>
                <Row gutter={16}>
                    <Col span={4}>
                        <Card title={this.getDialHeader()} bordered={false} style={styles.card}>
                            {this.renderCardPhone()}
                            <Search
                                placeholder="input phone number"
                                enterButton="Dial"
                                size="large"
                                onSearch={value => this.dialRequest(value, this.state.LeadRequest.Ref)}
                            />
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card title="Detail" bordered={false} style={styles.card}>
                            {this.renderCardAllField()}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Comments" bordered={false} style={styles.card}>
                            {/*Card content*/}
                            <CommentLeadInner _this={this}/>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card title="Call logs" bordered={false} style={styles.card}>
                            {this.renderCardCallLog()}
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card title="Status logs" bordered={false} style={styles.card}>
                            {this.renderCardDispositionLog()}
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {

        let {leadPool, leadName, leadState} = this.buildData()

        /*let leadPool="PP1 X";
        let leadName="Merveilleux Biangacila" ;//this.state.LeadRequest.LeadData.FullName;//"Merveilleux Biangacila";
        let leadState="Fresh";*/
        const regStatus = this.props.pbx.Registry.status;
        const pbxCalling = this.props.pbx.Calling;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto",
                    marginBottom: 20
                }}>
                    <div style={{
                        minWidth: "100%",
                    }}>
                        <PageHeader
                            ghost={false}
                            onBack={() => window.history.back()}
                            title={"Agent Work Station".toLocaleUpperCase()}
                            subTitle="all is here"
                            extra={[
                                <div style={{marginRight: 10, minWidth: "100%", paddingLeft: 20}}>
                                    &nbsp;Extension: {this.props.pbx.Extension.username}
                                    <Switch
                                        checkedChildren="Online"
                                        unCheckedChildren="Offline"
                                        onChange={this.onChangePbxStatus}
                                    />
                                    <span style={{float: "right"}}>
                                        Reg.Status:
                                        <span style={{
                                            backgroundColor: regStatus === "Not registered" ? 'orange' : 'green',
                                            color: "white",
                                            borderRadius: 8,
                                            padding: 5,
                                            marginLeft: 5
                                        }}>{regStatus}</span>

                                    </span>
                                </div>,
                            ]}
                            style={{border: "1px solid rgb(235, 237, 240)"}}
                        >
                            <Descriptions size="small" column={3}>
                                <Descriptions.Item label="Full name">{userN.Fullname}</Descriptions.Item>
                                <Descriptions.Item label="Role">
                                    <a>{userN.Role}</a>
                                </Descriptions.Item>
                                <Descriptions.Item label="Creation Time">{userN.OrgDateTime}</Descriptions.Item>
                                <Descriptions.Item label="Username">{userN.Username}</Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    {userN.Status}
                                </Descriptions.Item>
                            </Descriptions>
                        </PageHeader>
                        {/*<PageHeader
                            className="site-page-header"
                            onBack={() => null}
                            title={"Agent Working Board".toLocaleUpperCase()}
                            subTitle={
                                <div style={{marginRight: 10, minWidth: "100%", paddingLeft: 20}}>
                                    &nbsp;Extension: {this.props.pbx.Extension.username}
                                    <Switch
                                        checkedChildren="Online"
                                        unCheckedChildren="Offline"
                                        onChange={this.onChangePbxStatus}
                                    />
                                    <span style={{float: "right"}}>
                                        Reg.Status:
                                        <span style={{
                                            backgroundColor: regStatus === "Not registered" ? 'orange' : 'green',
                                            color: "white",
                                            borderRadius: 8,
                                            padding: 5,
                                            marginLeft: 5
                                        }}>{regStatus}</span>

                                    </span>
                                </div>
                            }
                            style={{border: "1px solid rgb(235, 237, 240)"}}
                        />*/}
                    </div>
                    {this.renderDashBoard()}
                    <Divider orientation="left">Lead details</Divider>

                    <GridContainer>
                        <GridItem>
                            {this.renderNewCommentButton()}
                        </GridItem>
                        <GridItem>
                            {this.renderLead()}
                        </GridItem>
                        <GridItem>
                            {this.renderDisposition()}
                        </GridItem>
                        <GridItem>
                            {this.renderSearch()}
                        </GridItem>
                        <GridItem>
                            {this.renderRecording()}
                        </GridItem>
                        <GridItem>
                            {this.renderReport()}
                        </GridItem>
                        <GridItem style={{alignItems: "center", flex: 1, display: "flex"}}>
                            State: <span style={{fontWeight: "bold", marginLeft: 10}}>{leadState}</span>
                        </GridItem>
                        <GridItem style={{alignItems: "center", flex: 1, display: "flex"}}>
                            Pool: <span style={{fontWeight: "bold", marginLeft: 10}}>{leadPool}</span>
                        </GridItem>
                        <GridItem style={{alignItems: "center", flex: 1, display: "flex"}}>
                            Name: <span style={{fontWeight: "bold", marginLeft: 10}}>{leadName}</span>
                        </GridItem>
                    </GridContainer>


                    <div style={styles.containerBox}>
                        <Tabs tabPosition={this.state.tabPosition} style={{
                            backgroundColor: "white",
                            minHeight: 400,
                            padding: "30px",
                            marginTop: 20,
                        }}>
                            <TabPane tab={"Dial Numbers"} key="1">
                                {this.renderCardPhone()}
                                <Search
                                    placeholder="input phone number"
                                    enterButton="Dial"
                                    size="large"
                                    onSearch={value => this.dialRequest(value, this.state.LeadRequest.Ref)}
                                />
                            </TabPane>
                            <TabPane tab={"Details"} key="2">
                                {this.renderCardAllField()}
                            </TabPane>
                            <TabPane tab={"Comments"} key="3">
                                <CommentLeadInner _this={this}/>
                            </TabPane>
                            <TabPane tab={"Call logs"} key="4">
                                {this.renderCardCallLog()}
                            </TabPane>
                            <TabPane tab={"Status logs"} key="5">
                                {this.renderCardDispositionLog()}
                            </TabPane>
                        </Tabs>
                    </div>


                </div>
                <video
                    className="rounded centered"
                    id="video-device"
                    width={320}
                    height={240}
                    autoPlay
                    playsinline
                    muted="muted"
                />
                <audio
                    id={"audio-device"}
                />
                <audio
                    className="rounded centered"
                    id="roomaudio"
                    width="100%"
                    height="100%"
                    autoPlay
                />
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(LeadAgentStattionPage);

const styles = {
    card: {
        minHeight: 400,
    },
    containerBox: {
        background: "#ececec",
        padding: "30px",
        marginTop: 20,
        minHeight: 400,
    },
    box: {
        minHeight: 400,
        margin: 10
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

/*

 */
