import React from "react";
import { Modal, Button } from 'antd';
import { Table, Tag, Space } from 'antd';

export default class PopupApplicantDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ModalText: this.props.title,
            visible: false,
            confirmLoading: false,
        };
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.props._this.setState({
            showPopupDetail:false,
            popupTitle:"",
            data:{}
        })
        this.setState({
            ModalText: 'The modal will be closed after two seconds',
            confirmLoading: true,
        });
        setTimeout(() => {
            this.setState({
                visible: false,
                confirmLoading: false,
            });
        }, 2000);
    };

    handleCancel = () => {
        console.log('Clicked cancel button');
        this.props._this.setState({
            showPopupDetail:false,
            popupTitle:"",
            data:{}
        })
        this.setState({
            visible: false,
        });
    };
    renderContent=()=>{
        const {data}=this.props;
        let ls=[];
        let dataSource=[];
        let index=0;
        for(let i in data){
            const row=data[i];
            const key=row.key;
            const val=row.val;

             dataSource.push({
                    key: index,
                    name: key,
                    val: val,
                });
             index++;
        }

        console.log("data popup > ",dataSource);

        const columns = [
            {
                title: 'Field',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Value',
                dataIndex: 'val',
                key: 'val',
            }];

        return (
            <Table dataSource={dataSource} columns={columns} />
        )
    }
    render() {
        const {  confirmLoading, ModalText } = this.state;
        const {title,data,visible}=this.props;
        return (
            <>
                <Modal
                    title={"Detail applicant: "+title}
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                >
                    {this.renderContent()}
                </Modal>
            </>
        );
    }
}

