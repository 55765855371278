import React from "react";
import MyLogo from "../../../assets/img/logo.png";
import Connector from "../../../redux/connector";
import {Button, WhiteSpace} from "antd-mobile";


class MobileMainMenu extends React.Component{
    changeLink=(link)=>{
        window.location.href=link;
    }
    render() {
        let menus=[
            {name:"Work Station",link:"#/mobile-agent-workstation"},
            {name:"Dashboard",link:"#/mobile-agent-dashboard"},
            {name:"Search Lead",link:"#/mobile-agent-search"},
            {name:"Recording",link:"#/mobile-agent-recording"},
            {name:"New Lead",link:"#/mobile-agent-newlead"},
            {name:"Report",link:"#/mobile-agent-report"},

        ]

        return (
            <div style={{margin:15}}>
                <div style={styles.containerLogo}>
                    <img src={MyLogo} style={styles.logo} alt={"Logo"}/>
                </div>
                <div style={styles.containerLabel}>
                    <label style={styles.titleLabel} color={"primary"}>
                        Main Menu
                    </label>
                </div>
                {menus.map((row)=>{
                    return(
                        <div style={styles.containerInput}>
                            <Button
                                inline
                                type="default"
                                style={styles.button}
                                onClick={()=>this.changeLink(row.link)}
                            >{row.name}</Button>
                        </div>
                    )
                })}

                <WhiteSpace/>

            </div>
        )
    }
}

export default Connector(MobileMainMenu)

const styles = {
    error:{
        color:"red" ,
        fontWeight: "bold"
    },
    button: {
        marginTop:10,
        minWidth: "100%",
        /*backgroundColor: "blue",*/
    },
    bigContainer: {
        minWidth: "100%",
        backgroundColor: "red",
        minHeight: "100%",
        flex: 1,/*justifyContent:"center",*/
        display: "flex",

        alignItems: "center"
    },
    input: {
        minWidth: "90%",
        /*backgroundColor:"red"*/
    },
    titleLabel: {
        color: "lightblue",
        fontWeight: "bold",
        fontSize: 18
    },
    containerInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 5,
        minWidth: "90%",
    },
    containerLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },
    containerLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
    },
    logo: {
        maxHeight: 50,
        minHeight: 50,
    }
}
