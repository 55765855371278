import React from "react";
import Connector from "../../../redux/connector";
import PageBuilder from "../../../components/web/Common/PageBuilder";

class LeadSettingSourcePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        //await fetchDataEntity("Companies", "client/entity", "company", this)
        let StateStorage = [
            {stateField: "Data", moduleEntity: "lead/entity", table: "batch-supplier", noCondition: false},
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
            /*{stateField: "Registries", moduleEntity: "manager/entity", table: "registries", noCondition: false},
            {stateField: "Trunks", moduleEntity: "manager/entity", table: "trunks", noCondition: false},
            {stateField: "Agents", moduleEntity: "manager/entity", table: "agents", noCondition: false},*/
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Code", label: "Code"},
            {id: "Name", label: "Name"},

            /*{id: "Secret", label: "Secret"},
            {id: "OutboundNumber", label: "Outbound"},
            {id: "Range", label: "Range"},
            {id: "Phone", label: "Phone"},
            {id: "Campaign", label: "Campaign"},*/
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
            /*{keyState: "Registries", targetKey: "Org", storeVariable: "registries"},
            {keyState: "Trunks", targetKey: "Org", storeVariable: "trunks"},
            {keyState: "Agents", targetKey: "Org", storeVariable: "agents"},
            {keyState: "Data", targetKey: "Org", storeVariable: "extensions"},*/
        ];

        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },
            /*{
                label: "Registry",
                message: "Please select your line",
                key: "InboundNumber",
                value: "InboundNumber",
                lookInState: false,
                targetStorage: "registries",
                StateKey: "Registry"
            },
            {
                label: "Trunk",
                message: "Please select your trunk",
                key: "Name",
                value: "Name",
                lookInState: false,
                targetStorage: "trunks",
                StateKey: "Trunk"
            },*/
        ];

        let bldFields = [
            {
                label: "Name",
                message: "Please input your source name",
                type: "text",
                StateKey: "Name",
                required:true
            },
            {
                label: "Description",
                message: "Please input your source Description",
                type: "text",
                StateKey: "Description",
                required:false
            },
        ]

        return (
            <PageBuilder
                pageTitle={"Supplier"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Data"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Supplier"}
                postMsgResponse={"Thank you, supplier submitted!"}
                postMsgWarning={"Are you sure you want to submit this supplier"}
                postEndpoint={"/lead/entity/insert/batch-supplier"}
            />
        )
    }

}

export default Connector(LeadSettingSourcePage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
