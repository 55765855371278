import React, {Fragment} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import GridContainer from "../components/Common/dashboard/Grid/GridContainer";
import GridItem from "../components/Common/dashboard/Grid/GridItem";
import ImgCallLive from '../assets/img/in-call.gif';


import CallEndIcon from '@material-ui/icons/CallEnd';

import DialerSipIcon from '@material-ui/icons/DialerSip';
import DetailsIcon from '@material-ui/icons/Details';
import {reducerPbx as pbx} from "../redux/reducer";
//import pbx from "../../reducers/reducer/reducer-pbx";


//   https://material-ui.com/components/material-icons/


const useStyles = makeStyles(theme => ({
    card: {
        minWidth:"100%",
        maxWidth: "100%"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        minWidth: "100%",
        backgroundColor:"#ffebee",
        justifyContent:"center",
        borderRadius:5
    },
}));

export default  (props)=> {

    let {duration,answerIncomingCall,hangupIncomingCall,inCall}=props;


    const theme = useTheme();
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    let colorStatus="orange";
    if(inCall.status==="answer"){
        colorStatus = "green"
    }else if(inCall.status==="hangup"){
        colorStatus = "red"
    }
    const GetInboundCompany=(lineNumber)=>{
        if(typeof props.pbx.CurrentCalls !=="undefined"){
            let data = props.pbx.CurrentCalls;
            for(let i in data){
                const row=data[i];
                if (row.Customer ===lineNumber) {
                    return row;//row.Org+" "+row.Queue;
                }
            }
            return {Org:"---",Queue:"---"}
        }
        return "-- --";

    }

    const clickShowCallForm=()=>{
        window.location.href="#pa-calls-in"
    }

    const info = GetInboundCompany(inCall.caller);
    inCall.InfoInbound = info;

    const pickupCall=()=>{
        props.SetActiveIncomeCall(inCall);
        answerIncomingCall();
        //clickShowCallForm()
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <GridContainer style={{marginTop: 5, minHeight: "100%", paddingTop: 15}}>
                    <GridItem xs={12} sm={8} md={8} alignItems="center" style={{minHeight: "100%"}}>
                        <Typography component="h6" variant="h4">
                            Income Call
                        </Typography>
                        <br/>
                        <Typography variant="h8" color="textSecondary">
                            Status: (<span style={{color:colorStatus}}> {inCall.status}</span>)
                        </Typography>
                        <br/>
                        <Typography variant="h8" color="textSecondary">
                            Caller: {inCall.caller}
                        </Typography>
                        <br/>
                        <Typography variant="h8" color="textSecondary">
                            Cust: {inCall.customer||"---"}
                        </Typography>
                        <br/>
                        <Typography variant="h8" color="textSecondary">
                            Org: {info.Org||"---"}
                        </Typography>
                        <br/>
                        <Typography variant="h8" color="textSecondary">
                            Queue: {info.Queue||"---"}
                        </Typography>
                        <br/>
                        <Typography variant="h8" color="textSecondary">
                            Since: <span style={{color:"red"}}>{parseFloat(duration).toFixed(2)}&nbsp;m</span>
                        </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} alignItems="center" style={{minHeight: "100%"}}>
                        <img src={ImgCallLive} style={{maxWidth:"100%",maxHeight:"100%"}} />
                    </GridItem>
                </GridContainer>

            </CardContent>
            <CardActions>
                <div className={classes.controls}>
                    <IconButton aria-label="previous">
                        <CallEndIcon
                            style={{color:"red",fontWeight:"bold",fontSize:34}}
                            onClick={()=>hangupIncomingCall()}
                        />
                    </IconButton>
                    <IconButton aria-label="play/pause">
                        <DetailsIcon
                            style={{color:"blue",fontWeight:"bold",fontSize:34}}
                            onClick={()=>clickShowCallForm()}
                        />
                    </IconButton>
                    <IconButton aria-label="next">
                        <DialerSipIcon
                            style={{color:"green",fontWeight:"bold",fontSize:34}}
                            onClick={()=>{pickupCall()}}
                        />
                    </IconButton>
                </div>
            </CardActions>
        </Card>
    );
}
