import React, {Fragment} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import moment from "moment";
import {IsNotSuperUser} from "../../../api/fn";
import {fetchDataEntity} from "../../../api/entity-data";
import {PostToPbx} from "../../../api/http-post";
import {Button, Col, DatePicker, Form, Row, Select, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import SelectBar from "../../../components/web/Lead/SelectBar";
import {GetLoginToken, GetUserToken} from "../../../api/token";

const {Option} = Select;
const currentDate = moment().format("YYYY-MM-DD");

class LeadAdminStationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:currentDate,
            endDate:currentDate,
            DashboardAgents:null,
            currentOrg: "C100003",
            currentAgent: "marvelous@easipath.com",
            currentAgentName: "Merveilleux biangacila",
            currentRole:"super",
            Companies:[],
        }
        console.log("constructor > ",GetUserToken())
    }
    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        let user = GetUserToken();
        this.setState({
            isLoading: true,
            currentOrg:user.OrgCode,
            currentAgent:user.Username,
            currentAgentName:user.FullName
        })
        //await this.requestDashboard();
        await fetchDataEntity("Companies", "client/entity", "company", this);
    }
    handleChange = (value,key) => {
        this.setState({
            [key]: value,
        })
    }
    requestDashboard=async ()=>{
        let hub = {
            Org: this.state.currentOrg,
            StartDate: this.state.startDate,
            EndDate:this.state.endDate,
            isLoading: true,
            DashboardAgents:null,
        }
        let endpoint = "/lead/report/dashboard/agent";
        await this.PostState(hub, endpoint,"DashboardAgents");
    }
    PostState=async (hub,endpoint,stateKey)=>{
        let _this = this;
        this.setState({
            isLoading: true
        })
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log(endpoint+" response ---> ",endpoint," > ", hub, data);
            _this.setState({
                [stateKey]: data,
                isLoading: false
            })
        });
    }

     onChangeClick=(val1,val2)=>{
        console.log("onChangeClick--> ",val1,val2)
        if(val2.length===2){
            const start=val2[0];
            const end=val2[1];
            this.setState({
                startDate:start,
                endDate:end
            });
        }
    }

    renderTableData=()=>{
        if(this.state.DashboardAgents===null){
            return null
        }
        const columns = [
            {
                title: 'Agent',
                dataIndex: 'Agent',
                key: 'Agent',
            },
            {
                title: 'New Lead',
                dataIndex: 'NewLead',
                key: 'NewLead',
            },
            {
                title: 'Callback',
                dataIndex: 'Callback',
                key: 'Callback',
            },
            {
                title: 'Talk Time',
                dataIndex: 'TalkTime',
                key: 'TalkTime',
            },
            {
                title: 'Calls',
                dataIndex: 'Calls',
                key: 'Calls',
            },{
                title: 'Qualify',
                dataIndex: 'Qualify',
                key: 'Qualify',
            },{
                title: 'Stats Lead',
                dataIndex: 'StatsLead',
                key: 'StatsLead',
            },{
                title: 'Stats Outbound',
                dataIndex: 'StatsOutbound',
                key: 'StatsOutbound',
            },{
                title: 'Stats Qualify',
                dataIndex: 'StatsQualify',
                key: 'StatsQualify',
            },
        ];
        let dataSource=[];

        const allDash=this.state.DashboardAgents;
        for(let i in allDash){
            const myDash = allDash[i];
            dataSource.push({
                Agent:myDash.Agent,
                NewLead:myDash.LeadRequested,
                Callback:myDash.CallBack,
                TalkTime:myDash.TalkTimeDial,
                Calls:myDash.Calls,
                Qualify:myDash.Qualify,
                StatsLead: <span  style={{color:"white",backgroundColor:myDash.ColorTargetLead}}>{myDash.LeadRequested+"/"+myDash.TargetLead}</span> ,
                StatsOutbound: <span  style={{color:"white",backgroundColor:myDash.ColorTargetOutbound}}>{myDash.Calls+"/"+myDash.TargetOutbound}</span>,
                StatsQualify: <span  style={{color:"white",backgroundColor:myDash.ColorTargetQualify}}>{myDash.Qualify+"/"+myDash.TargetQualify}</span>,
            })
        }

        return(
            <Table
                dataSource={dataSource}
                columns={columns}
            />
        )
    }

    render() {

        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto",
                    marginBottom: 20
                }}>
                    <Form layout="vertical" hideRequiredMark>

                        <SelectBar _this={this} {...this.props} />

                        <Row gutter={16}>
                            <Col span={24}>
                                {this.renderTableData()}
                            </Col>
                        </Row>

                    </Form>
                </div>
            </Page>
        )
    }
}


export default Connector(LeadAdminStationPage);

const styles = {
    card: {
        minHeight: 400,
    },
    containerBox: {
        background: "#ececec",
        padding: "30px",
        marginTop: 20,
        minHeight: 400,
    },
    box: {
        minHeight: 400,
        margin: 10
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

