import React from "react";
import Connector from "../../../redux/connector";
import PageBuilder from "../../../components/web/Common/PageBuilder";

class LeadSettingCampaignPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let StateStorage = [
            {stateField: "Data", moduleEntity: "lead/entity", table: "batch-setting-disposition", noCondition: false},
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Name", label: "Name"},
            {id: "IsQualify", label: "Is Qualify?"},
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
        ];
        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            }
        ];
        let bldFields = [
            {
                label: "Name",
                message: "Please input your batch name",
                type: "text",
                StateKey: "Name",
                required:true
            },{
                label: "IsQualify",
                message: "Please select your qualification",
                type: "option",
                StateKey: "IsQualify",
                required:true,
                options:[
                    {key:"yes",val:"Yes"},
                    {key:"no",val:"no"},
                ]
            },
        ]
        return (
            <PageBuilder
                pageTitle={"Disposition"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Data"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Call Disposition"}
                postMsgResponse={"Thank you, Disposition submitted!"}
                postMsgWarning={"Are you sure you want to submit this disposition"}
                postEndpoint={"/lead/entity/insert/batch-setting-disposition"}
            />
        )
    }

}

export default Connector(LeadSettingCampaignPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
