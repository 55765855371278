import React,{Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
export default function Chips({props,pageTitle}) {
    const styles={
        currentTitle:{
            color:"#42a5f5",
            fontWeight:"bold",
            fontSize:12
        },
        myFont:{
            fontSize:12
        }
    }

    const renderPAinfo=()=>{
        /*
        Extension: {
        username: "00000",
        secret: "00000",
        agentRef:"none@home.com",
        agentName:"nome"
    },

     Server:{
        host:"",
        port:5060
    },
    Registry:{
      status:"Not registered" ,
      time:moment().format("YYYY-MM-DD HH:mm:ss"),
    },
         */
        const ext =props.pbx.Extension;
        const host = props.pbx.Server.host;
        const status =props.pbx.Registry.status;
        let registryColor ="#f44336";
        if(status==="registered"){
            registryColor="green"
        }
        if(ext.username ==="00000"){
            return null;
        }
        return(
            <Link color="inherit"  onClick={handleClick} style={{...styles.myFont,color: registryColor}}>
                Ext: <span>{ext.username} @ {host} &nbsp;|&nbsp; {status}</span>
            </Link>
        )
    }

    return (
        <Fragment>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit"  onClick={handleClick} style={styles.myFont}>
                    {props.navigations.currentModule.toLocaleUpperCase()}
                </Link>
                <Link color="inherit"  onClick={handleClick} style={styles.myFont}>
                    {props.navigations.currentMenu.toLocaleUpperCase()}
                </Link>
                <Typography color="textPrimary"  style={styles.currentTitle}>{pageTitle}</Typography>
                {renderPAinfo()}
            </Breadcrumbs>
            <Divider style={{marginBottom:10}}/>
        </Fragment>
    );
}
