import React, {Fragment} from "react";
import {Button, WhiteSpace, Grid, Tabs, Badge, List, Switch, Modal, InputItem, TextareaItem} from 'antd-mobile';

import Connector from "../../../redux/connector";
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'
import {GetUserToken, SaveToken} from "../../../api/token";
import {BuildData, DialRequest, OnChangePbxStatus, RequestLead, StopCalling} from "../../../api/agent-station";
import {fetchDataEntity} from "../../../api/entity-data";
import {PhoneFilled, SearchOutlined} from "@ant-design/icons";
import {DatePicker, PageHeader} from "antd";
import {PostToPbx} from "../../../api/http-post";
import {Calendar} from 'antd-mobile';
import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import moment from "moment";
import SelectBar from "../../../components/web/Lead/SelectBar";
import SelectorCalendar from "../../../components/mobile/SelectorCalendar";


const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}
const Alert = Modal.alert;
const Item = List.Item;
let user1 = GetUserToken();

const currentDate = moment().format("YYYY-MM-DD");

class MobileLeadAgentDashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOrg: user1.Org,// "C100003",
            currentAgent: user1.Username,// "marvelous@easipath.com",
            currentAgentName: user1.FullName,// "Merveilleux biangacila",
            currentRole: "super",
            startDate: currentDate,
            endDate: currentDate,
            showStartDate: false,
            showEndDate: false,
            DashboardAgents: null
        }
    }

    componentDidMount = async () => {
        await this.requestDashboard();
        /*await fetchDataEntity("Dispositions", "lead/entity", "batch-setting-disposition", this, false);
        await fetchDataEntity("Extensions", "/manager/entity", "extensions", this, false);
        await fetchDataEntity("LeadComments", "lead/entity", "batch-agent-comment", this, false);
        await fetchDataEntity("CategoryComments", "lead/entity", "batch-category-comment", this, false);
*/
    }
    requestDashboard = async () => {
        let hub = {
            Org: this.state.currentOrg,
            StartDate: currentDate,
            EndDate: currentDate
        }
        let endpoint = "/lead/report/dashboard/agent";
        await this.PostState(hub, endpoint, "DashboardAgents");
    }
    PostState = async (hub, endpoint, stateKey) => {
        let _this = this;
        this.setState({
            isLoading: true
        })
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log(endpoint + " response ---> ", data, dataIn);
            _this.setState({
                [stateKey]: data,
                isLoading: false
            })
        });
    }



    renderCalendar = () => {

        let _this= this;
        const onChangeStartDate=(value)=>{
            _this.setState({
                startDate:value
            })
        }
        const onChangeEndDate=(value)=>{
            _this.setState({
                endDate:value
            })
        }


        return (
            <div>
                <List className="calendar-list" style={{backgroundColor: 'white'}}>
                    <List.Item
                        className="item"
                        extra={
                            <DatePicker onChange={onChangeStartDate} style={{minWidth:"100%"}}/>
                        }>
                        Start Date
                    </List.Item>
                    <List.Item
                        className="item"
                        extra={
                            <DatePicker onChange={onChangeEndDate} style={{minWidth:"100%"}}/>
                        }>
                        End Date
                    </List.Item>
                    <List.Item
                        className="item"
                        >
                        <Button type="default" onClick={this.requestDashboard} style={{marginTop:5}}>
                            <SearchOutlined /> Search
                        </Button>
                    </List.Item>
                </List>

            </div>
        )
    }

    render() {
        let results = null;
        let req=null;
        if(this.state.DashboardAgents!==null){
            req=this.state.DashboardAgents[this.state.currentAgent];
        }
        console.log("results---> ",req)
        const data = Array.from(new Array(4)).map((_val, i) => ({
            icon: 'https://gw.alipayobjects.com/zos/rmsportal/nywPmnTAvTmLusPxHPSu.png',
            text: `name${i}`,
        }));
        const innStyle = {
            color: "orange",
            fontWeight: "bold"
        }
        const innStyle2 = {
            color: "gray",
            fontWeight: "bold"
        }

        let MyDash = [
            {
                icon: <label style={innStyle}>{req!==null?req.LeadRequested:0}</label>,
                text: <label style={innStyle2}>REQUEST</label>
            },
            {
                icon: <label style={innStyle}>{req!==null?req.Calls:0}</label>,
                text: <label style={innStyle2}>CALLS</label>
            },
            {
                icon: <label style={innStyle}>{req!==null?req.TalkTimeDial:"00:00:00"}</label>,
                text: <label style={innStyle2}>TALK TIME</label>
            },
            {
                icon: <label style={innStyle}>{req!==null?req.CallBack:0}</label>,
                text: <label style={innStyle2}>CALLBACK</label>
            },
            {
                icon: <label style={innStyle}>{req!==null?req.Qualify:0}</label>,
                text: <label style={innStyle2}>QUALIFY</label>
            },
            {
                icon: <label style={innStyle}>{req!==null?req.LeadProcess:0}</label>,
                text: <label style={innStyle2}>PROCESS</label>
            },
        ]
        return (
            <div style={{margin: 15}}>
                <div style={styles.containerLabel}>
                    <PageHeader
                        onBack={() => window.location.href = "#main-menu"}
                        title={<label style={styles.titleLabel} color={"primary"}>
                            Daily Dashboard
                        </label>}
                    />
                </div>

                <div>
                    {this.renderCalendar()}
                </div>

                <div style={styles.Pie}>
                    <div className="sub-title">Grid item adjust accroiding to img size</div>
                    <Grid data={MyDash} columnNum={3}/>
                    {/*<div className="sub-title">No border</div>
                    <Grid data={data} hasLine={false} />*/}
                </div>
            </div>
        )
    }

}

export default Connector(MobileLeadAgentDashboardPage)

const styles = {
    Pie: {
        flex: 1,
        flexDirection: "row",
        maxWidth: "100%",
        paddingTop: 20,
        justifyContent: "center",
        marginRight: 0,
        marginLeft: 0,
        maxHeight: "50%",
        minHeight: "50%"
    },
    listDisposition: {
        width: "100%",
        minWidth: "100%"
    },
    infoTitle: {
        color: "black",
        fontWeight: "bold",
        fontSize: 18
    },
    error: {
        color: "red",
        fontWeight: "bold"
    },
    button: {
        marginTop: 10,
        minWidth: "100%",
        /*backgroundColor: "blue",*/
    },
    bigContainer: {
        minWidth: "100%",
        backgroundColor: "red",
        minHeight: "100%",
        flex: 1,/*justifyContent:"center",*/
        display: "flex",

        alignItems: "center"
    },
    input: {
        minWidth: "90%",
        /*backgroundColor:"red"*/
    },
    titleLabel: {
        color: "lightblue",
        fontWeight: "bold",
        fontSize: 18
    },
    containerInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 5,
        minWidth: "90%",
    },
    containerLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },
    containerLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
    },
    logo: {
        maxHeight: 50,
        minHeight: 50,
    }
}



