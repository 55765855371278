import React from "react";
import { Badge, Row, Col,Card,List, Typography, Divider } from 'antd';
import { OrderedListOutlined } from '@ant-design/icons';

export default (props)=>{
    let {color,titleText,contentText,footerText}=props;
    if(typeof titleText ==="undefined"){
        titleText=""
    }
    console.log("----> ",titleText)
    return(
        <div style={{
            minWidth:"100%",
            margin:10,
            padding:10,
            backgroundColor:color,
            borderWidth:1,
            borderRadius:10,
            borderRightWidth:60
        }}>
            <Row>
                <Col span={18} style={styles.smallLabel}>{titleText.toLocaleUpperCase()}</Col>
                <Col span={6} style={{...styles,float:"right",textAlign:"right",paddingRight:20}}>
                    <OrderedListOutlined style={{color:"white",fontSize:18}}/>
                </Col>
            </Row>
            <Row >
                <Col span={20} style={styles.bidLabel}>{contentText}</Col>
            </Row>
            <Row >
                <Col span={24} style={{...styles.smallLabel,textAlign:"center"}}>{footerText}</Col>
            </Row>
        </div>
    )
}

export const DashBoxLine= (props)=>{
    let {ls,data}=props;
    return(
        <div style={{
            minWidth:"100%",
            margin:10,
            padding:5,
            backgroundColor:"gray",
            borderColor:"2px solid red",
            borderWidth:2,
            borderRadius:10,
            borderRightWidth:60,
            borderBlockColor:"green",
        }}>
            <div style={{minWidth:"100%",maxWidth:"100%",backgroundColor:"white"}}>
                <List
                    size="small"
                    /*header={<div>Header</div>}
                    footer={<div>Footer</div>}*/
                    bordered
                    dataSource={data}
                    renderItem={item => (
                        <List.Item >
                            <Typography.Text mark style={{backgroundColor:item.color}}>
                                <span style={{backgroundColor:item.color,color:"white",fontWeight:"bold"}}>[{item.val}]</span>
                            </Typography.Text> {item.key}
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}

const styles={
    bidLabel:{
        color:"white",
        fontSize:24,
        fontWeight:"bold",
        textAlign:"center"
    },
    smallLabel:{
        color:"white",
        fontSize:14
    },
    box:{
        margin:10
    }
}
