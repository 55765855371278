import React from 'react';
import {Router, Route, Switch} from 'react-router';
import {Provider} from 'react-redux';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import store from "./redux/store";
import NotFoundPage from "./pages/web/Common/404";
import WelcomePage from "./pages/web/Welcome";
import LoginPage from "./pages/web/Common/LoginPage";
import PbxSettingRangePage from "./pages/web/pbx/PbxSettingRangePage";
import PbxSettingTrunkPage from "./pages/web/pbx/PbxSettingTrunkPage";
import PbxSettingRegistryPage from "./pages/web/pbx/PbxSettingRegistryPage";
import PbxSettingQueuePage from "./pages/web/pbx/PbxSettingQueuePage";
import PbxSettingInboundQueuePage from "./pages/web/pbx/PbxSettingInboundQueuePage";
import PbxSettingAgentPage from "./pages/web/pbx/PbxSettingAgentPage";
import PbxSettingExtensionPage from "./pages/web/pbx/PbxSettingExtensionPage";
import PbxSettingBillingCreditPage from "./pages/web/pbx/PbxSettingBillingCreditPage";
import PbxDashboardLivePage from "./pages/web/pbx/PbxDashboardLivePage";
import LeadSettingSourcePage from "./pages/web/lead/LeadSettingSourcePage";
import LeadSettingCampaignPage from "./pages/web/lead/LeadSettingCampaignPage";
import LeadSettingBatchsPage from "./pages/web/lead/LeadSettingBatchsPage";
import SuperSettingCompany from "./pages/web/super/SuperSettingCompany";
import denied from "./pages/web/Common/denied";
import SocketManager from "./websocket/SocketManager";
import LeadSettingCallDisposition from "./pages/web/lead/LeadSettingCallDisposition";
import LeadSettingCategoryFilePage from "./pages/web/lead/LeadSettingCategoryFilePage";
import LeadSettingCategoryCommentPage from "./pages/web/lead/LeadSettingCategoryCommentPage";
import LeadSettingUploadPage from "./pages/web/lead/LeadSettingUploadPage";
import LeadSettingPoolPage from "./pages/web/lead/LeadSettingPoolPage";
import LeadSettingAllocationPage from "./pages/web/lead/LeadSettingAllocationPage";
import LeadAgentWorkboardPage from "./pages/web/lead/LeadAgentStattionPage";
import LeadAdminStationPage from "./pages/web/lead/LeadAdminStationPage";
import LeadSettingTargetPage from "./pages/web/lead/LeadSettingTargetPage";
import MobileLoginPage from "./pages/mobile/Common/MobileLoginPage";
import MobileMainPage from "./pages/mobile/Common/MobileMainMenu";
import MobileLeadAgentStationPage from "./pages/mobile/Lead/MobileLeadAgentStationPage";
import MobileLeadAgentDashboardPage from "./pages/mobile/Lead/MobileLeadAgentDashboardPage";
import DocGenPdfPage from "./pages/web/document/DocGenPdfPage";
import DocGenInfoPage from "./pages/web/document/DocGenInfoPage";
import DocGenVariablePage from "./pages/web/document/DocGenVariablePage";
import DocGenApplicantPage from "./pages/web/document/DocGenApplicantPage";
import DocGenTemplatePage from "./pages/web/document/DocGenTemplatePage";
import DocGenApplicationPage from "./pages/web/document/DocGenApplicationPage";
import DocShareEsignPage from "./pages/web/document/DocShareEsignPage";
import eSign from "./pages/web/public/eSign";
import CrmEntityModulesPage from "./pages/web/crm/CrmEntityModulesPage";
import CrmEntityAttributesPage from "./pages/web/crm/CrmEntityAttributesPage";
import ChannelAvmRegisterAudioPage from "./pages/web/channel/ChannelAvmRegisterAudioPage";
import CrmEntityRecordPage from "./pages/web/crm/CrmEntityRecordPage";
import ChannelAvmSendAudioPage from "./pages/web/channel/ChannelAvmSendAudioPage";
import PbxCallOutboundPage from "./pages/web/pbx/PbxCallOutboundPage";
import AgendaSchedulePage from "./pages/web/agenda/AgendaSchedulePage";
import AgendaOwnersPage from "./pages/web/agenda/AgendaOwnersPage";
import AgendaCategory from "./pages/web/agenda/AgendaStatusPage";
import AgendaCategoryPage from "./pages/web/agenda/AgendaCategoryPage";
import AgendaStatusPage from "./pages/web/agenda/AgendaStatusPage";
import PbxCallCdrPage from "./pages/web/pbx/PbxCallCdrPage";
import PbxCallRecordingPage from "./pages/web/pbx/PbxCallRecordingPage";

const createHistory = require("history").createHashHistory;
const history = createHistory();

function webRouter() {
    return(
        <Switch>
            <Route exact path="/login" component={LoginPage}/>
            <Route exact path="/" component={LoginPage}/>
            <Route exact path="/super-setting-company" component={SuperSettingCompany}/>

            <Route exact path="/pbx-setting-range" component={PbxSettingRangePage}/>
            <Route exact path="/pbx-setting-trunk" component={PbxSettingTrunkPage}/>
            <Route exact path="/pbx-setting-registry" component={PbxSettingRegistryPage}/>
            <Route exact path="/pbx-setting-queue" component={PbxSettingQueuePage}/>
            <Route exact path="/pbx-setting-inbound" component={PbxSettingInboundQueuePage}/>
            <Route exact path="/pbx-setting-agent" component={PbxSettingAgentPage}/>
            <Route exact path="/pbx-setting-extension" component={PbxSettingExtensionPage}/>
            <Route exact path="/pbx-setting-credit" component={PbxSettingBillingCreditPage}/>
            <Route exact path="/pbx-dashboard-live" component={PbxDashboardLivePage}/>

            <Route exact path="/lead-setting-supplier" component={LeadSettingSourcePage}/>
            <Route exact path="/lead-setting-campaign" component={LeadSettingCampaignPage}/>
            <Route exact path="/lead-setting-batch" component={LeadSettingBatchsPage}/>
            <Route exact path="/lead-setting-disposition" component={LeadSettingCallDisposition}/>

            <Route exact path="/lead-setting-category-file" component={LeadSettingCategoryFilePage}/>
            <Route exact path="/lead-setting-category-comment" component={LeadSettingCategoryCommentPage}/>
            <Route exact path="/lead-setting-upload" component={LeadSettingUploadPage}/>
            <Route exact path="/lead-setting-pool" component={LeadSettingPoolPage}/>
            <Route exact path="/lead-setting-agent" component={PbxSettingAgentPage}/>
            <Route exact path="/lead-setting-allocation" component={LeadSettingAllocationPage}/>
            <Route exact path="/lead-setting-target" component={LeadSettingTargetPage}/>

            <Route exact path="/lead-agent-workboard" component={LeadAgentWorkboardPage}/>
            <Route exact path="/lead-admin-workboard" component={LeadAdminStationPage}/>

            <Route exact path="/doc-gen-pdf" component={DocGenPdfPage}/>
            <Route exact path="/doc-gen-info" component={DocGenInfoPage}/>
            <Route exact path="/doc-gen-variable" component={DocGenVariablePage}/>
            <Route exact path="/doc-gen-applicant" component={DocGenApplicantPage}/>
            <Route exact path="/doc-gen-template" component={DocGenTemplatePage}/>
            <Route exact path="/doc-gen-application" component={DocGenApplicationPage}/>
            <Route exact path="/doc-share-pin" component={DocShareEsignPage}/>
            <Route exact path="/p/v1/:pincode" component={eSign}/>
            <Route exact path="/crm-entity-category" component={CrmEntityModulesPage}/>
            <Route exact path="/crm-entity-attributes" component={CrmEntityAttributesPage}/>
            <Route exact path="/crm-entity-record" component={CrmEntityRecordPage}/>
            <Route exact path="/channel-avm-register-audio" component={ChannelAvmRegisterAudioPage}/>
            <Route exact path="/channel-avm-send-message" component={ChannelAvmSendAudioPage}/>
            <Route exact path="/pbx-call-outbound" component={PbxCallOutboundPage}/>

            <Route exact path="/agenda-schedule" component={AgendaSchedulePage}/>
            <Route exact path="/agenda-owner" component={AgendaOwnersPage}/>
            <Route exact path="/agenda-category" component={AgendaCategoryPage}/>
            <Route exact path="/agenda-status" component={AgendaStatusPage}/>
            <Route exact path="/pbx-call-cdr" component={PbxCallCdrPage}/>
            <Route exact path="/pbx-call-recording" component={PbxCallRecordingPage}/>


            <Route exact path="/access-authorize-denied" component={denied}/>
            <Route exact path="*" component={NotFoundPage}/>

        </Switch>
    )
}
function mobileRouter() {
    return(
    <Switch>
        <Route exact path="/login" component={MobileLoginPage}/>
        <Route exact path="/" component={MobileLoginPage}/>
        <Route exact path="/main-menu" component={MobileMainPage}/>
        <Route exact path="/mobile-agent-workstation" component={MobileLeadAgentStationPage}/>
        <Route exact path="/mobile-agent-dashboard" component={MobileLeadAgentDashboardPage}/>
        <Route exact path="/p/v1/:pincode" component={eSign}/>

        <Route exact path="*" component={MobileMainPage}/>
    </Switch>
    )
}
function App() {
    return (
        <Provider store={store}>
            <SocketManager>
            <Router history={history}>
                {!isMobile?webRouter():mobileRouter()}
            </Router>
            </SocketManager>
        </Provider>
    );
}

export default App;

//  {!isMobile?webRouter():mobileRouter()}
