import React from "react";
import {Col, Form, Row, Select,Input} from "antd";
const {Option} = Select;

export default (props)=>{
    let {_this}=props;
    return(
        <div>
            <h3> Step 1: Info</h3>

            <Row gutter={16} style={{marginTop: 20}}>
                <Col span={6}>
                    <Form.Item
                        label={"Origin of Data"}
                    >
                        <Select
                            name={"OriginData"}
                            defaultValue=""
                            onChange={(e) => _this.handleChange(e, "OriginData")}
                        >
                            <Option value={"crm"}>CRM</Option>
                            <Option value={"csv"}>CSV (Not implement yet)</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} style={{marginTop: 5}}>
                <Col span={6}>
                    <Form.Item
                        label={"Campaign"}
                    >
                        <Input
                            name={"CampaignName"}
                            defaultValue=""
                            onChange={(e) => _this.handleInput(e, "CampaignName")}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} style={{marginTop: 5}}>
                <Col span={6}>
                    <Form.Item
                        label={"Notify Email"}
                    >
                        <Input
                            name={"NotifyEmail"}
                            defaultValue=""
                            onChange={(e) => _this.handleInput(e, "NotifyEmail")}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} style={{marginTop: 5}}>
                <Col span={6}>
                    <Form.Item
                        label={"Comment"}
                    >
                        <Input
                            name={"Comment"}
                            defaultValue=""
                            onChange={(e) => _this.handleInput(e, "Comment")}
                        />
                    </Form.Item>
                </Col>
            </Row>

        </div>
    )
}
