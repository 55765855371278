import React from "react";


export default (props)=>{

    return(
        <div>
            <h3> Step 6: Submit</h3>
            <p>Your are now read to send your avm message! click on Done button below</p>
        </div>
    )
}
