import React from "react";
import PageBuilder from "../../../components/web/Common/PageBuilder";
import Connector from "../../../redux/connector";

class CrmEntityAttributesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let StateStorage = [
            {stateField: "Data", moduleEntity: "entity", table: "entity-attributes", noCondition: false},
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
            {stateField: "Modules", moduleEntity: "entity", table: "entity-categories", noCondition: false},
            /*{stateField: "Registries", moduleEntity: "manager/entity", table: "registries", noCondition: false},
            {stateField: "Trunks", moduleEntity: "manager/entity", table: "trunks", noCondition: false},
            {stateField: "Agents", moduleEntity: "manager/entity", table: "agents", noCondition: false},*/
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Module", label: "Entity"},
            {id: "FieldName", label: "FieldName"},
            {id: "DataType", label: "Type"},
            {id: "DisplaySelection", label: "Selection"},
            {id: "Position", label: "Position"},
            {id: "Unique", label: "Unique"},
            {id: "Mandatory", label: "Mandatory"},
            {id: "Name", label: "Name"},
            {id: "Surname", label: "Surname"},
            {id: "Username", label: "Username"},
            {id: "Password", label: "Password"},
            {id: "Email", label: "Email"},
            {id: "Phone", label: "Phone"},
            {id: "Required", label: "Required"},
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "Org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
            /*{keyState: "Registries", targetKey: "Org", storeVariable: "registries"},
            {keyState: "Trunks", targetKey: "Org", storeVariable: "trunks"},
            {keyState: "Agents", targetKey: "Org", storeVariable: "agents"},
            {keyState: "Data", targetKey: "Org", storeVariable: "extensions"},*/
        ];

        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },
            {
                label: "Entity",
                message: "Please select your entity",
                key: "Name",
                value: "Name",
                lookInState: true,
                targetStorage: "Modules",
                StateKey: "Module",
                Conditions: [
                    {targetStateKey: "Org", localField: "Org"}
                ]
            },

        ];

        const fnBooleanField = (nameField, labelField,required) => {
            return {
                label: labelField,
                message: "Please select your "+labelField,
                type: "boolean",
                StateKey: nameField,
                required: required,
                options:[
                    {key: "yes", val: "Yes"},
                    {key: "no", val: "No"},
                ]
            }
        }

        let bldFields = [
            {
                label: "Field Name",
                message: "Please input your field name",
                type: "text",
                StateKey: "FieldName",
                required: true
            },
            {
                label: "Type",
                message: "Please input your field name",
                type: "option",
                StateKey: "DataType",
                required: true,
                options:[
                    {key: "float", val: "Number or Decimal"},
                    {key: "string", val: "Text"},
                    {key: "date", val: "Date"},
                    {key: "list", val: "List"},
                    {key: "boolean", val: "Boolean Or True/False"},
                ]
            },
            {
                label: "Order position",
                message: "Please input your field order position",
                type: "float",
                StateKey: "Position",
                required: true
            },
            {
                label: "Display Section",
                message: "Please input your field group name",
                type: "text",
                StateKey: "Section",
                required: true
            },
            fnBooleanField("Unique","Unique key reference?",true),
            fnBooleanField("Mandatory","Mandatory key?",true),
            fnBooleanField("Name","Used as  a name?",true),
            fnBooleanField("Surname","Used as  a surname?",true),
            fnBooleanField("Username","Used as username?",true),
            fnBooleanField("Password","Used as Password?",true),
            fnBooleanField("Email","Used as Email address?",true),
            fnBooleanField("Phone","Used as Phone number?",true),
            fnBooleanField("DisplayTable","Display in table?",true),
            fnBooleanField("DisplaySelect","Display in option box?",true),

        ]

        return (
            <PageBuilder
                pageTitle={"Entity Attribute"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Data"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={bldOptions}
                bldFields={bldFields}
                newFormTitle={"New Attribute"}
                postMsgResponse={"Thank you, attribute submitted!"}
                postMsgWarning={"Are you sure you want to submit this attribute"}
                postEndpoint={"/entity/insert/entity-attributes"}
            />
        )
    }

}

export default Connector(CrmEntityAttributesPage);
