import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
const {Option} = Select;

export default (props)=>{
    let {_this}=props;
    function onChangeCompany(value){
        _this.setState({
            currentOrg:value
        })
    }
    const onChangeStartDate=(key,date)=>{
        _this.setState({
            startDate:date
        });
        console.log("onChangeStartDate -----> ",date)
    }
    const onChangeEndDate=(key,date)=>{
        _this.setState({
            endDate:date
        });
        console.log("onChangeEndDate -----> ",date)
    }
    let ls=[];
    for(let i in _this.state.Companies){
        const row=_this.state.Companies[i];

        ls.push({
            key:row.CustomerNumber,
            val:row.Name
        })
    }
    return(
        <Row gutter={16}>
            <Col span={4}>
                <Form.Item
                    name="startDate"
                    label="Start Date"
                    rules={[{ required: true, message: 'Please choose the start date' }]}
                >
                    <DatePicker onChange={onChangeStartDate} style={{minWidth:"100%"}}/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    name="endDate"
                    label="End Date"
                    rules={[{ required: true, message: 'Please choose the end date' }]}
                >
                    <DatePicker onChange={onChangeEndDate} style={{minWidth:"100%"}}/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Button type="default" onClick={_this.requestDashboard} style={{marginTop:30}}>
                    <SearchOutlined /> Search
                </Button>
            </Col>
        </Row>
    )
}
