import React from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/connector";
import {GetUserToken, PostTo} from "../../../api/fetching-data";
import {SERVER_API} from "../../../configures/constants";
import Schedule from "../../../components/Common/Agenda/Schedule4";
import moment from "moment";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import BoxSelector from "../../../components/Common/ControllerInput/ControllerSelectBox";

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            Companies: [],
            Owners: [],
            Statuses: [],
            Categories: [],
            Appointments: [],
            component: "list",
            data: [],
            currentDate: moment().format("YYYY-MM-DD"),
            currentCompany: "",
        }

        this.currentDateChange = (currentDate) => {
            this.setState({currentDate})
        };
    }

    componentDidMount = async () => {
        await this.loadAllData();
        return
        const _this = this;
        setTimeout(() => {
            let tmp = _this.state;
            let data = _this.state.data;
            let date = "2020-04-18";
            let time = "10:15";
            const dateTime = date + "T" + time;
            data.push({
                title: "Easisec Agenda",
                startDate: "2020-04-19T10:15",// new Date(2020, 4, 18, 10, 15),
                endDate: "2020-04-19T11:00",// new Date(2020, 4, 18, 11, 15),
                notes: "Hi me by 10",
                agent: "Merveilleux",
                members: 1,
                stage: "pending"
            });
            data.push({
                title: "Easisec Entity",
                notes: "Hi by 15",
                startDate: "2020-04-19T12:50",// new Date(2020, 4, 18, 14, 10),
                endDate: "2020-04-19T14:00",// new Date(2020, 4, 18, 15, 15),
                agent: "Nathan",
                stage: "pending",
                members: 2,
            });
            data.push({
                title: "Church",
                notes: "Wednesday service",
                startDate: "2020-04-22T09:30",// new Date(2020, 4, 18, 14, 10),
                endDate: "2020-04-22T10:00",// new Date(2020, 4, 18, 15, 15),
                agent: "Merveilleux",
                members: 1,
                stage: "success"
            });
            data.push({
                title: "Home Cell",
                notes: "Monday Home service",
                startDate: "2020-04-24T10:30",// new Date(2020, 4, 18, 14, 10),
                endDate: "2020-04-24T12:00",// new Date(2020, 4, 18, 15, 15),
                agent: "Marlene",
                members: 3,
                stage: "expire"
            });
            tmp.data = data;
            _this.setState(tmp);
            console.log("===>LLLLLL==> ", data);
        }, 3000);
    }

    loadAllData = async () => {
        await this.fetchDataCompanies();
        await this.fetchDataOwners()
        await this.fetchDataCategories();
        await this.fetchDataStatuses();
        await this.fetchDataAppointments();
    }

    fetchDataCompanies = async () => {
        await this.fetchData("Companies", "client", "company")
    }
    fetchDataStatuses = async () => {
        await this.fetchData("Statuses", "agenda", "status")
    }
    fetchDataOwners = async () => {
        await this.fetchData("Owners", "agenda", "owner")
    }
    fetchDataCategories = async () => {
        await this.fetchData("Categories", "agenda", "category")
    }
    fetchDataAppointments = async () => {
        await this.fetchData("Appointments", "agenda", "appointment")
    }
    fetchData = async (stateEntity, moduleEntity, table) => {
        this.setState({
            isLoading: true,
            [stateEntity]: [],
        })
        let _this = this;
        let user = GetUserToken();
        let hub = {
            Org: user.OrgCode,
            Username: user.Username,
            Role: user.Role
        }
        let endpoint = "/" + moduleEntity + "/entity/list/" + table;
        await PostTo(SERVER_API, hub, endpoint, async (data) => {
            console.log("):(--->fetchData " + moduleEntity + " response: ", data)
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            _this.setState({
                isLoading: false,
                [stateEntity]: data.RESULT,
            })
        });
    }

    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    handleInput = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    getHandleValue = (key) => {
        return this.state[key] || ""
    }
    onSubmit = async () => {
        this.setState({
            isLoading: true,
            Companies: [],
        })
        let _this = this;
        let user = GetUserToken();
        let hub = {...this.state};
        delete (hub.Companies);
        delete (hub.isLoading);

        let endpoint = "/client/entity/insert/company";
        await PostTo(SERVER_API, hub, endpoint, async (data) => {
            console.log("):(--->fetchData company response: ", data)
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , Company submitted!")
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.fetchData();
        });

    }

    commitChanges = async ({added, changed, deleted}) => {
        let _this = this;
        this.setState(async (state) => {
            let {data} = state;
            if (added) {
                const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
                data = [...data, {id: startingAddedId, ...added}];
            }
            if (changed) {
                data = data.map(appointment => (
                    changed[appointment.id] ? {...appointment, ...changed[appointment.id]} : appointment));
            }
            if (deleted !== undefined) {
                data = data.filter(appointment => appointment.id !== deleted);
            }

            console.log("-->commitChanges--> ", added, data, changed, deleted);
            await _this.saveAppointment(added);
            return {data};
        });
    }
    saveAppointment = async (data) => {
        /*
        CustomerNumber string
	Ref            string
	Code           string
	StartDate      string
	EndDate        string
	Notes          string
	Title          string
	Stage          string
         */
        let _this = this;
        let user = GetUserToken();
        let hub = {
            Org: user.OrgCode,
            Title: data.title,
            StartDate: data.startDate,
            EndDate: data.endDate,
            Ref: this.findOwnerFromMember(data.members).Ref,
            Notes: data.notes,
            Stage: data.stage,
            Category: data.category,
            AllDay: data.allDay,
            Login: user.Username,
            CustomerNumber:this.state.currentCompany
        }

        let endpoint = "/agenda/entity/insert/appointment";
        await PostTo(SERVER_API, hub, endpoint, async (data) => {
            console.log("):(--->onSubmit appointment response: ", data)
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , Agenda appointment submitted!")
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadAllData();
        });
    }
    buttonComponent = (info) => {
        console.log("-->commitChanges--> ", info)
    }
    onAgentChange = (info) => {
        console.log("onAgentChange > ", info)
    }
    findOwnerFromMember = (indexIn) => {
        const state = this.state;
        let ref = {Ref: "", Name: ""};
        for (let i in state.Owners) {
            let index = parseInt(i) + 1;
            const row = state.Owners[i];
            if (index === indexIn) {
                ref = row;
            }
        }
        return ref
    }
    renderSchedule = () => {
        const state = this.state;
        let boo = true;
        if (!state.currentCompany ) {
            boo = false
        }
        if (!boo) {
            return (
                <p style={{color: "red", fontSize: 18}}>
                    (!)Please select your target company before managing your agenda
                </p>
            )
        }

        let schedulerData = [];
        let agents = [];
        let agentsIndex = [];
        let owners = [];
        let ownerResourceInstance = [];
        let statusResourceInstance = [];
        let categoryResourceInstance = [];



        for (let i in state.Owners) {
            let index = parseInt(i) + 1;
            const row = state.Owners[i];
            if(row.CustomerNumber!==state.currentCompany){
                continue
            }
            owners.push({
                id: index,
                email: row.Ref,
                name: row.Name,
                role: row.Role,
            });
            ownerResourceInstance.push({
                id: index,
                text: "Owner: " + row.Name
            })
            agents.push(row.Name);
            agentsIndex.push(index);
        }
        for (let i in state.Statuses) {
            const row = state.Statuses[i];
            if(row.CustomerNumber!==state.currentCompany){
                continue
            }
            statusResourceInstance.push({
                id: row.Name,
                text: "Status: " + row.Name,
                color: row.Color
            })
        }
        for (let i in state.Categories) {
            const row = state.Categories[i];
            if(row.CustomerNumber!==state.currentCompany){
                continue
            }
            categoryResourceInstance.push({
                id: row.Name,
                text: "Category: " + row.Name,
                color: row.Color
            })
        }

         boo = true;
        if (statusResourceInstance.length === 0) {
            boo = false
        }
        if (ownerResourceInstance.length === 0) {
            boo = false
        }
        if (categoryResourceInstance.length === 0) {
            boo = false
        }
        if (!boo) {
            return (
                <p style={{color: "red", fontSize: 18}}>Waiting for setting data e.g. Categories,status.............</p>
            )
        }

        for (let i in state.Appointments) {
            const row = state.Appointments[i];
            let members=0;
            let agent="";
            for(let a in owners){
                const owner  = owners[a];
                if(row.Ref ===owner.email){
                    members =owner.id;
                    agent=owner.name;
                }
            }
            schedulerData.push({
                title: row.Title,
                startDate:row.StartDate,
                endDate: row.EndDate,
                notes: row.Notes,
                agent: agent,
                members: members,
                stage: row.Stage,
                category:row.Category
            })

        }

        let resources = [
            {
                fieldName: 'stage',
                title: 'Status',
                instances: statusResourceInstance,
            },
            {
                fieldName: 'members',
                title: 'Agents',
                instances: ownerResourceInstance,
                allowMultiple: false,
            },
            {
                fieldName: 'category',
                title: 'Category',
                instances: categoryResourceInstance,
                allowMultiple: false,
            },


        ];

        let options = {
            shadePreviousCells: true,
            shadePreviousAppointments: true,
            updateInterval: 10000,
        }
        let grouping = [{
            resourceName: 'members',
        }];

        console.log("agents (:)---> ", agents);
        console.log("agentsIndex (:)---> ", agentsIndex);
        console.log("statusResourceInstance (:)---> ", statusResourceInstance);
        console.log("ownerResourceInstance (:)---> ", ownerResourceInstance);

        //return null

        return (
            <Schedule
                {...options}
                schedulerData={schedulerData}
                currentDate={this.state.currentDate}
                typeOfView={"Week"}
                commitChanges={this.commitChanges}
                buttonComponent={this.buttonComponent}
                currentDateChange={this.currentDateChange}
                resourcesData={resources}
                resourcesCategories={agents}
                resourcesIndex={agentsIndex}
                onAgentChange={this.onAgentChange}
                grouping={grouping}
            />
        )

    }

    renderSelectCompany = () => {
        let options = [];
        let state=this.state;
        for(let i in state.Companies){
            const row = state.Companies[i];
            options.push({
                key:row.CustomerNumber,
                val:row.Name
            })
        }
        return (
            <GridItem xs={12} sm={12} md={12}>
                <BoxSelector
                    handleInput2={this.handleInput2}
                    state={state}
                    ls={options}
                    label={"Company"}
                    name={"currentCompany"}
                    getHandleValue={this.getHandleValue}
                />
                <br/>
            </GridItem>
        )
    }

    render() {
        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                {this.renderSelectCompany()}
                <br/>
                <div style={styles.GridContainer}>

                    <div style={styles.containerGray}>
                    {this.renderSchedule()}
                    </div>
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(Company)


const styles = {
    GridContainer: {
        backgroundColor: "lightgray",
        margin: 0,
        paddingTop:20,
        paddingRight: 20,
        paddingBottom: 30,
        minWidth: "98%",
        maxWidth: "98%",
    },
    containerGray: {
        backgroundColor: "white",
        marginLeft: 20,
        paddingBottom: 30,
        minWidth: "96%",
        maxWidth: "95%",
        padding:20,
    },
}

/*
select org,module,fieldname from generaltablefield where org='Test Demo' and module='driver';

delete from generaltablefield where org='Test Demo' and module='driver' and fieldname='Email Address';


 */
