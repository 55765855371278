import React, {Component, Fragment} from 'react';
import Configures from "../../../configures";
import CloudDownloadOutlined from "@ant-design/icons/lib/icons/CloudDownloadOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import Table from "antd/es/table";


export default class DownloadFromPinDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUpload: {
                type: "",
                name: "",
                base64string: "",
                filename: "",
            },
        }
    }


    downloadFile = (row) => {
        var url = row.link;
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a'); // create html element anchor
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = row.filename; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();
    }

    openDocLink = (row) => {
        let link = row.link;
        console.log("Opening link pdf link request > ", link);
        document.title = "eSign Document";
        window.open(link, '_blank');
    }

    getFileList = () => {
        let ls = [];
        let allFiles = this.props._this.state.UploadedFiles;
        let record = this.props._this.state.selectedRecord;
        const server = Configures.ServerPbx;
        for (let i in allFiles) {
            const row = allFiles[i];
            if (row.PinCode === record.PinCode) {
                let endpoint = "/document/download/" + row.DropBoxRef;
                ls.push({
                    category: row.Category,
                    side: row.Side,
                    name: row.Name,
                    link: server + "" + endpoint,
                })
            }
        }
        console.log("getFileList > ",ls)
        return ls
    }

    render() {

        const innerStyle = {cursor: "pointer"};
        const columns = [
            {
                title: '#',
                dataIndex: 'index',
                key: 'index',
            },
            {
                title: 'Side',
                dataIndex: 'side',
                key: 'side',
            },{
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
            },
        ];

        let dataFiles=[];
        let allData=this.getFileList();
        for(let i in allData){
            let row = allData[i];
            row.index = <div>
                <CloudDownloadOutlined style={{fontSize: 18, color: "blue"}}/>
                <a onClick={() => this.downloadFile(row)} style={innerStyle}>Download</a>
                &nbsp;| &nbsp;
                <DownloadOutlined style={{fontSize: 18, color: "blue"}}/>
                <a onClick={() => this.openDocLink(row)} style={innerStyle}>Open</a>
                &nbsp;| &nbsp;
            </div>;
            dataFiles.push(row);
        }

        return (
            <Fragment>
                <p className={"alert alert-info"}>Download</p>
                <Table
                    style={{ marginTop: 8 }}
                    columns={columns}
                    dataSource={dataFiles}
                    />
            </Fragment>
        )

    }
}
