import React from "react";
import MyRadioList from "./MyRadioList";


export default (props)=>{
    let {_this}=props;
    let list=[
        /*{key:"now",val:"Now"},
        {key:"schedule",val:"Schedule"},*/
    ];
    for(let i in _this.state.IvrList){
        const row = _this.state.IvrList[i];
        list.push({
            key:row.Code,
            val:row.Name,
        })
    }
    let defaultValue = _this.state.SelectAudio;
    const onChange=(value)=>{
        _this.handleInput2( "SelectAudio",value)
    }

    return(
        <div style={{marginTop: 20}}>
            <h3> Step 4: Ivr Audio</h3>
            <p>Please select the audio from the list to be play when the customer answer the phone!.
                <br/>Note that you will be charge based on the duration of the your audio including
                the voice mail!
            </p>
            <MyRadioList list={list} defaultValue={defaultValue} onChange={onChange}/>
        </div>
    )
}
