import { combineReducers } from 'redux';
import {reducerMenus as menus,reducerNavigation as nav,reducerLogin as login,reducerLiveCalls as liveCalls,
    reducerPbx as pbx}from "./reducer";

export default combineReducers({
    menus,
    nav,
    login,
    liveCalls,
    pbx
})
