import React from "react";
import MyRadioList from "./MyRadioList";


export default (props)=>{
    let {_this}=props;

    let list=[
        {key:"now",val:"Now"},
        {key:"schedule",val:"Schedule"},
    ];
    let defaultValue = _this.state.PeriodType;
    const onChange=(value)=>{
        _this.handleInput2( "PeriodType",value)
    }
    return(
        <div style={{marginTop: 20}}>
            <h3> Step 3: Period</h3>
            <p>When are you planning to send this avm? please note that the schedule option is not implement yet.</p>
            <p>I suggest you to choose option [Now]</p>
            <MyRadioList list={list} defaultValue={defaultValue} onChange={onChange}/>
        </div>
    )
}
