import React from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/connector";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {UpcaseFirst,GetDataField,GetOptionsFromData} from "../../../api/fn";
import {GetUserToken, PostTo} from "../../../api/fetching-data";
import {SERVER_API} from "../../../configures/constants";
import ControllerSelectBox from "../../../components/Common/controller-inputs/ControllerSelectBox";
import ControllerInputBox from "../../../components/Common/controller-inputs/ControllerInputBox";
import ControllerInputButton from "../../../components/Common/controller-inputs/ControllerInputButton";

class AgendaCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            Companies: [],
            Statuses:[],
            component:"list",
            CustomerNumber :"",
            Name           :"",
            Description           :"",
        }
    }
    componentDidMount = async () => {
        await this.fetchDataCompanies();
        await this.fetchDataStatuses();
    }
    fetchDataCompanies = async () => {
        await this.fetchData("Companies","client","company")
    }
    fetchDataStatuses = async () => {
        await this.fetchData("Statuses","agenda","status")
    }

    fetchData = async (stateEntity,moduleEntity,table) => {
        this.setState({
            isLoading: true,
            [stateEntity]: [],
        })
        let _this = this;
        let user = GetUserToken();
        let hub = {
            Org: user.OrgCode,
            Username: user.Username,
            Role: user.Role
        }
        let endpoint = "/"+moduleEntity+"/entity/list/"+table;
        await PostTo(SERVER_API, hub, endpoint, async (data) => {
            console.log("):(--->fetchData "+moduleEntity+" response: ", data)
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            _this.setState({
                isLoading: false,
                [stateEntity]: data.RESULT,
            })
        });
    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    handleInput = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    getHandleValue = (key) => {
        return this.state[key] || ""
    }
    onSubmit=async()=>{
        this.setState({
            isLoading: true,
            Companies: [],
        })
        let _this = this;
        let user = GetUserToken();
        let hub = {...this.state};
        hub.Org = user.OrgCode;
        delete(hub.Companies);
        delete(hub.Categories);
        delete(hub.isLoading);

        let endpoint = "/agenda/entity/insert/status";
        await PostTo(SERVER_API, hub, endpoint, async (data) => {
            console.log("):(--->onSubmit Owner response: ", data)
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert("Thank you , Agenda Owner submitted!")
            _this.setState({
                isLoading: false,
                component:"list"
            });
            await this.fetchDataCompanies();
            await this.fetchDataStatuses();
        });

    }
    onAddRecord=()=>{
        this.setState({
            component:"form"
        })
    }
    onDelete=()=>{
        this.setState({
            component:"list"
        })
    }

    getColumns = () => {
        return [
            {id: "Company", label: "Company"},
            {id: "Name", label: "Name"},
            {id: "Color", label: "Color"},
        ]
    }
    /*
    CustomerNumber string
	Ref            string
	Name           string
	Role           string
     */

    renderList = () => {
        if(this.state.component!=="list"){
            return
        }
        let ls =this.state.Statuses;
        let temps=[];
        for(let i in ls){
            let row = ls[i];
            row.Company = GetDataField(this.state.Companies,"CustomerNumber",row.CustomerNumber,"Name");
            temps.push(row)
        }
        ls = temps
        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpcaseFirst("Company")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }



    renderForm=()=>{
        if(this.state.component!=="form"){
            return
        }
        let optionCompanies=GetOptionsFromData(this.state.Companies,"CustomerNumber","Name");
        let inputList = [
            {name: "CustomerNumber", label: "Company", type: "option",options:optionCompanies},
            {name: "Name", label: "Name of Owner", type: "text"},
            {name: "Color", label: "Color", type: "text"},
        ]

        return (
            <form style={{minWidth: "100%"}}>
                <h3 style={{color: "#42a5f5"}}>New Company</h3>
                {inputList.map((row, index) => {

                    if (row.type === "option") {
                        return (
                            <ControllerSelectBox
                                name={row.name}
                                label={row.label}
                                required={row.required || true}
                                handleInput2={this.handleInput2}
                                getHandleValue={this.getHandleValue}
                                styleIn={{minWidth: "100%"}}
                                ls={row.options}
                            />
                        )
                    }

                    return (
                        <ControllerInputBox
                            name={row.name}
                            label={row.label}
                            required={row.required || true}
                            handleInput2={this.handleInput2}
                            getHandleValue={this.getHandleValue}
                            styleIn={{minWidth: "100%"}}
                            type={row.option}
                        />
                    )
                })}

                <ControllerInputButton
                    variant={"contained"}
                    color="primary"
                    type={"button"}
                    onClick={this.onSubmit}
                    styleIn={{float: "right"}}
                    label={<span style={{fontWeight: "bold", fontSize: 18}}> Save & Submit</span>}
                />

            </form>
        )
    }

    render() {
        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true} pageTitle={"Agenda Status"}>
                <div className={"container"}>
                    <hr/>
                    {this.renderList()}
                    {this.renderForm()}
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(AgendaCategory)


const styles = {
    GridContainer: {
        minHeight: "100%",
        minWidth: "100%",
    }
}
