import React from "react";
/*import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/css/froala_editor.css';*/
import 'froala-editor/css/froala_editor.css';
import 'froala-editor/css/froala_style.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'font-awesome/css/font-awesome.css';
/*import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';*/
import FroalaEditor from 'react-froala-wysiwyg';
import Grid from "@material-ui/core/Grid";
import {ConvertStringToBase64} from "../../../api/fn";
import {Form, Input} from "antd";



export default class AddPagePdfHtml2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newTemplateBody: null,
            position:"",
        }
    }

    handleModelChange = (e) => {
        this.setState({newTemplateBody: e});
        console.log("handleModelChange   > ", this.state);
    }
    onClose=()=>{
        let {_this}=this.props;
        _this.onCloseModal()
    }
    onChangePosition=(e)=> {
        let value = e.target.value;
        console.log("-->handle-area>>  ",value)
        this.setState({
            position:value
        })
    }

    saveTempBody=async (withThank)=> {
        //const row = this.props.state.BuildTempRow;
        let body =this.state.newTemplateBody ;// convertStringToHtml(this.state.newTemplateBody);

        let {_this,keyShow}=this.props;
        let tmp=_this.state;
        tmp.pages.push(body);
        tmp[keyShow]=false;
        this.setState({
            inputData:""
        });
        await _this.addPage(ConvertStringToBase64(body),this.state.position);
        _this.setState(tmp);

    }

    render() {
        return (
            <view style={{
                margin:"0 auto 0",
                minWidth:560,
                minHeight:920
            }}>
                <Grid item sm={12}>
                    <Form.Item
                        label={"Page number"}
                        name={"position"}
                        rules={[{required: true, message: "Please input your page number!"}]}
                    >
                        <Input
                            type="text"
                            value={this.state.position}
                            defaultValue={this.state.position}
                            onChange={this.onChangePosition}
                        />
                    </Form.Item>
                </Grid>

                <FroalaEditor
                    tag='textarea'
                    config={{

                    }}
                    model={this.state.newTemplateBody}
                    onModelChange={this.handleModelChange}
                    style={{
                        minHeight:920
                    }}
                />

                <Grid item sm={12}>
                    <button
                        onClick={() => this.onClose()}
                        style={{
                            float: "left",
                            backgroundColor: "red",
                            color: "white",
                            margin: 10,
                            minWidth: 75,
                            minHeight: 35,
                            borderColor: "black",
                            borderWidth: 1,
                            borderRadius: 5,
                        }}

                    >
                        Close
                    </button>
                    <button
                        onClick={() => this.saveTempBody()}
                        style={{
                            float: "right",
                            backgroundColor: "#42a5f5",
                            color: "white",
                            margin: 10,
                            minWidth: 75,
                            minHeight: 35,
                            borderColor: "black",
                            borderWidth: 1,
                            borderRadius: 5,
                        }}
                    >
                        Submit
                    </button>

                </Grid>
            </view>
        )
    }

}
