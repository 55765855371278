import React from "react";
import {Comment, Avatar, Form, Button, List, Input, Select} from 'antd';
import moment from 'moment';
import SelectBox from "./SelcectBox";

const { TextArea } = Input;
const {Option} = Select;

export default (props)=>{
    let { onChange, onSubmit, submitting, value ,Categories,_this}=props;
    let ls=[];
    const onChangeCommentCategory=(value)=>{
        console.log("onChangeCommentCategory category > ",value);
        _this.setState({newCommentCategory:value})
    }

    for(let i in Categories){
        const row=Categories[i];
        if(_this.state.currentOrg===row.Org){
            ls.push({
                ke:row.Name,
                val:row.Name,
            })
        }
    }
    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };
    const handleSubmit=()=>{
    }
    const onFinish=()=>{
    }
    const onFinishFailed=()=>{

    }

    return(
        <div style={{minWidth:300}}>

            <Form.Item

                label={"Category"}
                name={"Category"}
                rules={[{required: false, message: "select your category"}]}
            >
                <SelectBox {...props} style={{minWidth:"100%"}}/>
            </Form.Item>
            <Form.Item>
                <TextArea rows={4} onChange={onChange} value={value} />
            </Form.Item>
            <Form.Item>

                <Button
                    htmlType="Cancel"
                    loading={submitting}
                    onClick={()=>_this.setState({btnComment:false})}
                    type="secondary"
                    style={{float:"left",backgroundColor:"red",color:"white"}}
                >
                    Cancel
                </Button>
                <Button
                    htmlType="submit"
                    loading={submitting}
                    onClick={onSubmit}
                    type="primary"
                    style={{float:"right"}}
                >
                    Add
                </Button>
            </Form.Item>

        </div>
    )
}
