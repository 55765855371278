import React from "react";
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import Title from "../../../components/Common/Title";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Page from "../../../layout/web/Page";
import {GetUserToken} from "../../../api/token";
import {
    attachImageProfile,
    buildAssociationData,
    findRecordDisplayKey, findRecordKey, findRecordKeyField,
    findRecordType,
    findRecordUniqueKey,
    SortData, UpcaseFirst
} from "../../../api/fn";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {PostToPbx} from "../../../api/http-post";
import {Alert, Button, Col, Form, Input, Row, Select} from "antd";
import {SubmitPost} from "../lead/localFunctions";

import ModelSimple from "../../../components/Common/popup-modal/ModalView";
import PopupMe from "../../../components/Common/PopupMe";
import UploadDocument from "../../../components/web/Document/UploadDocument";
import PopupDownloadFile from "../../../components/web/Crm/PopupDownloadFile";
import ChooseCategoryModal from "../../../components/web/Crm/ChooseCategoryModal";


const {Option} = Select;

class CrmEntityRecordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Records: [],
            Associations: [],
            Modules: [],
            ListFilter: [],
            Files: [],
            Companies: [],
            Attributes: [],
            Org: "",
            selectedModule: "",
            DataView: {},
            showDetail: false,
            selectedRecord: null,
            showEditFormType: true,
            showDownloadFile: false,
            downloadFileList: [],
            DbName: "pbx",
            CqlTable: "generaltabledata",
            component: "list",
            fileName:"",
            fileNameUpload:"",
            fileCategory:"",
            fileCategory2:"",

            showViewDetail: false,
            selectViewData: {},
            selectViewTitle: "",
            showUpload: false,


        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Attributes", "entity", "entity-attributes", this, false)
        await fetchDataEntity("Records", "entity", "entity-record", this, false);
        await fetchDataEntity("Modules", "entity", "entity-categories", this, false)
        await fetchDataEntity("Files", "entity", "files", this, false)
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        })

    }
    handInput = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    loadInitialData = async () => {
        await fetchDataEntity("Records", "entity", "entity-record", this,true)
    }
    getListField = () => {
        let ls = [];
        let store = this.state.Attributes;
        store = SortData("Position", store)
        for (let i in store) {
            const row = store[i];
            if (row.Org !== this.state.Org) {
                continue
            }
            if (row.Module !== this.state.selectedModule) {
                continue
            }

            let type = "text";
            let options = [];
            if (row.DataType === "string") {
                type = "text";
            }
            if (row.DataType === "boolean") {
                type = "radio";
                options = [true, false];
            }
            if (row.DataType === "list") {
                type = "option";
                for (let i in row.Options) {
                    options.push({
                        key: i,
                        val: row.Options[i],
                    })
                }
            }
            let my = {
                field: row.FieldName,
                label: row.FieldName.toLocaleUpperCase(),
                options: options,
                type: type,
                required: row.Required,
                dataType: row.DataType,
                position: row.Position,
            }
            ls.push(my);

        }
        return ls;
    }
    handleInputFileChooseCategory = async (key, e) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
        console.log("handleInputChooseCategory > ", key, " > ", e.target.value)
    }
    closeChooseFileCategory = (record) => {
        if (this.state.fileCategory === "") {
            return alert("Sorry can't continue,please choose your file category!")
        }
        if (this.state.fileCategory !== "profile") {
            if (this.state.fileName === "") {
                return alert("Please provide the file name before continue!")
            }
        }
        this.setState({
            showChooseCategory: false,
            showUpload: true,
            selectedRecord: record,
            fileName: "",
            fileBase64: "",
        });
        //this.handleOpenUpload()

    }
    cancelChooseFileCategory = () => {
        this.setState({
            showChooseCategory: false,
            showUpload: false,
            selectedRecord: null,
            fileCategory: "",
            fileName: "",
            fileBase64: "",
        });
    }
    saveProfileFile = async (info) => {
        if (typeof info === "undefined") {
            return
        }
        const user = GetUserToken();
        const fileCategory = this.state.fileCategory2;
        let hub = {};
        hub.Org = info.org;
        hub.Module = this.state.selectedModule;
        hub.Ref = info.ref;
        hub.Category = fileCategory;
        hub.Type = fileCategory;
        hub.Name =  this.state.fileNameUpload;
        hub.Username = user.Username;
        hub.Filename = info.filename;
        hub.Base64String = info.base64string;
        const _this = this;
        let endpoint = "/document/upload/new";
        this.setState({isLoading: true});
        console.log("-=--=-=-=>>>--> ",hub)
        await SubmitPost(hub, endpoint, "Thank your your record have been saved!", this)
        _this.cancelChooseFileCategory();
    }
    openDetail = (row) => {
        this.setState({
            showDetail: true,
            selectedRecord: row,
        })
    }
    getListDisplayField = (targetField) => {
        let ls = [];
        let attributes = this.state.Attributes;
        attributes = SortData("Position", attributes);
        for (let i in attributes) {
            const row = attributes[i];
            if (row.Org !== this.state.Org) {
                continue
            }
            if (row.Module !== this.state.selectedModule) {
                continue
            }
            if (row[targetField]) {
                ls.push(row)
            }
        }
        return ls
    }
    buildDataView = () => {
        const associationsData = buildAssociationData(this, this, "DisplayTable");
        let columns = [
            {id: 'link', label: 'Profile Pic.', minWidth: 170, dataType: "string"},
        ];

        /**
         *
         * Let add our display field
         */
        const attributes = this.getListDisplayField("DisplayTable");
        for (let i in attributes) {
            const row = attributes[i];
            if (row.Org !== this.state.Org) {
                continue
            }
            if (row.Module !== this.state.selectedModule) {
                continue
            }
            const fieldname = row.FieldName;
            columns.push({
                id: fieldname, label: fieldname, minWidth: 170, dataType: "string"
            })
        }

        /**
         * let add association columns
         */
        let assCol = {};
        for (let i in associationsData) {
            const row = associationsData[i];
            if (row.MainEntity === this.state.selectedModule) {
                assCol[row.SupplierEntity] = true;
            }
        }
        for (let i in assCol) {
            columns.push({
                id: i, label: i, minWidth: 170, dataType: "string"
            })
        }
        /**
         * Let add our display fields data
         *
         */
        const addFiledDisplayData = (inData) => {
            for (let i in attributes) {
                const row = attributes[i];
                const fieldname = row.FieldName;
                inData[fieldname] = inData.Data[fieldname]
            }
            return inData
        }

        let ls = [];
        const databases = this.state.Records;
        for (let i in databases) {
            let row = databases[i];
            if (row.Org !== this.state.Org) {
                continue
            }
            if (row.Module === this.state.selectedModule) {
                row = addFiledDisplayData(row);
                ls.push(row)
            }
        }

        let lsFiles=[];
        for(let i in this.state.Files){
            const row=this.state.Files[i];
            if(row.org!==this.state.Org){
                continue
            }
            lsFiles.push(row)
        }
        ls = attachImageProfile(lsFiles, ls);

        /**
         *
         * let me add our associate data
         */
        let tmpLst = [];
        for (let i in ls) {
            let rowRecord = ls[i];
            const ref = rowRecord.Ref;
            for (let i in associationsData) {
                const row = associationsData[i];
                if (row.MainEntity === this.state.selectedModule) {
                    if (row.Main === ref) {
                        rowRecord[row.SupplierEntity] = row.SupplierName;
                    }
                }
            }
            tmpLst.push(rowRecord)
        }

        ls = tmpLst;

        return {columns: columns, data: ls}
    }
    openDownload = (row) => {
        let ls = [];
        for (let i in this.state.Files) {
            const o = this.state.Files[i];
            if (o.ref === row.Ref) {
                ls.push(o);
            }
        }
        this.setState({
            showDownloadFile: true,
            downloadFileList: ls,
            selectedRecord: row,
        })

    }
    closeDownload = () => {
        this.setState({
            showDownloadFile: false,
            downloadFileList: [],
        })
    }
    onDeleteView = async (row) => {
        if (!window.confirm("Are you sure that you want to remove?")) {
            return
        }
        let conds = [];
        conds.push({
            Key: "org",
            Val: GetUserToken().Org,
            Type: "string"
        });
        conds.push({
            Key: "tableref",
            Val: this.state.selectedModule,
            Type: "string"
        });
        conds.push({
            Key: "ref",
            Val: row["ref"],
            Type: "string"
        });

        let hub = {
            Fields: [],
            DbName: this.state.DbName,
            TableName: this.state.CqlTable,
            Conditions: conds,
        }
        const _this = this;
        let endpoint = "/global-service/remove";
        this.setState({isLoading: true});

        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
            })
            if (data === null) {
                alert("Serve" +
                    "r connection Error! try again later");
                return
            }
            alert("Thank you Record deleted!");
            _this.setState({
                isLoading: false,
            })
            await fetchDataEntity("Records", "entity", "database", _this)
        });


    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onSubmitRecord = async () => {
        if (!window.confirm("Are you sure to submit this record!")) {
            return
        }
        let _this = this;
        let fieldsList = this.getListField();
        let fieldsListOriginal=[];
        let ls = [];
        for (let i in this.state.Attributes) {
            const row = this.state.Attributes[i];
            if (row.Org !== this.state.Org) {
                continue
            }
            if (row.Module !== this.state.selectedModule) {
                continue
            }
            fieldsListOriginal.push(row);
        }

        let o = {};
        for (let i in fieldsList) {
            const row = fieldsList[i];
            const key = row.field;
            let val = this.state[key];
            const type = findRecordType(key, fieldsList);
            if (type === "float") {
                val = parseFloat(val)
            } else if (type === "number") {
                val = parseInt(val)
            } else if (type === "boolean") {
                val = val === "yes"
            } else {
                //todo do nothing
            }
            o[key] = val;
        }
        ;

        console.log("onSubmitRecord send 1 > ", o, " > ", this.state)

        let hub = {
            Org: GetUserToken().Org,
            module: _this.state.selectedModule,
            Data: o,
            Ref: findRecordUniqueKey(fieldsListOriginal, _this),
            names: findRecordDisplayKey(fieldsListOriginal, "DisplaySelection", _this),
            Email: findRecordKey(fieldsListOriginal, "Email", _this),
            Phone: findRecordKey(fieldsListOriginal, "Phone", _this),
            Username: findRecordKey(fieldsListOriginal, "Username", _this),
            Name: findRecordKey(fieldsListOriginal, "Name", _this),
            Surname: findRecordKey(fieldsListOriginal, "Surname", _this),
            Password: findRecordKey(fieldsListOriginal, "Password", _this),
            UniqueKey: findRecordKeyField(fieldsListOriginal, "Unique", _this)
        }
        console.log("onSubmitRecord send 2 > ", hub);

        /*let hub = {};
        hub.Org = GetUserToken().Org;
        hub.CqlTable = this.state.CqlTable;
        hub.DbName =this.state.DbName;
        hub.InData = [];
        hub.InData.push(data);*/

        let endpoint = "/entity/insert/entity-record";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await SubmitPost(hub, endpoint, "Thank your your record have been saved!", this)
    }
    openUpload = (row) => {
        this.setState({
            showChooseCategory: true,
            showUpload: false,
            selectedRecord: row,
        });
        //alert("Selected record: "+JSON.stringify(row))
    }
    closeDetail = () => {
        this.setState({
            showViewDetail: false,
            selectViewData: null,
            selectViewTitle: null
        });
    }
    openDetail = (record) => {
        let row = record;
        let info = {};
        info.title = row.Names;
        info.descriptionText = row.Module;
        info.descObject = row.Data;
        info.image = row.link;
        info.row = row;
        console.log("$$$$$--->(-> ", info)
        this.setState({
            showViewDetail: true,
            selectViewData: info,
            selectViewTitle: ""
        });
    }
    renderList = () => {
        if (this.state.component === "form" || this.state.selectedModule === "" || this.state.Org === "") {
            return null
        }
        let data = this.buildDataView()
        return (
            <TableDataView
                {...data}
                tableTitle={UpcaseFirst(this.state.selectedModule) + " Records (" + data.data.length + ") "}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDeleteView}
                openUpload={this.openUpload}
                openDetail={this.openDetail}
                closeDownload={this.closeDownload}
                openDownload={this.openDownload}
            />
        )
    }
    renderNewForm = () => {
        if (this.state.component === "list") {
            return null
        }
        let inputList = [];
        let fieldsStore = this.getListField();
        for (let i in fieldsStore) {
            const row = fieldsStore[i];
            inputList.push({
                name: row.field,
                label: row.field.toUpperCase(),
                required: row.required,
                message: "Please provide your " + row.field,
                type: row.type,
                StateKey: row.field,
                options: row.options
            })
        }

        const layout = {
            labelCol: {span: 4},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 15},
        };

        return (
            <Form
                {...layout}
                onSubmit={this.onSubmitRecord}
                className="login-form"
                onFinish={this.onSubmitRecord}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message={this.props.newFormTitle}/>

                {inputList.map((row) => {
                    if (row.type === "option" || row.type === "boolean") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    onChange={(e) => this.handleChange(e, row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                name={row.name}
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20, float: "right", marginLeft: 10}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit" style={{float: "right", marginLeft: 10}}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
    renderCompaniesComboxbox = () => {
        let user = GetUserToken();
        let ls = [];
        for (let i in this.state.Companies) {
            const row = this.state.Companies[i];
            if (user.Role === "super") {
                ls.push({
                    key: row.CustomerNumber,
                    val: row.Name,
                })
            } else {
                if (row.CustomerNumber === user.Org) {
                    ls.push({
                        key: row.CustomerNumber,
                        val: row.Name,
                    })
                }
            }


        }
        return (
            <Select
                name={"Org"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "Org")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.Org;
        for (let i in this.state.Modules) {
            const row = this.state.Modules[i];

            if (row.Org === org) {
                ls.push({
                    key: row.Name,
                    val: row.Name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderChooseFileCategory = () => {
        if (!this.state.showChooseCategory) {
            return null
        }
        let record = this.state.selectedRecord;

        return (
            <ChooseCategoryModal
                status={this.state.showChooseCategory}
                record={record}
                onChangeCategory={this.handleInputFileChooseCategory}
                handleClose={this.closeChooseFileCategory}
                onCancel={this.cancelChooseFileCategory}
                openUpload={this.openUpload}
                _this={this}
            />
        )
    }
    renderViewDetail = () => {
        if (!this.state.showViewDetail) {
            return null
        }
        let info = this.state.selectViewData;
        return (
            <PopupMe
                {...info}
                closeModal={this.closeDetail}
                visible={this.state.showViewDetail}
            />
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Record Management</Title>

                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={4}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesComboxbox()}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label={"Entity"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>

                    {this.renderList()}
                    {this.renderNewForm()}
                    {this.renderChooseFileCategory()}
                    {this.renderViewDetail()}
                </div>

                <UploadDocument
                    open={this.state.showUpload}
                    handleClose={this.handleCloseUpload}
                    selectedRecord={this.state.selectedRecord}
                    uploadCategory={this.state.fileCategory}
                    handleSave={this.saveProfileFile}
                    module={this.state.selectedModule}
                    filesLimit={1}
                    submitFile={this.saveProfileFile}
                    _this={this}
                />
                <PopupDownloadFile
                    popTitle={"Download File"}
                    _this={this}
                    popStatus={this.state.showDownloadFile}
                    popFnClose={this.closeDownload}
                    vehicle={this.state.selectedRecord}
                    fileList={this.state.downloadFileList}
                    tableRef={this.selectedModule}
                />
                {/*<ModelSimple
                    data={this.state.filterData}
                    open={this.state.showFilterPopup}
                    entity={this.props.module}
                    title={this.state.fitlerTitle}
                    onClose={() => this.closeFilterPopup()}
                    includeHeader={true}
                />*/}
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}

export default Connector(CrmEntityRecordPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
