import React, {Fragment} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import moment from "moment";
import {fetchDataEntity} from "../../../api/entity-data";
import {PostToPbx} from "../../../api/http-post";
import {Col, Form, Row, Select, Table} from "antd";
import SelectBar from "../../../components/web/Lead/SelectBar";
import {GetUserToken} from "../../../api/token";
import DashBox from "../../../components/web/Lead/DashBox";
import {UpcaseFirst} from "../../../api/fn";
import TableDataView from "../../../components/Common/tables/TableDataView";
import PopupRecordingListener from "../../../components/web/Pbx/PopupRecordingListener";

const {Option} = Select;
const currentDate = moment().format("YYYY-MM-DD");

class PbxCallCdrPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: currentDate,
            endDate: currentDate,
            RateCompany:0.45,
            currentOrg: "",
            Companies: [],
            Data: null,
            RecordingResults:[],
            showRecording:false,
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        let user = GetUserToken();
        this.setState({
            isLoading: true,
            currentOrg: user.OrgCode,
            currentAgent: user.Username,
            currentAgentName: user.FullName
        })
        //await this.requestDashboard();
        await fetchDataEntity("Companies", "client/entity", "company", this);
    }
    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    requestRecording= async (record) => {
        let hub = {
            Org: this.state.currentOrg,// user.OrgCode,
            KeyQuery: record.ChannelId,
            Params: [this.state.currentOrg.toLocaleLowerCase()],
        }
        this.setState({
            showRecording:true,
        })
        let endpoint = "/lead/recording/search";
        await this.PostState(hub, endpoint, "RecordingResults");
    }
    requestReport = async () => {
        let user = GetUserToken();
        let hub = {
            Org: this.state.currentOrg,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate,
            RateCompany:0.45,
            isLoading: true,
            Data: null,
        }
        let endpoint = "/e2bill/report";
        await this.PostState(hub, endpoint, "Data");
    }
    PostState = async (hub, endpoint, stateKey) => {
        let _this = this;
        this.setState({
            isLoading: true
        })
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log(endpoint + " response ---> ", endpoint, " > ", hub, data);
            _this.setState({
                [stateKey]: data,
                isLoading: false
            })
        });
    }


    renderSummaryAgent = () => {
        if (this.state.Data === null) {
            return null
        }
        const columns = [
            {
                title: 'Extension',
                dataIndex: 'Extension',
                key: 'Extension',
            },
            {
                title: 'Agent',
                dataIndex: 'Agent',
                key: 'Agent',
            },
            {
                title: 'Calls',
                dataIndex: 'TotalCaller',
                key: 'TotalCaller',
            },
            {
                title: 'Duration',
                dataIndex: 'TotalDurationText',
                key: 'TotalDurationText',
            },
            {
                title: 'Bill Sec',
                dataIndex: 'TotalBillSecText',
                key: 'TotalBillSecText',
            },

        ];
        let dataSource = [];

        const allDash = this.state.Data.SummaryAgent;
        for (let i in allDash) {
            let myDash = allDash[i];
            myDash.Extension = i;
            dataSource.push(myDash)
            /*dataSource.push({
                Agent:myDash.Agent,
                NewLead:myDash.LeadRequested,
                Callback:myDash.CallBack,
                TalkTime:myDash.TalkTimeDial,
                Calls:myDash.Calls,
                Qualify:myDash.Qualify,
                StatsLead: <span  style={{color:"white",backgroundColor:myDash.ColorTargetLead}}>{myDash.LeadRequested+"/"+myDash.TargetLead}</span> ,
                StatsOutbound: <span  style={{color:"white",backgroundColor:myDash.ColorTargetOutbound}}>{myDash.Calls+"/"+myDash.TargetOutbound}</span>,
                StatsQualify: <span  style={{color:"white",backgroundColor:myDash.ColorTargetQualify}}>{myDash.Qualify+"/"+myDash.TargetQualify}</span>,
            })*/
        }

        return (
            <Table
                dataSource={dataSource}
                columns={columns}
            />
        )
    }

    renderSummaryAll = () => {
        if (this.state.Data === null) {
            return null
        }
        const style = {background: 'white', padding: '8px'};
        const allDash = this.state.Data.SummaryAll;

        return (
            <Row style={{marginTop: 15}}>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Calls"}
                        contentText={allDash.TotalCaller}
                        footerText={"total call made"}
                    />
                </Col>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Talking Time"}
                        contentText={allDash.TotalDurationText}
                        footerText={"call duration"}
                    />
                </Col>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Bil Sec"}
                        contentText={allDash.TotalBillSecText}
                        footerText={"billing duration"}
                    />
                </Col>
                <Col span={4} style={style}>
                    <DashBox
                        color={"#0092ff"}
                        titleText={"Cost"}
                        contentText={"R"+allDash.CostCompany}
                        footerText={"Rate R 0.45/minute"}
                    />
                </Col>
            </Row>
        )
    }

    renderDetailCalls=()=>{
        if (this.state.Data === null) {
            return null
        }

        let  columns=[
            {id: "Extension", label: "Extension", minWidth: 170, dataType: "string"},
            {id: "Caller", label: "Caller", minWidth: 170, dataType: "string"},
            {id: "Customer", label: "Customer", minWidth: 170, dataType: "string"},
            {id: "Start", label: "Start", minWidth: 170, dataType: "string"},
            {id: "End", label: "End", minWidth: 170, dataType: "string"},
            {id: "Disposition", label: "Disposition", minWidth: 170, dataType: "string"},
            {id: "DialingTime", label: "TalkTime", minWidth: 170, dataType: "string"},
            {id: "BillSec", label: "BillSec", minWidth: 170, dataType: "string"},
            {id: "Recording", label: "Rec.", minWidth: 170, dataType: "string"},
        ];

        let ls=[];
        for(let i in this.state.Data.CallsAgent){
            let row = this.state.Data.CallsAgent[i];
            row.Recording=(<a onClick={()=>this.requestRecording(row)} style={{cursor:"pointer"}}>Rec.</a> )
            //
            ls.push(row)
        }

        let data={columns: columns, data: ls}

        return(
            <TableDataView
                {...data}
                tableTitle={"CDR Report (" + ls.length + ") "}
            />
        )
    }
    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto",
                    marginBottom: 20
                }}>
                    <Form layout="vertical" hideRequiredMark>

                        <SelectBar _this={this} {...this.props} />

                        <Row gutter={16}>
                            <Col span={24}>
                                {this.renderSummaryAll()}
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                {this.renderSummaryAgent()}
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                {this.renderDetailCalls()}
                            </Col>
                        </Row>

                        <PopupRecordingListener _this={this} />

                    </Form>
                </div>
            </Page>
        )
    }
}


export default Connector(PbxCallCdrPage);

const styles = {
    card: {
        minHeight: 400,
    },
    containerBox: {
        background: "#ececec",
        padding: "30px",
        marginTop: 20,
        minHeight: 400,
    },
    box: {
        minHeight: 400,
        margin: 10
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

