import React, {Fragment} from "react";
import {Flex, WhiteSpace, Button, Modal} from 'antd-mobile';
import SignaturePad from 'react-signature-pad-wrapper';
import "../../../components/web/Document/signature-pad.css";
import LogoImage from "../../../assets/img/logo.png";
import {List} from 'antd-mobile';
import {PostToPbx} from "../../../api/http-post";
import Configures from "../../../configures";
import {fetchDataEntity} from "../../../api/entity-data";
import {Form, Select} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const {Option} = Select;
const Item = List.Item;

const PlaceHolder = ({className = '', ...restProps}) => (
    <div className={`${className} placeholder`} {...restProps}>Block</div>
);

let uploadBase64string = "";
let uploadFilenameString="";
/*let takePicture = window.document.getElementById("take-picture"),
    showPicture = document.getElementById("show-picture"); //document.querySelector("#show-picture");
showPicture.src = uploadBase64string;*/


export default class eSign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PinCode: "",
            component: "signature",
            Record: {
                PinCode: "F10000001",
                FullName: "Merveilleux Biangacila",
                Phone: "0729139504",
                Email: "biangacila@gmail.com",
                Purpose: "please sign this document"
            },
            hasLogin: false,
            loginMessage: "",
            Results: [],
            Histories: [],
            Requests: [],
            selectedCategory: "",
        }

        this.takePicture = null;
        this.showPicture = null;
    }

    componentDidMount() {
        /*this.signaturePad.minWidth = 5;
        this.signaturePad.maxWidth = 10;
        this.signaturePad.penColor = 'rgb(66, 133, 244)';*/

        //this.requestLogin()
        /*const {match} = this.props;
        this.takePicture = window.document.getElementById("take-picture");
        this.showPicture = window.document.getElementById("show-picture"); //document.querySelector("#show-picture");
        this.showPicture.src = uploadBase64string;*/
    }

    handleChangeEntry = (value, key) => {
        let tmp = this.state;
        tmp[key] = value;
        this.setState(tmp)
    }
    clearSignature = () => {
        this.signaturePad.instance.clear();
    }
    previewImage = () => {
        //this.takePicture = window.document.getElementById("take-picture");
        this.showPicture = window.document.getElementById("show-picture"); //document.querySelector("#show-picture");
        this.showPicture.src = uploadBase64string;

        let files = document.getElementById("file01").files;
        if (files.length > 0) {
            this.getBase64(files[0]);
        }
    }
    onClickUpload=async ()=>{
        let _this=this;
        if(this.state.selectedCategory===""){
            return alert("Select your category first!")
        }
        this.setState({
            howUpload:true,
        });
        let record = this.state.Record;

        let infoToDropBox = {
            Org: record.Org,
            PinCode: record.PinCode,
            Name: this.state.selectedCategory,
            Base64String: uploadBase64string,
            FileName: uploadFilenameString,
        }

        await this.submitToDropBox(infoToDropBox, async (ref) => {
            let hub = {
                Org: record.Org,
                PinCode: record.PinCode,
                Category: "file",
                Name: this.state.selectedCategory,
                DropBoxRef: ref,
                MetaData: {},
                Side: "customer"
            };
            let endpoint = "/pdf/entity/insert/doc-pin-upload";
            console.log("submitToDropbox response > ", hub);
            await PostToPbx(hub, endpoint, async (data) => {
                if (data === null) {
                    alert("Sorry connection error try late");
                    return
                }
                _this.setState({
                    isLoading: false,
                });
                await _this.fetchDocs("doc-pin-upload", "Histories");
                await _this.fetchDocs("doc-pin-request", "Requests");
                alert("Your file "+_this.state.selectedCategory+" have been uploaded!")
            })
        })



    }
    getBase64 = (file) => {
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            _this.showPicture.src = reader.result;
            uploadBase64string = reader.result;
            uploadFilenameString = file.name;
            /*console.log(":>> ",reader.result);*/
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    fetchDocs = async (targetEndpoint, stateKey) => {
        let _this = this;
        let hub = {
            Org: this.state.Record.Org,
            PinCode: this.state.Record.PinCode,
        }
        let endpoint = "/pdf/entity/list/" + targetEndpoint;
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            let res = data.RESULT;
            _this.setState({
                [stateKey]: res
            })
        })
    }
    submitToDropBox = async (hub, callback) => {
        let endpoint = "/pdf/share/pin/upload";
        console.log("submitToDropbox response > ", hub);
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            callback(data.RESULT.DropBoxRef)
        })
    }
    submitSignature = async () => {
        let _this = this;
        if (this.signaturePad.isEmpty()) {
            // eslint-disable-next-line no-alert
            return alert('Please provide a signature first.');
        } else {
            //window.open(this.signaturePad.toDataURL());
        }
        let base64string = this.signaturePad.toDataURL();

        let record = this.state.Record;
        let infoToDropBox = {
            Org: record.Org,
            PinCode: record.PinCode,
            Name: "eSignature",
            Base64String: base64string,
            FileName: "signature.png",
        }

        await this.submitToDropBox(infoToDropBox, async (ref) => {
            let hub = {
                Org: record.Org,
                PinCode: record.PinCode,
                Category: "eSignature",
                Name: "Signature",
                DropBoxRef: "",
                MetaData: {},
                Side: "customer"
            };
            hub.DropBoxRef = ref;
            let endpoint = "/pdf/entity/insert/doc-pin-upload";
            console.log("submitToDropbox response > ", hub);
            await PostToPbx(hub, endpoint, async (data) => {
                if (data === null) {
                    alert("Sorry connection error try late");
                    return
                }
                _this.setState({
                    isLoading: false,
                });
                await _this.fetchDocs("doc-pin-upload", "Histories");
                await _this.fetchDocs("doc-pin-request", "Requests");
                alert("Your signature have been uploaded!")
            })
        })

    }
    submitLogin = async (password) => {
        let _this = this;
        const {match} = this.props;
        const MyPinCode = match.params.pincode;
        if (MyPinCode === "") {
            return alert("Invalid pin code, can't process your login, click on the url provided via sms or email")
        }
        let hub = {
            Secret: password,
            PinCode: MyPinCode
        }
        let endpoint = "/pdf/share/pin/login";
        console.log("submitLogin response > ", hub);
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }

            let res = data.RESULT;
            if (!res.HasLogin) {
                _this.setState({
                    loginMessage: "Sorry login fail, please try again later!"
                });
                return
            }
            _this.setState({
                loginMessage: "",
                hasLogin: true,
                Record: res.Result,
            });
            await _this.fetchDocs("doc-pin-upload", "Histories");
            await _this.fetchDocs("doc-pin-request", "Requests");
        })

    }
    requestLogin = () => {
        let _this = this;
        return Modal.prompt(
            'Password',
            'Please put your secret code send to you via sms',
            [
                {text: 'Cancel', onPress: _this.setState({loginMessage: "You just cancel login operation!"})},
                {text: 'Login', onPress: password => _this.submitLogin(password)},
            ],
            'secure-text',
        )
    }
    viewDocs = (row) => {
        const server = Configures.ServerPbx;
        let endpoint = "/document/download/" + row.DropBoxRef;
        let url = server + "" + endpoint;

        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            let contentType = xhr.getResponseHeader("Content-Type");
            let arr = contentType.split("/")
            console.log("contentType > ",contentType);
            let a = window.document.createElement('a'); // create html element anchor
            a.href = window.window.URL.createObjectURL(xhr.response); // xhr.response is a blob

            a.download = row.Name+"."+arr[1]; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();

    }
    renderUpload = () => {
        let record = null;// this.props._this.state.selectedRecord;
        let ls = [];
        if (this.state.Requests.length > 0) {
            record = this.state.Requests[0];
        }
        if (record !== null) {
            for (let key in record.DocsCustomer) {
                let val = record.DocsCustomer[key];
                ls.push(key)
            }
        }

        return (
            <div>
                <Form.Item
                    style={{minWidth: "50%"}}
                    label={"Category"}
                    name={"selectedCategory"}
                    rules={[{required: true, message: "select your Category"}]}
                >
                    <Select
                        name={"Applicant"}
                        defaultValue=""
                        style={{width: "70%"}}
                        onChange={(e) => this.handleChangeEntry(e, "selectedCategory")}
                    >
                        {ls.map((item, index) => {
                            return (
                                <Option value={item}>{item}</Option>
                            )
                        })}
                    </Select>

                </Form.Item>
                <Flex justify="center">
                    <input
                        type="file"
                        id="file01"
                        accept="image/*"
                        onChange={() => this.previewImage()}
                    />
                </Flex>

                <Flex justify="center" style={{margin:10}}>
                    <p>
                        <img src="about:blank" alt="preview image" id="show-picture" width="400" height="200"/>
                    </p>
                </Flex>
                <Flex justify="center">

                    <Button
                        style={{width: "30%"}}
                        onClick={this.onClickUpload}
                        size={"small"}
                    >
                        <UploadOutlined/> Upload
                    </Button>
                </Flex>

            </div>
        )
    }
    renderHistory = () => {
        let state = this.state.Histories;
        console.log("renderHistory > ", state);
        return (
            <div>
                <List className="my-list">
                    {state.map((item) => {
                        return (
                            <Item
                                arrow="horizontal"
                                multipleLine
                                onClick={() => this.viewDocs(item)}
                                extra={"View"}
                                style={{cursor: "pointer"}}
                            >
                                {item.Name} &nbsp;|&nbsp;{item.Side}&nbsp;|&nbsp;{item.Category}
                            </Item>
                        )
                    })}
                </List>
            </div>
        )

    }
    renderDetail = () => {
        let state = this.state.Record;
        return (
            <div>
                <List className="my-list">
                    <Item arrow="horizontal" multipleLine onClick={() => {
                    }} extra={state.FullName}>
                        Full Name:
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => {
                    }} extra={state.Phone}>
                        Phone:
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => {
                    }} extra={state.Email}>
                        Email:
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => {
                    }} extra={state.Purpose}>
                        Purpose:
                    </Item>
                </List>
            </div>
        )
    }
    renderSignaturePad = () => {
        return (
            <Fragment>
                <div className={"body"}>
                    <div className={"signature-pad"} style={{minWidth: "100%", maxHeight: 100}}>
                        <SignaturePad
                            ref={ref => this.signaturePad = ref}
                            className={"signature-pad"}
                            height={100}
                            redrawOnResize={true}
                        />
                    </div>

                </div>
                <Flex justify="end">
                    <Button
                        type={"default"}
                        onClick={() => this.clearSignature()}
                        size={"small"}
                        style={{float: "right", margin: 10, color: "red"}}
                    >Clear</Button>
                    <Button
                        type={"default"}
                        onClick={() => this.submitSignature()}
                        size={"small"}
                        style={{float: "right", margin: 10, color: "gray"}}
                    >Submit</Button>
                </Flex>
            </Fragment>
        )
    }

    renderLogin = () => {
        return (
            <div>

                <h3 style={{color: "gray"}}>welcome to our cloudCall System,
                    before continue please click on the login button below to start</h3>
                <WhiteSpace size="lg"/><br/>
                <Button onClick={() => this.requestLogin()}
                >login-secret</Button>
                <p style={{color: "red"}}>
                    {this.state.loginMessage}
                </p>
            </div>
        )
    }

    switch = () => {
        let state = this.state.component;
        if (state === "detail") {
            return this.renderDetail();
        } else if (state === "upload") {
            return this.renderUpload();
        } else if (state === "signature") {
            return this.renderSignaturePad();
        } else if (state === "history") {
            console.log("switch history > ", state, " > ", this.state.Histories)
            return this.renderHistory();
        } else {
            return this.renderLogin();
        }
    }

    render() {
        return (

            <div style={{
                justifyContent: "center",
                marginTop: 25,
                minWidth: "100%",
                background: "white",

            }}>

                <div style={{
                    maxWidth: 500,
                    minHeight: 150,
                    textAlign: "center",
                    margin: "0 auto 0",
                    paddingBottom: 100
                }}>

                    <Flex justify="center">
                        <img src={LogoImage} style={{
                            maxHeight: 35,
                            margin: 10
                        }}/>
                    </Flex>

                    {!this.state.hasLogin ? this.renderLogin() :
                        <Fragment>

                            <Flex justify="center">
                                <Button onClick={() => this.setState({component: "detail"})} type="warning"
                                        style={styles.btn}
                                        size="small" className="inline">Detail</Button>
                                <Button onClick={() => this.setState({component: "upload"})} type="warning"
                                        style={styles.btn}
                                        size="small" className="inline">Upload</Button>
                                <Button onClick={() => this.setState({component: "signature"})} type="warning"
                                        style={styles.btn} size="small" className="inline">Signature</Button>
                                <Button onClick={() => this.setState({component: "history"})} type="warning"
                                        style={styles.btn}
                                        size="small" className="inline">History</Button>
                            </Flex>

                            <div className="sub-title"
                                 style={{margin: -15}}>{this.state.component.toLocaleUpperCase()}</div>

                            {this.switch()}

                        </Fragment>}

                </div>
            </div>
        )
    }
}


const styles = {
    btn: {
        /*minHeight:20,
        maxHeight:20*/
    }
}
