import React, {Fragment} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import moment from "moment";
import {fetchDataEntity} from "../../../api/entity-data";
import {PostToPbx} from "../../../api/http-post";
import {Col, Form, Row, Select, Button, Table, Input} from "antd";
import SelectBar from "../../../components/web/Lead/SelectBar";
import {GetUserToken} from "../../../api/token";
import {PlusOutlined, SearchOutlined, SoundOutlined} from '@ant-design/icons';
import eConfig from "../../../configures";

const {Option} = Select;
const currentDate = moment().format("YYYY-MM-DD");

class PbxCallRecordingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: currentDate,
            endDate: currentDate,
            RateCompany: 0.45,
            currentOrg: "",
            Companies: [],
            Data: null,
            RecordingResults: [],
            showRecording: false,
            searchKey: ""
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        let user = GetUserToken();
        this.setState({
            isLoading: true,
            currentOrg: user.OrgCode,
            currentAgent: user.Username,
            currentAgentName: user.FullName
        })
        //await this.requestDashboard();
        await fetchDataEntity("Companies", "client/entity", "company", this);
    }
    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    requestRecording = async () => {
        let hub = {
            Org: this.state.currentOrg,// user.OrgCode,
            KeyQuery: this.state.searchKey,
            Params: [this.state.currentOrg.toLocaleLowerCase()],
        }
        this.setState({
            showRecording: true,
            RecordingResults: [],
            isLoading: true,
        })
        let endpoint = "/lead/recording/search";
        await this.PostState(hub, endpoint, "RecordingResults");
    }

    PostState = async (hub, endpoint, stateKey) => {
        let _this = this;
        this.setState({
            isLoading: true,
        })
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log(endpoint + " response ---> ", endpoint, " > ", hub, data);
            _this.setState({
                [stateKey]: data,
                isLoading: false
            })
        });
    }
    onClose = () => {
        this.setState({
            showRecording: false,
        });
    };
    handleChangeCompany = (value, key) => {

        this.setState({
            [key]: value,
        })
    }
    onChange = (value) => {
        this.setState({
            searchKey: value.target.value,
        });
    }
    renderList = () => {

        const dd = this.state.RecordingResults;
        let dataSource = [];
        for (let i in dd) {
            let row = dd[i];
            const wavUrl = eConfig.ServerPbx + "/document/download/" + row.Revision;
            row.Listen = (
                <audio controls>
                    <source src={wavUrl} type="audio/wav"/>
                    Your browser does not support the audio element.
                </audio>
            )
            dataSource.push(row);
        }
        const columns = [
            {
                title: 'Modified',
                dataIndex: 'Modified',
                key: 'Modified',
            },
            {
                title: 'Size',
                dataIndex: 'Size',
                key: 'Size',
            },
            {
                title: 'Listen',
                dataIndex: 'Listen',
                key: 'Listen',
            }
        ];

        return {dataSource, columns}
    }
    renderCompaniesSelection = () => {
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "currentOrg")}
                    value={this.state.currentOrg}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }


    render() {
        let {dataSource, columns} = this.renderList();

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto",
                    marginBottom: 20
                }}>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                    </div>

                    <Form layout="vertical" hideRequiredMark>

                        <Form layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                                <Col span={20}>
                                    <Form.Item
                                        name="name"
                                        label="Phone"
                                        rules={[{required: true, message: 'Please enter phone '}]}
                                    >
                                        <Input placeholder="Please enter your search key" onChange={this.onChange}/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Button type="default" onClick={this.requestRecording} style={{marginTop: 30}}>
                                        <SearchOutlined/> Search
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Table dataSource={dataSource} columns={columns}/>
                                </Col>
                            </Row>
                        </Form>

                    </Form>
                </div>
            </Page>
        )
    }

}

export default Connector(PbxCallRecordingPage);


