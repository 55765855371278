import React from "react";
import { Steps, Button, message } from 'antd';
import StepOneChooseInfo from "./StepOneChooseInfo";
import StepTwoFieldSetting from "./StepTwoFieldSetting";
import StepThreeSubmit from "./StepThreeSubmit";

const { Step } = Steps;


export default (props)=>{
    let {_this}=props;
    let steps=[
        {
            title: 'Choose info',
            content: <StepOneChooseInfo {...props}/>,
        },
        {
            title: 'Set Field Category',
            content: <StepTwoFieldSetting {...props}/>,
        },
        {
            title: 'Submit',
            content: <StepThreeSubmit  {...props}/>,
        },
    ];

    const next=()=> {
        const current = _this.state.current + 1;
        _this.setState({ current });
    }

    const prev=() =>{
        const current = _this.state.current - 1;
        _this.setState({ current });
    }
    const done=async ()=>{

        await props.submitUpload();
        message.success('Processing complete!');
    }

    const { current } = _this.state;
    return (
        <div>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div
                className="steps-content"
                style={{
                    minHeight:320,

                }}
            >
                {steps[current].content}
            </div>
            <div
                className="steps-action"
                style={{
                    marginBottom:10
                }}
            >
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => done()}>
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div>
        </div>
    );



}
/*
class StepsUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
    }

    steps=[
        {
            title: 'Choose info',
            content: <StepOneChooseInfo {...this.props}/>,
        },
        {
            title: 'Set Field Category',
            content: <StepTwoFieldSetting {...this.props}/>,
        },
        {
            title: 'Submit',
            content: <StepThreeSubmit  {...this.props}/>,
        },
    ]

    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }
    done=async ()=>{

        await this.props.submitUpload();
        message.success('Processing complete!');
    }

    render() {
        const { current } = this.state;
        return (
            <div>
                <Steps current={current}>
                    {this.steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <div
                    className="steps-content"
                    style={{
                        minHeight:320,

                    }}
                >
                    {this.steps[current].content}
                </div>
                <div
                    className="steps-action"
                    style={{
                        marginBottom:10
                    }}
                >
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => this.next()}>
                            Next
                        </Button>
                    )}
                    {current === this.steps.length - 1 && (
                        <Button type="primary" onClick={() => this.done()}>
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}*/



