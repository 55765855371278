
import {
    NAVIGATION_APP,
    NAVIGATION_MENU,
    NAVIGATION_MODULE,
    NAVIGATION_CONTENT
} from "../../constants";
const initial ={
    PageSystem:"pbx",
    PageModule:"home",
    PageMenu:"",
    PageContent:"",
}
const reduceMenus = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case NAVIGATION_APP:
            newState.PageSystem = action.payload;
            return Object.assign({}, newState);
        case NAVIGATION_MODULE:
            newState.PageModule = action.payload;
            return Object.assign({}, newState);
        case NAVIGATION_MENU:
            newState.PageMenu = action.payload;
            return Object.assign({}, newState);
        case NAVIGATION_CONTENT:
            newState.PageContent = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceMenus;

