import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import {  notification } from 'antd';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function CustomizedSnackbars({open,message,alertType,handleClose}) {


    const args = {
        message: 'Notification Janus',
        description:message,
        duration: 0,
    };

    if(open){
        notification.open(args);
        handleClose()
    }
    return null

}
