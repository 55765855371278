
import React from "react";
import Grid from "@material-ui/core/Grid";
import {Form, Input} from 'antd';

import 'suneditor/dist/css/suneditor.min.css'
import suneditor from 'suneditor';
import plugins from 'suneditor/src/plugins';
import {ConvertBase64IntoString, ConvertStringToBase64} from "../../../api/fn";
import {FroalaEditor} from "react-froala-wysiwyg";
import Divider from "@material-ui/core/Divider";



const initEditor = suneditor.init({
    plugins: plugins,
    height: 200,
    buttonList: [
        ['undo', 'redo',
            'font', 'fontSize', 'formatBlock',
            'paragraphStyle',
            'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
            'fontColor', 'hiliteColor', 'textStyle',
            'removeFormat',
            'outdent', 'indent',
            'align', 'horizontalRule', 'list', 'lineHeight',
            'table', 'link', 'image', 'video',
            'fullScreen', 'showBlocks', 'codeView',
            'preview', 'print', 'save', 'template']
    ],

});

let editorMe = null;


export default class AddPagePdfHtml extends React.Component{
    constructor(props) {
        super(props);
        const record = this.props._this.state.selectedRow;
        console.log("constructor --> ",record);
        this.state={
            inputData:record!==null?ConvertBase64IntoString(record.Body):"",
            position:record!==null?record.Position:"",
        }
    }
    componentDidMount=()=> {
        editorMe = initEditor.create('sample', {
            // The value of the option argument put in the "create" function call takes precedence
        });
    }
    handleInput=(e)=>{
        this.setState({
            inputData:e.target.value
        });

    }
    onChangePosition=(e)=> {
        let value = e.target.value;
        console.log("-->handle-area>>  ",value)
        this.setState({
            position:value
        })
    }
    submitData=async ()=>{
        let el=window.document.getElementById('sample');
        let myContent=el.value;
        if(editorMe !==null){
            myContent = editorMe.getContents({onlyContents: Boolean});
        }
        console.log("(:)->addTopics submitNodePrepare: ",myContent);
        //handleInputWithKey("newTopicBody",myContent);

        let {_this,keyShow}=this.props;
        let tmp=_this.state;
        tmp.pages.push(myContent);
        tmp[keyShow]=false;
        this.setState({
            inputData:""
        });
       await _this.addPage(ConvertStringToBase64(myContent),this.state.position);
        _this.setState(tmp);
    }
    onClose=()=>{
        let {_this}=this.props;
        _this.onCloseModal()
    }

    render() {
        return(
            <div  style={{minWidth:545,maxWidth:545}}>
                <Grid container sm={12} style={{marginTop: 35, minHeight: "100%"}} component={"div"}>
                    <Grid item sm={12}>
                        <Form.Item
                            label={"Page number"}
                            name={"position"}
                            rules={[{required: true, message: "Please input your page number!"}]}
                        >
                        <Input
                            type="text"
                            value={this.state.position}
                            defaultValue={this.state.position}
                            onChange={this.onChangePosition}
                        />
                        </Form.Item>
                    </Grid>

                    <Grid item sm={12}>
                        <textarea
                            id="sample"
                            name={"newTopicBody"}
                            style={{
                                minWidth: 545,
                                maxWidth: 545,
                                overflow: "scroll",
                                paddingTop: 50,
                                paddingLeft: 60,
                            }}
                            onChange={(e) => {
                                this.handleInput(e)
                            }}
                            value={this.state.inputData}
                        >

                            </textarea>

                    </Grid>
                    <Grid item sm={12}>
                        <button
                            onClick={() => this.onClose()}
                            style={{
                                float: "left",
                                backgroundColor: "red",
                                color: "white",
                                margin: 10,
                                minWidth: 75,
                                minHeight: 35,
                                borderColor: "black",
                                borderWidth: 1,
                                borderRadius: 5,
                            }}

                        >
                            Close
                        </button>
                        <button
                            onClick={() => this.submitData()}
                            style={{
                                float: "right",
                                backgroundColor: "#42a5f5",
                                color: "white",
                                margin: 10,
                                minWidth: 75,
                                minHeight: 35,
                                borderColor: "black",
                                borderWidth: 1,
                                borderRadius: 5,
                            }}
                        >
                            Submit
                        </button>

                    </Grid>
                </Grid>


            </div>
        )
    }
}
// 0671775085
const styles = {
    paper: {
        position: 'absolute',
        minWidth: 400,
        border: '2px solid #000',
    },
    root: {
        '& .MuiTextField-root': {
            margin: 10,
            width: 200,
        },
    },
    textarea: {
        minWidth: "75",
    }
};

