import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {UpCaseFirst, GetRowOptions, IsNotSuperUser, FindFieldFromRecordWithData} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {GetLoginToken, GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert} from "antd";
import {CheckSquareOutlined, CloseSquareOutlined, BellOutlined, PhoneOutlined} from '@ant-design/icons';
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";

const {Option} = Select;
let userN = GetUserToken();
const findCompanyExt=(extern,extensions)=>{

    for(let i  in extensions){
        const row = extensions[i];
        console.log("renderPeerState > ",(userN.Role!=="super")," > ",row);
        if(row.Number===extern){
            //todo extern find
            let boo=true;
            if(userN.Role!=="super"){
                if(userN.Org!==row.Org){
                    boo=false
                }
            }
            return {
                CompanyCode:row.Org,
                Boo:boo
            }
        }
    }
    return{
        CompanyCode:"none",
        Boo:false
    }
}
const findCompanyFromLineNumber=(lineNumber,lines)=>{
    const cleanNumber=(phone)=>{
        let destination = "0" + phone.substring(phone.length - 9);
        return destination;
    }
    for(let i in lines){
        let row =lines[i];

        if(cleanNumber(row.InboundNumber)===cleanNumber(lineNumber)){

            let boo=true;
            if(userN.Role!=="super"){
                if(userN.Org!==row.Org){
                    boo=false
                }
            }
            return {
                CompanyCode:row.Org,
                Boo:boo
            }
        }
    }

    return{
        CompanyCode:"none",
        Boo:false
    }
}
let findCompanyNameFromCode=(code,companies)=>{
    for(let i in companies){
        const row=companies[i];
        if(row.CustomerNumber===code){
            return row.Name;
        }
    }
    return code;
}
class PbxDashboardLivePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Extensions: [],
            Lines:[],
            isLoading: false,
            component: "list"
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Extensions", "manager/entity", "extensions", this, false);
        await fetchDataEntity("Lines", "manager/entity", "registries", this,false);
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    getAgentName = (extension) => {
        for (let i in this.state.Extensions) {
            const row = this.state.Extensions[i];
            if (row.Number === extension) {
                return row.Agent
            }
        }
        return extension;
    }
    makerStateIcon = (text) => {
        if (text === "online") {
            return (
                <CheckSquareOutlined style={{color: "green", fontSize: 20, fontWeight: "bold"}}/>
            )
        } else {
            return (
                <CloseSquareOutlined style={{color: "red", fontSize: 20, fontWeight: "bold"}}/>
            )
        }
    }
    makerStateCallStep = (state) => {
        if (state === "RINGING" || state === "WAITING") {
            return (
                <BellOutlined style={{color: "orange", fontSize: 20, fontWeight: "bold"}}/>
            )
        }
        if (state === "ANSWER") {
            return (
                <PhoneOutlined style={{color: "green", fontSize: 20, fontWeight: "bold"}}/>
            )
        }
    }
    renderPeerState = () => {
        let _this=this;

        let columns = [
            {id: "ExtensionWithIcon", label: "Ext."},
            {id: "Agent", label: "Agent"},
            {id: "Code", label: "Company"},
            /*{id:"StateIcon",label:"State"},*/
        ]
        let ls = [];
        let store = this.props.liveCalls.LivePeers;
        for (let i in store) {
            const row = store[i];
            const infoExtension = findCompanyExt(row.Extension,this.state.Extensions);
            row.Code = findCompanyNameFromCode(infoExtension.CompanyCode,this.state.Companies);
            if(!infoExtension.Boo){
                continue
            }
            //todo
            row.Agent = this.getAgentName(row.Extension);
            row.StateIcon = this.makerStateIcon(row.State);
            row.ExtensionWithIcon = (
                <div style={styles.displayRow}>
                    {this.makerStateIcon(row.State)}
                    <span style={styles.extensionSize}>{row.Extension}</span>
                </div>)
            ls.push(row);
        }

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={3} sm={3} md={3}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Agent")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    noAddButton={true}
                />
            </GridItem>
        )
    }

    renderOutbound = () => {
        const myExtension = (extension, state) => {
            return (
                <div style={styles.displayRow}>
                    {this.makerStateCallStep(state)}
                    <span style={styles.extensionSize}>{extension}</span>
                </div>
            )
        }
        let columns = [
            {id: "Peer", label: "Ext."},
            {id: "Customer", label: "Customer"},
            {id: "State", label: "State"},
            {id:"Code",label:"Code"},
        ];
        let ls = [];
        let store = this.props.liveCalls.LiveOutbounds;
        for (let i in store) {
            const row = store[i];

            const infoExtension = findCompanyExt(row.Extension,this.state.Extensions);
            row.Code = findCompanyNameFromCode(infoExtension.CompanyCode,this.state.Companies);
            if(!infoExtension.Boo){
                continue
            }

            let state = row.CurrentState.Name;
            if (state === "RINGING" || state === "ANSWER") {
                ls.push({
                    Peer: myExtension(row.Extension, state),
                    Customer: row.Phone,
                    State: row.CurrentState.Name,
                    StateTime: row.CurrentState.DateTime,
                    Disposition: row.Disposition,
                    Code:row.Code
                })
            }

        }
        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={4} sm={4} md={4}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Outbound")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    noAddButton={true}
                />
            </GridItem>
        )
    }

    renderInbound = () => {
        const myExtension = (extension, state) => {
            return (
                <div style={styles.displayRow}>
                    {this.makerStateCallStep(state)}
                    <span style={styles.extensionSize}>{extension}</span>
                </div>
            )
        }
        let columns = [
            {id: "CallerNumber", label: "Caller"},
            {id: "LineNumber", label: "Line"},
            {id: "QueueExtension", label: "Ext."},
            {id: "State", label: "State"},
            {id: "Code", label: "Code"},
        ];
        let ls = [];
        let my={};
        let store = this.props.liveCalls.LiveInbounds;
        for (let i in store) {
            const row = store[i];
            let state = row.CallStatus;

            const infoExtension = findCompanyFromLineNumber(row.LineNumber,this.state.Lines);
            row.Code = findCompanyNameFromCode(infoExtension.CompanyCode,this.state.Companies);
            if(!infoExtension.Boo){
                continue
            }


            if(state!=="COMPLETED" && state!=="ABANDON"){
                if(typeof my[i]==="undefined"){
                    ls.push({
                        CallerNumber: myExtension(row.CallerNumber, state),
                        LineNumber: row.LineNumber,
                        QueueExtension: row.QueueExtension,
                        State: row.CallStatus,
                        Code:row.Code,
                    });
                    my[i]=true;
                }

            }


        }
        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={4} sm={5} md={5}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Inbound")}
                    noAddButton={true}
                />
            </GridItem>
        )
    }


    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>General Dashboard</Title>

                    <GridContainer>
                        {this.renderPeerState()}
                        {this.renderOutbound()}
                        {this.renderInbound()}
                    </GridContainer>

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(PbxDashboardLivePage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    },
    displayRow: {
        display: "flex",
        alignItems: "center",
        flex: 1,
        justifyContent: "space-around",
        flexDirection: "row"
    },
    extensionSize: {
        fontWeight: "bold",
        fontSize: 20
    }
}

