import React from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {
    UpCaseFirst,
    GetRowOptions,
    IsNotSuperUser,
    FindFieldFromRecordWithData,
    IsFindInArray
} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {PostToPbx} from "../../../api/http-post";
import {Button, Form, Input, Select, Alert} from "antd";
import {GetUserToken} from "../../../api/token";
const {Option} = Select;

let userN = GetUserToken();

class PbxSettingExtensionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Ranges:[],
            Trunks: [],
            Agents:[],
            Registries: [],
            isLoading: false,
            component: "list",
            Org: "",
            Range:"",
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "manager/entity", "extensions", this, false);
        await fetchDataEntity("Trunks", "manager/entity", "trunks", this, false);
        await fetchDataEntity("Agents", "manager/entity", "agent", this, false);
        await fetchDataEntity("Ranges", "manager/entity", "ranges", this, false);
        await fetchDataEntity("Registries", "manager/entity", "registries", this, false);
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onDelete =async (row) => {
        if (!window.confirm("Are you sure you want to remove this inbound queue ?")) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });
        let hub={
            Org:row.Org,
            Number:row.Number
        }
        let endpoint = "/manager/entity/remove/extensions";
        this.submitPost(hub, endpoint, "Tank you, Extension removed")

    }
    appendAgentDetail=(hub)=>{
        let agent = "";
        for(let i in this.state.Agents){
            const row =this.state.Agents[i];
            if(row.Username===hub.AgentRef){
                hub.Agent = row.FullName;
                hub.Phone = row.Phone;
                hub.Account = row.Email;
            }
        }
        return hub;

    }
    onFinish = async (values) => {
        if (!window.confirm("Are you sure you want to submit this extension ?")) {
            return
        }
        let hub = values;
        hub.Nat = hub.Nat==="yes";
        hub = this.appendAgentDetail(hub);
        let endpoint = "/manager/entity/insert/extensions";
        this.submitPost(hub, endpoint, "Tank you, extension submitted")
    };
    submitPost = async (hub, endpoint, successMsg) => {
        let _this = this;
        await PostToPbx(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            if(data.STATUS==="fail"){
                alert("Fail, could not insert your extension,try again")
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    handleChange = (value,key) => {
        this.setState({
            [key]: value,
        })
    }
    getColumns = () => {
        return [
            {id: "Client", label: "Company"},
            {id: "Agent", label: "Agent"},
            {id: "Number", label: "Extension"},
            {id: "Secret", label: "Secret"},
            {id: "OutboundNumber", label: "Outbound"},
            {id: "Range", label: "Range"},
            {id: "Phone", label: "Phone"},
            {id: "Campaign", label: "Campaign"},
        ];

    }

    renderList = () => {
        let user = GetUserToken();
        if (this.state.component !== "list") {
            return
        }
        const state = this.state.Data;
        console.log("**** this.getMember > ", state)

        let ls = []; //this.state.Data;
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            row.Client = FindFieldFromRecordWithData("CustomerNumber", row.Org, "Name", this.state.Companies);
            /**
             * let check for privilege
             *
             */
            let comp = FindFieldFromRecordWithData("Name", row.OrgCode, "Name", this.state.Companies);
            if(user.Role==="super"){
                ls.push(row)
            }else{
                if(user.Org===row.Org || user.Org===comp  ){
                    ls.push(row)
                }
            }

        }

        let data = {columns: this.getColumns(), data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Queue")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }

        let registries=[],trunks=[],agents=[],ranges=[],extensions=[];
        for(let i in this.state.Registries){
            const row = this.state.Registries[i];
            if(row.Org ===this.state.Org){
                registries.push(row)
            }
        }
        for(let i in this.state.Trunks){
            const row = this.state.Trunks[i];
            if(row.Org ===this.state.Org){
                trunks.push(row)
            }
        }
        for(let i in this.state.Agents){
            const row = this.state.Agents[i];
            if(row.Org ===this.state.Org){
                agents.push(row)
            }
        }

        for(let i in this.state.Data){
            const row = this.state.Data[i];
            if(row.Org ===this.state.Org){
                extensions.push(row)
            }
        }

        let currentRange = {};

        for(let i in this.state.Ranges){
            const row = this.state.Ranges[i];
            if(row.Org ===this.state.Org){
                ranges.push(row)
                if(row.Name ===this.state.Range){
                    currentRange = row
                }
            }
        }

        let lsExtension=[];

        for(let i in currentRange.Extensions){
            const targetExtension = i;
            if(!IsFindInArray("Number",targetExtension,extensions)){
                lsExtension.push({
                    key:targetExtension,
                    val:targetExtension
                })
            }
        }

        let companies = GetRowOptions("CustomerNumber", "Name", this.state.Companies);
        let registriesOptions=GetRowOptions("InboundNumber", "InboundNumber", registries);
        let trunksOptions=GetRowOptions("Name", "Name", trunks);
        let agentsOptions=GetRowOptions("Username", "FullName", agents);
        let rangesOptions=GetRowOptions("Name", "Name", ranges);
        let contexts=[
            {key:"agent",val:"Agent (Call from lead only)"},
            {key:"admin",val:"Admin (Call Any number)"}
        ]
        let nats=[
            {key:"yes",val:"Yes"},
            {key:"no",val:"No"}
        ]


        let inputList = [
            {
                name: "Org",
                label: "Name of Company",
                type: "option",
                required: true,
                options: companies,
                message: "Please input your company name"
            },
            {
                name: "Trunk",
                label: "Trunk",
                type: "option",
                required: true,
                options:trunksOptions,
                message: "Please select your trunk"
            },
            /*{
                name: "OutboundNumber",
                label: "Outbound Number",
                type: "option",
                required: true,
                options:registriesOptions,
                message: "Please input your Outbound Number"
            },*/
            {
                name: "Range",
                label: "Range",
                type: "option",
                required: true,
                options:rangesOptions,
                message: "Please select your Range"
            },
            {
                name: "Number",
                label: "Extension",
                type: "option",
                required: true,
                options:lsExtension,
                message: "Please select your Extension"
            },
            {
                name: "AgentRef",
                label: "Agent",
                type: "option",
                required: true,
                options:agentsOptions,
                message: "Please select your Agent"
            },
            {
                name: "Context",
                label: "Context",
                type: "option",
                required: true,
                options:contexts,
                message: "Please input your Context"
            },
            {
                name: "Nat",
                label: "Are Behind firewall?",
                type: "option",
                required: true,
                options:nats,
                message: "Please select your network position"
            },
            {name: "OutboundNumber", label: "Outbound Number", type: "text", required: true, message: "Please input your Outbound Number"},
            {name: "Secret", label: "Secret", type: "text", required: true, message: "Please input your Secret"},
            {name: "PublicIp", label: "Public Ip Address", type: "text", required: false, message: "Please input your public Ip Address"},
            {name: "LocalIp", label: "Local Ip Address", type: "text", required: false, message: "Please input your local Ip Address"},
            {name: "Campaign", label: "Campaign", type: "text", required: false,message:"Please input your campaign"},
            /*{name: "Product", label: "Product", type: "text", required: false,message:"Please input your Product"},
            {name: "Account", label: "Account", type: "text", required: false,message:"Please input your Account"},
*/
        ]


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };


        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New Extension"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    onChange={(e)=>this.handleChange(e,row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Extension Management</Title>
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(PbxSettingExtensionPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}




