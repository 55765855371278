import React, {Fragment} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import Title from "../../../components/Common/Title";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {Button, Select, Form, Input, AutoComplete, Space, Table, Modal} from 'antd';
import {fetchDataEntity} from "../../../api/entity-data";
import {PostToPbx} from "../../../api/http-post";
import {FindFieldFromRecordWithData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {Row, Col, Divider} from 'antd';
import Paper from "@material-ui/core/Paper";
import {GetUserToken} from "../../../api/token";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import UploadDocument from "../../../components/web/Document/UploadDocument";
import UploadFormPinDoc from "../../../components/web/Document/UploadFormPinDoc";
import DownloadFromPinDoc from "../../../components/web/Document/DownloadFromPinDoc";

const {Option} = Select;


class DocShareEsignPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Infos: [],
            UploadedFiles: [],
            show: false,
            genShow: false,
            selectedCompany: "",
            selectedInfo: "",
            selectedApplicant: "",
            selectedRow: null,
            newEntry: {
                FullName: "",
                Phone: "",
                Email: "",
                Secret: "",
                SendMethod: "",
                Purpose: "",
                DocAdmin: {},
                DocCustomer: {}
            },
            tempCustomFile: {
                category: "",
                name: ""
            },
            tempAdminFile: {
                category: "",
                name: ""
            },
            showUpload: false,
            showDownload: false,
            selectedRecord: null,
            newUpload: {},
            ApplicantFiles:[],
            Applicants:[],
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Infos", "pdf/entity", "doc-pin-request", this,false);
        await fetchDataEntity("UploadedFiles", "pdf/entity", "doc-pin-upload", this,false);
        await fetchDataEntity("ApplicantFiles", "pdf/entity", "applicant-files", this,false)
        await fetchDataEntity("Applicants", "pdf/entity", "applicant", this,false)
    }
    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChangeEntry = (value, key) => {
        let tmp = this.state;
        tmp.newEntry[key] = value;
        this.setState(tmp)
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
            selectedInfo: ""
        })
    }
    handleReqFile = (e, stateKey, key) => {
        console.log("handleReqFile > ", e, stateKey, key)
        let tmp = this.state;
        tmp[stateKey][key] = e;
        this.setState(tmp);
    }
    uploadDoc = (row) => {
        alert("Upload implement soon!")
    }
    onAddRecord = () => {
        if (this.state.selectedCompany === "") {
            return alert("Please your company before adding!")
        }
        this.setState({
            show: true
        })
    }
    requestPinCode = async (callback) => {
        let hub = {}
        let endpoint = "/pdf/share/pin/request";
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            let res = data.RESULT;
            callback(res.NewPin);
        })
    }
    submitNewPin = async () => {
        let _this = this;
        let user = GetUserToken();
        let entry = this.state.newEntry;
        let applicant =JSON.parse(entry.Applicant);
        let hub = {
            Org: _this.state.selectedCompany,
            PinCode: "",
            Secret: entry.Secret,
            Ref:applicant.Ref,
            Email: applicant.Email,
            Phone: applicant.Phone,
            FullName: applicant.Display,
            DocsCustomer: entry.DocCustomer,
            DocsAdmin: entry.DocAdmin,
            State: "open",
            RequestBy: user.Username,
            Purpose: entry.Purpose,
            SendMethod: entry.SendMethod,
        }
        _this.setState({
            isLoading: true,
        })
        await this.requestPinCode(async (pinCode) => {
            hub.PinCode = pinCode;
            let endpoint = "/pdf/entity/insert/doc-pin-request";
            console.log("submitNewPin response > ", hub);
            await PostToPbx(hub, endpoint, async (data) => {
                if (data === null) {
                    alert("Sorry connection error try late");
                    return
                }
                _this.setState({
                    show: false,
                    isLoading: false,
                    newEntry: {
                        FullName: "",
                        Phone: "",
                        Email: "",
                        Secret: "",
                        SendMethod: "",
                        Purpose: "",
                        DocAdmin: {},
                        DocCustomer: {}
                    },
                    tempCustomFile: {
                        category: "",
                        name: ""
                    },
                    tempAdminFile: {
                        category: "",
                        name: ""
                    },
                });
                await fetchDataEntity("Infos", "pdf/entity", "doc-pin-request", _this);
                alert("Your pin doc have been created!")
            })

        })
    }
    submitToDropbox = async (callback) => {

        let record = this.state.selectedRecord;
        let o = this.state.newUpload;
        let hub = {
            Org: record.Org,
            PinCode: record.PinCode,
            Name: o.category,
            Base64String: o.base64string,
            FileName: o.fileName,
        }
        let endpoint = "/pdf/share/pin/upload";
        console.log("submitToDropbox response > ", hub);
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            callback(data.RESULT.DropBoxRef)
        })
    }
    submitUploadAdmin = async () => {

        let _this = this;
        let record = this.state.selectedRecord;
        let o = this.state.newUpload;
        this.setState({
            isLoading: true,
        });

        let hub = {
            Org: record.Org,
            PinCode: record.PinCode,
            Category: record.DocsAdmin[o.category],
            Name: o.category,
            DropBoxRef: "",
            MetaData: {},
            Side: "admin"
        };


        await this.submitToDropbox(async (dropBoxRef) => {
            // doc-pin-upload
            hub.DropBoxRef = dropBoxRef;
            let endpoint = "/pdf/entity/insert/doc-pin-upload";
            console.log("submitToDropbox response > ", hub);
            await PostToPbx(hub, endpoint, async (data) => {
                if (data === null) {
                    alert("Sorry connection error try late");
                    return
                }
                await fetchDataEntity("UploadedFiles", "pdf/entity", "doc-pin-upload", _this);
                _this.setState({
                    isLoading: false,
                    showUpload: false,
                });
                alert("Your pin doc have been uploaded!")
            })
        });
    }
    onDelete = async (row) => {
        if (!window.confirm("Are you sure to delete this Pin ? " + row.PinCode)) {
            return
        }
        let _this = this;
        _this.setState({
            isLoading: true,
        })
        let hub = {...row};
        delete hub.Action;
        let endpoint = "/pdf/entity/remove/doc-pin-request";
        console.log("submitNewPin response > ", hub);
        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            _this.setState({
                isLoading: false,
            });
            await fetchDataEntity("Infos", "pdf/entity", "doc-pin-request", _this);
            alert("Your pin doc have been delete!")
        })
    }
    sendPinSms = async (row) => {
        if (!window.confirm("Are you sure you want to send this pin via " + row.SendMethod + "?")) {
            return
        }
        let _this = this;
        delete row.Action;
        let hub = {...row};
        _this.setState({
            isLoading: true,
        })
        let endpoint = "/pdf/share/pin/send";
        console.log("sendPinSms response > ", hub);

        await PostToPbx(hub, endpoint, async (data) => {
            if (data === null) {
                alert("Sorry connection error try late");
                return
            }
            _this.setState({
                isLoading: false,

            });
            alert("Your pin doc have been created!")
        })
    }
    renderCompaniesSelection = () => {
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )

    }
    handleInputEntry = (e, key) => {
        let _this = this;
        let value = e.target.value;
        let tmp = _this.state;
        tmp.newEntry[key] = value;
        _this.setState(tmp)
        console.log("handleChange :> ", value, key)
    }
    handleInputRef = (value) => {
        let _this = this;
        let tmp = _this.state;
        tmp.Ref = value;
        _this.setState(tmp)
    }
    handleOpenUpload = (row) => {
        this.setState({
            selectedRecord: row,
            showUpload: true,
        });
        console.log("handleOpenUpload > ", row)
    }
    openDownload = async (row) => {
        this.setState({
            selectedRecord: row,
            showDownload: true,
        });
    }
    addCustomerFile = async () => {
        let tmp = this.state;
        if ( tmp.tempCustomFile.name === "") {
            return alert("Please provide your category and name before adding this requirement!")
        }
        const key = tmp.tempCustomFile.name;
        const val ="file";// tmp.tempCustomFile.category;
        tmp.newEntry.DocCustomer[key] = val;
        tmp.tempCustomFile = {
            category: "",
            name: ""
        }
        this.setState(tmp);
    }
    addAdminFile = async () => {
        let tmp = this.state;
        if ( tmp.tempAdminFile.name === "") {
            return alert("Please provide your category and name before adding this requirement!")
        }
        const key = tmp.tempAdminFile.name;
        const val ="file";// tmp.tempAdminFile.category;
        tmp.newEntry.DocAdmin[key] = val;
        tmp.tempAdminFile = {
            category: "",
            name: ""
        }
        this.setState(tmp);
    }
    renderList = () => {
        if (this.state.show) {
            return
        }
        let _this = this;

        const columns = [

            {id: "Client", label: "Company"},
            {id: "PinCode", label: "PinCode"},
            {id: "Secret", label: "Secret"},
            {id: "FullName", label: "Full Name"},
            {id: "Phone", label: "Phone"},
            {id: "Email", label: "Email"},
            {id: "State", label: "State"},
            {id: "SendMethod", label: "SendMethod"},
            {id: "Purpose", label: "Purpose"},
        ];
        let ls = [];
        console.log("SHARE REQUEST > ",this.state.Infos)
        for (let i in this.state.Infos) {
            let row = this.state.Infos[i];
            if (row.Org !== this.state.selectedCompany) {
                continue
            }

            const company = FindFieldFromRecordWithData(
                "CustomerNumber",
                row.Org,
                "Name",
                this.state.Companies
            );

            ls.push({
                ...row,
                Client: company,
            })
        }
        let moreButtons = [
            {
                icon: "mail",
                tooltip: "Send pin",
                myAction: this.sendPinSms
            }
        ];

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Records")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    otherFunctions={moreButtons}
                    openUpload={this.handleOpenUpload}
                    openDownload={this.openDownload}
                />
            </GridItem>
        )
    }

    renderFormNew = () => {
        if (!this.state.show) {
            return
        }
        let _this = this;


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };
        let fieldList = [
            /*{name: "FullName", label: "Full Name", type: "text"},
            {name: "Email", label: "Email", type: "email"},
            {name: "Phone", label: "Phone", type: "text"},*/
            {name: "Secret", label: "Secret", type: "text"},
            {name: "Purpose", label: "Purpose", type: "text"},
            {
                name: "SendMethod", label: "Send Method", type: "option", options: [
                    {key: "email", val: "Email"},
                    {key: "sms", val: "SMS"},
                ]
            },
        ];
        const style = {border: 'solid #0092ff', borderWidth: 1, borderRadius: 5, padding: '8px', minHeight: 300,};
        const columns = [
            {
                title: 'Category',
                dataIndex: 'Category',
            },
            {
                title: 'Name',
                dataIndex: 'Name',
            },
        ];
        let dataCustomer = [];
        let dataAdmin = [];
        let dataApplicant=[];
        let indexA = 0;
        let indexB = 0;
        for (let key in this.state.newEntry.DocCustomer) {
            let val = this.state.newEntry.DocCustomer[key];
            dataCustomer.push({
                key: indexA,
                Name: val,
                Category: key,
            });
            indexA++;
        }
        for (let key in this.state.newEntry.DocAdmin) {
            let val = this.state.newEntry.DocAdmin[key];
            dataAdmin.push({
                key: indexB,
                Name: val,
                Category: key,
            });
            indexB++;
        }
        for(let i in this.state.Applicants){
            const row = this.state.Applicants[i];
            if(row.Org===this.state.selectedCompany){
                dataApplicant.push({
                    key:JSON.stringify(row),
                    val:row.Display,
                })
            }
        }
        return (
            <Paper style={{padding: 20, overflow: "scroll"}}>
                <h4 style={{color: "red"}}>
                    New Share pin request
                    <Button
                        type="primary"
                        onClick={() => this.setState({show: false})}
                        style={{float: "right", backgroundColor: "red", marginLef: 10, borderRadius: 5}}
                    >
                        Cancel
                    </Button>
                </h4>

                <Form
                    {...layout}
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={this.submitNewPin}
                    onFinishFailed={this.submitNewPin}
                    style={{
                        marginTop: 20
                    }}
                >
                    <Form.Item
                        style={{minWidth: "50%"}}
                        label={"Applicant"}
                        name={"Applicant"}
                        rules={[{required: true, message: "select your Applicant"}]}
                    >
                        <Select
                            name={"Applicant"}
                            defaultValue=""
                            style={{width: "100%"}}
                            onChange={(e) => this.handleChangeEntry(e, "Applicant")}
                        >
                            {dataApplicant.map((record, index) => {
                                return (
                                    <Option value={record.key}>{record.val}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    {fieldList.map((row) => {
                        if (row.type === "option") {
                            return (
                                <Form.Item
                                    style={{minWidth: "50%"}}
                                    label={row.label}
                                    name={row.name}
                                    rules={[{required: true, message: "select your " + row.label}]}
                                >
                                    <Select
                                        name={"Applicant"}
                                        defaultValue=""
                                        style={{width: "100%"}}
                                        onChange={(e) => this.handleChangeEntry(e, row.name)}
                                    >
                                        {row.options.map((record, index) => {
                                            return (
                                                <Option value={record.key}>{record.val}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            )
                        }
                        return (
                            <Form.Item
                                style={{minWidth: "50%"}}
                                label={row.label}
                                name={row.name}
                                rules={[{required: true, message: "Please input your " + row.label}]}
                            >
                                <Input
                                    {...tailLayout}
                                    onChange={(e) => this.handleInputEntry(e, row.name)}
                                    type={row.type}
                                />
                            </Form.Item>
                        )
                    })}

                    <Divider orientation="left" style={{color: '#333', fontWeight: 'normal'}}>
                        &nbsp;
                    </Divider>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <div style={style}>
                                <h4 style={{color: "lightblue"}}><u>Customer Files To upload</u></h4>
                                <Input.Group compact>
                                    <Input
                                        style={{width: '50%', marginLeft: 10, marginRight: 10}}
                                        placeholder="File name"
                                        onChange={(e) => this.handleReqFile(e.target.value, "tempCustomFile", "name")}
                                    />
                                    <Button
                                        type="default"
                                        style={{width: '15%'}}
                                        onClick={this.addCustomerFile}
                                    >
                                        ADD
                                    </Button>
                                </Input.Group>

                                <Table columns={columns} dataSource={dataCustomer} size="middle"
                                       style={{marginTop: 20}}/>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <div style={style}>
                                <h4 style={{color: "lightblue"}}><u>Admin File Required To Upload</u></h4>
                                <Input.Group compact>
                                    <Input
                                        style={{width: '50%', marginLeft: 10, marginRight: 10}}
                                        placeholder="File name"
                                        onChange={(e) => this.handleReqFile(e.target.value, "tempAdminFile", "name")}
                                    />
                                    <Button
                                        type="default"
                                        style={{width: '15%'}}
                                        onClick={this.addAdminFile}
                                    >
                                        ADD
                                    </Button>
                                </Input.Group>

                                <Table columns={columns} dataSource={dataAdmin} size="middle" style={{marginTop: 20}}/>
                            </div>
                        </Col>
                    </Row>


                    <Button
                        type="primary"
                        style={{width: '10%', float: "right", marginTop: 20, marginBottom: 20}}
                        onClick={this.submitNewPin}
                    >
                        Submit
                    </Button>

                </Form>

            </Paper>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>Share document via PIN</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                    </div>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderList()}
                    </div>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderFormNew()}
                    </div>
                    <Modal
                        title="Basic Modal"
                        visible={this.state.showUpload}
                        onOk={() => this.submitUploadAdmin()}
                        onCancel={() => this.setState({showUpload: false})}
                        okText={"Submit"}
                    >
                        {this.state.selectedRecord !== null ?
                            <UploadFormPinDoc
                                _this={this}
                            /> : null}
                    </Modal>

                    <Modal
                        title="Download"
                        visible={this.state.showDownload}
                        onOk={() => this.setState({showDownload: false})}
                        onCancel={() => this.setState({showDownload: false})}
                        okText={"Submit"}
                    >
                        {this.state.selectedRecord !== null ?
                            <DownloadFromPinDoc
                                _this={this}
                            /> : null}
                    </Modal>

                </div>

            </Page>
        )
    }

}

export default Connector(DocShareEsignPage);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
