import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker,Table } from 'antd';
import { OrderedListOutlined,SearchOutlined } from '@ant-design/icons';

const { Option } = Select;


export default (props)=>{
    let {_this}=props;
    const showDrawer = () => {
        _this.setState({
            btnReportDaily: true,
        });

    };

    const onClose = () => {
        _this.setState({
            btnReportDaily: false,
        });
    };

    const onChangeClick=(val1,val2)=>{
        console.log("onChangeClick--> ",val1,val2)
        if(val2.length===2){
            const start=val2[0];
            const end=val2[1];
            _this.setState({
                startDate:start,
                endDate:end
            });
        }
    }

    let visible = _this.state.btnReportDaily;
    /*const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ];*/
    const dataSource = _this.state.StatsAgentDisposition;
    const columns = [
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'Agent',
            dataIndex: 'Agent',
            key: 'Agent',
        },
        {
            title: 'Disposition',
            dataIndex: 'Disposition',
            key: 'Disposition',
        },
        {
            title: 'Total',
            dataIndex: 'Counter',
            key: 'Counter',
        },
    ];

    return (
        <>
            <Button type="default" onClick={showDrawer}>
                <OrderedListOutlined /> Report
            </Button>
            <Drawer
                title="Daily Report Disposition"
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        {/*<Button onClick={onClose} type="primary">
                            Submit
                        </Button>*/}
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>

                    <Row gutter={16}>

                        <Col span={20}>
                            <Form.Item
                                name="dateTime"
                                label="DateTime"
                                rules={[{ required: true, message: 'Please choose the dateTime' }]}
                            >
                                <DatePicker.RangePicker
                                    style={{ width: '100%' }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={onChangeClick}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button type="default" onClick={_this.requestReportDisposition} style={{marginTop:30}}>
                                <SearchOutlined /> Search
                            </Button>
                           {/* <Form.Item
                                name="approver"
                                label=""
                                rules={[{ required: true, message: 'Please choose the approver' }]}
                            >
                                <Select placeholder="Please choose the approver">
                                    <Option value="jack">Jack Ma</Option>
                                    <Option value="tom">Tom Liu</Option>
                                </Select>
                            </Form.Item>*/}
                        </Col>
                    </Row>
                    <Row gutter={16}>

                        <Col span={24}>

                            <Table dataSource={dataSource} columns={columns} />

                            {/*<Form.Item
                                name="description"
                                label="Description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please enter url description',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} placeholder="please enter url description" />
                            </Form.Item>*/}
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );

}
