export const NAVIGATION_APP = "NAVIGATION_APP";
export const NAVIGATION_MENU = "NAVIGATION_MENU";
export const NAVIGATION_MODULE = "NAVIGATION_MODULE";
export const NAVIGATION_CONTENT = "NAVIGATION_CONTENT";
export const SET_APP_NAME = "SET_APP_NAME";
export const SET_APP_MODULE = "SET_APP_MODULE";
export const SET_APP_MENU = "SET_APP_MENU";
export const SET_APP_CONTENT = "SET_APP_CONTENT";
export const SET_APP_LINK = "SET_APP_LINK";
export const SET_NAV_MENUS = "SET_NAV_MENUS";
export const LOGGED_IN = "LOGGED_IN";

export const DASHBOARD_LIVE_CALL_IN = "DASHBOARD_LIVE_CALL_IN";
export const DASHBOARD_LIVE_CALL_OUT = "DASHBOARD_LIVE_CALL_OUT";
export const DASHBOARD_LIVE_PEER_STATUS = "DASHBOARD_LIVE_PEER_STATUS";

export const PBX_CHANGE_EXTENSION = "PBX_CHANGE_EXTENSION";
export const PBX_CHANGE_AGENT = "PBX_CHANGE_AGENT";
export const PBX_CALL_OUT_REQUEST = "PBX_CALL_OUT_REQUEST";
export const PBX_CALL_OUT_END = "PBX_CALL_OUT_END";
export const PBX_CALL_OUT_DO = "PBX_CALL_OUT_DO";
export const PBX_CALL_OUT_CALLERID = "PBX_CALL_OUT_CALLERID";
export const PBX_CALL_OUT_FORM = "PBX_CALL_OUT_FORM";
export const PBX_CALL_INCOME_REQUEST = "PBX_CALL_INCOME_REQUEST";
export const PBX_CALL_INCOME_ANSWER = "PBX_CALL_INCOME_ANSWER";
export const PBX_CALL_INCOME_HANGUP = "PBX_CALL_INCOME_HANGUP";
export const PBX_CHANGE_REGISTRY = "PBX_CHANGE_REGISTRY";
export const PBX_CALLING_STATUS = "PBX_CALLING_STATUS";
export const PBX_CALL_INCOME_ACTIVE = "PBX_CALL_INCOME_ACTIVE";

export const CURRENT_CALLS = "CURRENT_CALLS";

export const SAVE_MODULE_MENU = "SAVE_MODULE_MENU";
export const SAVE_APP_NAME = "SAVE_APP_NAME";


