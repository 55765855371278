import React, {Fragment} from "react";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import {fetchDataEntity} from "../../../api/entity-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {
    UpCaseFirst,
    GetRowOptions,
    IsNotSuperUser,
} from "../../../api/fn";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Title from "../../../components/Common/Title";
import {PostToPbx} from "../../../api/http-post";
import {
    Button,
    Col,
    Popover,
    Row,
    Divider,
    Card,
    List,
    Spin,
    Avatar,
    Breadcrumb,
    Switch,
    PageHeader,
    Descriptions
} from "antd";
import {PhoneFilled, StopOutlined} from '@ant-design/icons';
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import CommentLead from "../../../components/web/Lead/CommentLead";
import DispositionLead from "../../../components/web/Lead/DispositionLead";
import NewLead from "../../../components/web/Lead/NewLead";
import DashBox, {DashBoxLine} from "../../../components/web/Lead/DashBox";
import {PlusOutlined} from "@ant-design/icons";
import SearchLead from "../../../components/web/Lead/SearchLead";
import RecordLead from "../../../components/web/Lead/RecordingLead";
import {GetUserToken} from "../../../api/token";
import CommentLeadInner from "../../../components/web/Lead/CommentLeadInner";
import CloudCall, {doHangup, answer, sendDtmf, doCall} from "../../../webcall/JanusSipCall";
import AlertBox from "../../../webcall/AlertBox";
import Typography from "@material-ui/core/Typography";
import {Input} from 'antd';
import ReportDailyDisposition from "../../../components/web/Lead/ReportDailyDisposition";
import moment from "moment";
import {Tabs, Select, Space} from 'antd';

const {TabPane} = Tabs;

const {Search} = Input;

const currentDate = moment().format("YYYY-MM-DD");
let userN = GetUserToken();

class PbxCallOutboundPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newCommentBody: "",
            newCommentCategory: "",
            CategoryComments: [],
            LeadRequest: null,
            currentOrg: userN.Org,
            currentAgent: userN.Username,
            currentAgentName: userN.FullName,
            currentRole: "super",
            inRef: "",
            inPool: "",
            LeadComments: [],
            Dispositions: [],
            Extensions: [],
            newEntry: {
                disposition: "",
                comment: "",
            },
            dialingNumber: "",
            startDate: currentDate,
            endDate: currentDate,
            newLeadEntry: {
                name: "",
                surname: "",
                phone: "",
                email: "",
                comment: "",
            },
            showNewLeadList: true,
            LeadCallLogs: [],
            LeadStatusLogs: [],
            DashboardAgents: null
        }
    }

    componentDidMount = async () => {
        //IsNotSuperUser();
        await this.loadInitialData();
        //await this.onChangePbxStatus();
    }
    loadInitialData = async () => {
        let user = GetUserToken();
        this.setState({
            isLoading: true,
            currentOrg: user.OrgCode,
            currentAgent: user.Username,
            currentAgentName: user.FullName
        })
        await fetchDataEntity("Companies", "client/entity", "company", this);
        await fetchDataEntity("Extensions", "/manager/entity", "extensions", this, false);
    }
    PostState = async (hub, endpoint, stateKey) => {
        let _this = this;
        this.setState({
            isLoading: true
        })
        await PostToPbx(hub, endpoint, async (dataIn) => {
            const data = dataIn.RESULT;
            console.log(endpoint + " response ---> ", data, dataIn);
            _this.setState({
                [stateKey]: data,
                isLoading: false
            })
        });
    }
    submitCallLog = async (phone, ref) => {
        let hub = {
            Org: this.state.currentOrg,
            Agent: this.state.currentAgent,
            Ref: "admin-outbound",
            Phone: phone,
            CallRef: this.props.pbx.Extension.username
        }
        let endpoint = "/lead/entity/insert/batch-agent-call";
        await this.PostState(hub, endpoint, "fakeCallLogSubmit");
    }
    dialRequest = async (phone) => {
        console.log("dialRequest--> ", phone);
        const regStatus = this.props.pbx.Registry.status;
        if (regStatus === "Not registered") {
            return alert("Sorry you can't make without register first, click on the online switch!")
        }

        if (this.state.isDialing) {
            return alert("Please wait while another call in progress!");
        }
        if (phone === "") {
            return alert("Please provide number to call!");
        }
        if (!this.isValidNumber(phone)) {
            return alert("Error your number is not valid! 10 digit required")
        }
        let destination = "27" + phone.substring(phone.length - 9);
        //destination = destination.substring(destination.length - 9);

        doCall({
            to: destination,
            host: this.props.pbx.Server.host,
            AlertBox: AlertBox,
            isVideoCall: false
        });
        //this.props.PbxOutCallShowForm({isSet:true})
        this.setState({
            dialingNumber: phone
        });

        await this.submitCallLog(phone)

    }
    stop = () => {
        doHangup();
        this.props.PbxOutCallEnd({is: true})
    }
    isValidNumber = (num) => {
        let str = num;
        let pattern = new RegExp("^[0-9]{10}$");
        let result = pattern.test(str);
        console.log("isValidNumber -> ", str, " > ", result, " > ", /^\d{10}$/.test(str));
        return true
    }
    onChangePbxStatus = (state) => {
        const regStatus = this.props.pbx.Registry.status;
        console.log("onChangePbxStatus--> ", state, " > ", regStatus)
        if (state) {
            /**
             * let find extension to register
             * */
            let ext = null;
            for (let i in this.state.Extensions) {
                const row = this.state.Extensions[i];
                console.log("(((--->? ", row.AgentRef, " == ", this.state.currentAgent, " > ", (row.AgentRef === this.state.currentAgent));
                if (row.AgentRef === this.state.currentAgent) {
                    ext = row;
                }
            }
            /**
             * Let save to props for register
             * */

            if (ext === null) {
                return alert("Sorry we  can't register, agent not allocate")
            }
            const server = "voip2.easipath.com";
            const username = ext.Number;
            const secret = ext.Secret;
            console.log("2 (((--->? ", username, " > ", ext, " > ", this.state.Extensions);
            this.props.PbxChangeExtension({
                username: username,
                secret: secret + username,
                server: server,
            });
        }
    }
    renderCardPhone = () => {
        let props = this.props;
        let pbxCalling = props.pbx.Calling;
        let dialStatus = false;
        if (pbxCalling.status !== "Not registered") {
            dialStatus = true
        }

        let _this = this;
        const renderHangup = () => {
            if (pbxCalling.status === "Not registered" || pbxCalling.status === "hangup") {
                return null
            }
            return (
                <a
                    style={{cursor: "pointer", color: "red", marginLeft: 20}}
                    onClick={() => _this.stop()}
                >
                    <PhoneFilled/>
                </a>
            )
        }

        const {phones} = this.buildData();
        return (
            <List
                dataSource={phones}
                renderItem={item => (
                    <List.Item key={item.key}>
                        <List.Item.Meta
                            /*avatar={
                                <Avatar src="https://www.blazethread.com/wp-content/uploads/2019/07/220-2207243_phone-icon-png-mobile-phone-icon-circle-png.png" />
                            }*/
                            title={<a href="https://ant.design">{item.val}</a>}
                            description={UpCaseFirst(item.key)}
                        />
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a onClick={() => this.dialRequest(item.val)}
                                   style={{cursor: "pointer", color: "#0092ff"}}>
                                    Dial
                                    <Avatar
                                        style={{color: "#0092ff", marginLeft: 5}}
                                        src="https://www.blazethread.com/wp-content/uploads/2019/07/220-2207243_phone-icon-png-mobile-phone-icon-circle-png.png"/>
                                </a>
                                |&nbsp;{dialStatus ?
                                <span style={{color: "#9c27b0"}}>:{pbxCalling.status} {renderHangup()}</span> : null}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </List.Item>
                )}
            >
                {this.state.loading && this.state.hasMore && (
                    <div className="demo-loading-container">
                        <Spin/>
                    </div>
                )}
            </List>
        )
    }
    getDialHeader = () => {
        let props = this.props;
        let pbxExtension = props.pbx.Extension.username || "00000";
        let pbxOutCaller = props.pbx.OutCall.phone;
        let pbxRegistry = props.pbx.Registry;
        let pbxCalling = props.pbx.Calling;
        let registerStatus = pbxRegistry.status;
        let registryColor = "#f44336";

        if (registerStatus === "registered") {
            registryColor = "green"
        }

        let dialStatus = false;
        if (pbxCalling.status !== "Not registered") {
            dialStatus = true
        }
        let _this = this;
        const renderHangup = () => {
            if (pbxCalling.status === "Not registered" || pbxCalling.status === "hangup") {
                return null
            }
            return (
                <a
                    style={{cursor: "pointer", color: "red", marginLeft: 20}}
                    onClick={() => _this.stop()}
                >
                    <PhoneFilled/>
                </a>
            )
        }

        return (
            <Fragment>
                Dial Status {dialStatus ?
                <span style={{color: "#9c27b0"}}>:{pbxCalling.status} {renderHangup()}</span> : null}
            </Fragment>
        )
    }
    buildData = () => {
        const getDataCategory = (arr, type) => {
            let ls = [];
            for (let key in arr) {
                const val = arr[key];
                ls.push({
                    key: key,
                    val: val,
                    type: type,
                })
            }
            return ls
        }
        const req = this.state.LeadRequest;
        let leadPool = "---";
        let leadName = "---";//this.state.LeadRequest.LeadData.FullName;//"Merveilleux Biangacila";
        let leadState = "---";
        let phones = [], names = [], identities = [], allFields = [], emails = [], others = [];

        if (req === null) {
            return {leadPool, leadName, leadState, phones, emails, others, names, allFields, identities}
        }

        phones = getDataCategory(req.LeadData.Phone, "Phone");
        emails = getDataCategory(req.LeadData.Email, "email");
        names = getDataCategory(req.LeadData.Name, "name");
        identities = getDataCategory(req.LeadData.Identity, "identity");
        others = getDataCategory(req.LeadData.Other, "other");

        const combine1 = names.concat(phones);
        const combine2 = emails.concat(identities);
        allFields = combine1.concat(combine2);
        allFields = allFields.concat(others);

        leadName = req.LeadData.FullName.toLocaleUpperCase();
        leadPool = req.PoolName.toLocaleUpperCase();
        leadState = req.State.toLocaleUpperCase();

        return {leadPool, leadName, leadState, phones, emails, others, names, allFields, identities}

    }
    renderHangup = () => {
        let props = this.props;
        let pbxCalling = props.pbx.Calling;
        let dialStatus = false;
        if (pbxCalling.status !== "Not registered") {
            dialStatus = true
        }

        let _this = this;
        if (pbxCalling.status === "Not registered" || pbxCalling.status === "hangup") {
            return null
        }
        return (
            <a
                style={{cursor: "pointer", color: "red", marginLeft: 20}}
                onClick={() => _this.stop()}
            >
                <PhoneFilled/>
            </a>
        )
    }

    render() {
        let props = this.props;
        let pbxCalling = props.pbx.Calling;
        let dialStatus = false;
        if (pbxCalling.status !== "Not registered") {
            dialStatus = true
        }
        const regStatus = this.props.pbx.Registry.status;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto",
                    marginBottom: 20
                }}>
                    <div style={{
                        minWidth: "100%",
                    }}>
                        <PageHeader
                            ghost={false}
                            onBack={() => window.history.back()}
                            title={"Click to  Dial (Admin)".toLocaleUpperCase()}
                            subTitle="context reserved for admin user outside of lead"
                            extra={[
                                <div style={{marginRight: 10, minWidth: "100%", paddingLeft: 20}}>
                                    &nbsp;Extension: {this.props.pbx.Extension.username}
                                    <Switch
                                        checkedChildren="Online"
                                        unCheckedChildren="Offline"
                                        onChange={this.onChangePbxStatus}
                                    />
                                    <span style={{float: "right"}}>
                                        Reg.Status:
                                        <span style={{
                                            backgroundColor: regStatus === "Not registered" ? 'orange' : 'green',
                                            color: "white",
                                            borderRadius: 8,
                                            padding: 5,
                                            marginLeft: 5
                                        }}>{regStatus}</span>

                                    </span>
                                </div>,
                            ]}
                            style={{border: "1px solid rgb(235, 237, 240)"}}
                        >
                            <Descriptions size="small" column={3}>
                                <Descriptions.Item label="Full name">{userN.Fullname}</Descriptions.Item>
                                <Descriptions.Item label="Role">
                                    <a>{userN.Role}</a>
                                </Descriptions.Item>
                                <Descriptions.Item label="Creation Time">{userN.OrgDateTime}</Descriptions.Item>
                                <Descriptions.Item label="Username">{userN.Username}</Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    {userN.Status}
                                </Descriptions.Item>
                            </Descriptions>
                        </PageHeader>

                        <div style={styles.containerBox}>
                            <Tabs tabPosition={this.state.tabPosition} style={{
                                backgroundColor: "white",
                                minHeight: 400,
                                padding: "30px",
                                marginTop: 20,
                            }}>
                                <TabPane tab={"Click to Dial"} key="1">
                                    <div>
                                        {dialStatus ?
                                            <React.Fragment>
                                                <span
                                                    style={{color: "#9c27b0",fontSize:24}}
                                                >
                                                    Call Status :{pbxCalling.status} {this.renderHangup()}
                                                </span>
                                            </React.Fragment> : null}
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", flex: 1,}}>
                                        <div style={{maxWidth: "95%", minWidth: "95%", minHeight: 35, maxHeight: 35}}>
                                            <Search
                                                placeholder="input phone number"
                                                enterButton="Dial"
                                                size="large"
                                                onSearch={value => this.dialRequest(value)}
                                                style={{
                                                    minHeight: 35,
                                                    maxHeight: 35
                                                }}
                                            />
                                        </div>
                                        <div style={{
                                            maxWidth: "5%",
                                            minWidth: "5%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minHeight: 35,
                                            maxHeight: 35,
                                        }}>
                                            {/*<Button
                                                type={"danger"}
                                                style={{
                                                    minHeight: 35,
                                                    maxHeight: 35,
                                                    marginLeft: 10,
                                                    marginTop: 5
                                                }}
                                            >Hangup</Button>*/}
                                        </div>
                                    </div>


                                </TabPane>

                            </Tabs>
                        </div>

                    </div>

                </div>

            </Page>
        )
    }
}


export default Connector(PbxCallOutboundPage);

const styles = {
    card: {
        minHeight: 400,
    },
    containerBox: {
        background: "#ececec",
        padding: "30px",
        marginTop: 20,
        minHeight: 400,
    },
    box: {
        minHeight: 400,
        margin: 10
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
