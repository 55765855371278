import React from "react";
import Page from "../../layout/web/Page"
import Connector from "../../redux/connector";

class WelcomePage extends React.Component {

    render() {
        return (
            <Page {...this.props}>
                <div>
                    welcome to pbx
                </div>
            </Page>
        )
    }
}

export default Connector(WelcomePage)

