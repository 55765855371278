import React from 'react';
import Menus from "./Menus";
import {SAVE_MODULE_MENU, SAVE_APP_NAME} from "../../../constants/index"

const styles = {
    Active: {
        color: "white",
        fontWeight: "bold",
        fontSize: 16
    }
}
export default (props) => {
    /*let selectedModule=props.navigations.currentModule;*/

    let selectedApp = props.navigations.currentApp;
    let selectedModule = props.navigations.currentModule;// localStorage.getItem("@easisec-module") || "";
    let selectedMenu = props.navigations.currentSubmenu; // localStorage.getItem("@easisec-menu") || "";
    let ls = [];

    if (typeof props.navigations.menus[selectedApp] === "undefined") {
        selectedApp = "pa"
    }


    for (let i in props.navigations.menus[selectedApp][selectedModule]) {
        const row = props.navigations.menus[selectedApp][selectedModule][i];
        ls.push({key: row.name, link: row.link});
    }

    const onChooseMenu = (menu) => {
        props.dispatch({
            type: SAVE_MODULE_MENU,
            payload: {
                module: selectedModule,
                menu: menu.key,
                submenu: ""
            }
        });

        localStorage.setItem("@easisec-module", selectedModule);
        localStorage.setItem("@easisec-menu", menu.key);
        localStorage.setItem("@easisec-submenu", "");
        window.location.href = "#/" + menu.link;
    }

    const handleClick = () => {

    }

    return (
        <Menus {...props}
               open={true}
               handleClick={handleClick}
               ls={ls}
               onChooseMenu={onChooseMenu}

        />
    )

}
