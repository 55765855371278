import {SET_APP_NAME, SET_APP_MODULE, SET_APP_MENU, SET_APP_CONTENT, SET_NAV_MENUS, SET_APP_LINK} from "../../constants"

const RoleAll = ["super", "admin", "client", "agent"];
const RoleSuper = ["super"];
const RoleAdmin = ["super", "admin"];
const RoleAgent = ["super", "admin", "agent"];

const getStorage = (key) => {
    let val = localStorage.getItem(key);
    if (typeof val === "undefined") {
        return "";
    }
    if (val === null) {
        return ""
    }
    return val;
}
const setStorage = (key, val) => {
    localStorage.setItem(key, val);
    console.log("localStorage > ", localStorage)
    return val;
}
const initial = {
    CurrentApp: localStorage.getItem("@pbx-app") || setStorage("@pbx-app", "pbx"),
    CurrentModule: getStorage("@pbx-module") || setStorage("@pbx-module", "setting"),
    CurrentMenu: getStorage("@pbx-menu") || setStorage("@pbx-menu", "range"),
    CurrentLink: getStorage("@pbx-link") || setStorage("@pbx-link", "pbx-setting-range"),
    CurrentSubmenu: getStorage("@pbx-submenu") || setStorage("@pbx-submenu", ""),
    AllRole: RoleAll,
    menus: {
        pbx: {
            setting: [

                {
                    app: "pbx",
                    name: "range",
                    link: "pbx-setting-range",
                    roles: RoleSuper,
                    isDefault: true
                },
                {
                    app: "pbx",
                    name: "trunk",
                    link: "pbx-setting-trunk",
                    roles: RoleSuper,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "Inbound Line ",
                    link: "pbx-setting-registry",
                    roles: RoleSuper,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "queue",
                    link: "pbx-setting-queue",
                    roles: RoleSuper,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "inbound-queue",
                    link: "pbx-setting-inbound",
                    roles: RoleSuper,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "agent",
                    link: "pbx-setting-agent",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "extension",
                    link: "pbx-setting-extension",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pbx",
                    name: "credit",
                    link: "pbx-setting-credit",
                    roles: RoleSuper,
                    isDefault: false
                },
            ],
            dashboard: [
                {
                    app: "pbx",
                    name: "Live",
                    link: "pbx-dashboard-live",
                    roles: RoleAdmin,
                    isDefault: true
                },
                /*{
                    app: "pbx",
                    name: "CDR",
                    link: "pbx-dashboard-cdr",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "Recording",
                    link: "pbx-dashboard-recording",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "Outbound Report",
                    link: "pbx-dashboard-report-outbound",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "Inbound Report",
                    link: "pbx-dashboard-report-inbound",
                    roles: RoleAdmin,
                    isDefault: false
                },*/

            ],
            call: [
                {
                    app: "pbx",
                    name: "sip registry",
                    link: "pbx-call-registry",
                    roles: RoleAgent,
                    isDefault: true
                },
                {
                    app: "pbx",
                    name: "outbound",
                    link: "pbx-call-outbound",
                    roles: RoleAgent,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "cdr",
                    link: "pbx-call-cdr",
                    roles: RoleAgent,
                    isDefault: false
                },
                {
                    app: "pbx",
                    name: "recording",
                    link: "pbx-call-recording",
                    roles: RoleAgent,
                    isDefault: false
                },
            ],

        },
        lead: {
            setting: [
                {
                    app: "lead",
                    name: "Supplier",
                    link: "lead-setting-supplier",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "lead",
                    name: "Campaign",
                    link: "lead-setting-campaign",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "lead",
                    name: "Batch",
                    link: "lead-setting-batch",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "lead",
                    name: "Disposition",
                    link: "lead-setting-disposition",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "lead",
                    name: "Category of Comment",
                    link: "lead-setting-category-comment",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "lead",
                    name: "Category of File",
                    link: "lead-setting-category-file",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "lead",
                    name: "Upload",
                    link: "lead-setting-upload",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "lead",
                    name: "Pool",
                    link: "lead-setting-pool",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "lead",
                    name: "Agent",
                    link: "lead-setting-agent",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "lead",
                    name: "Allocation",
                    link: "lead-setting-allocation",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "lead",
                    name: "Target",
                    link: "lead-setting-target",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            "Agent": [
                {
                    app: "lead",
                    name: "Station Board",
                    link: "lead-agent-workboard",
                    roles: RoleAgent,
                    isDefault: false
                },
            ],
            "Admin": [
                {
                    app: "lead",
                    name: "Station Board",
                    link: "lead-admin-workboard",
                    roles: RoleAdmin,
                    isDefault: true
                },
            ],
        },
        dialer: [],
        super: {
            setting: [
                {
                    app: "super",
                    name: "company",
                    link: "super-setting-company",
                    roles: RoleSuper,
                    isDefault: true
                },
            ],
        },
        document: {
            Generate: [
                {
                    app: "document",
                    name: "Application",
                    link: "doc-gen-application",
                    roles: RoleAgent,
                    isDefault: true
                },
                {
                    app: "document",
                    name: "Info",
                    link: "doc-gen-info",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "document",
                    name: "Variable",
                    link: "doc-gen-variable",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "document",
                    name: "Template",
                    link: "doc-gen-template",
                    roles: RoleAdmin,
                    isDefault: false
                }, {
                    app: "document",
                    name: "Applicant",
                    link: "doc-gen-applicant",
                    roles: RoleAgent,
                    isDefault: false
                },
            ],
            Share: [
                {
                    app: "document",
                    name: "eDocument",
                    link: "doc-share-pin",
                    roles: RoleAgent,
                    isDefault: false
                },
            ],
        },
        crm: {
            setting: [
                {
                    app: "pbx",
                    name: "Entity",
                    link: "crm-entity-category",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pbx",
                    name: "Attributes",
                    link: "crm-entity-attributes",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            Database: [
                {
                    app: "pbx",
                    name: "Record",
                    link: "crm-entity-record",
                    roles: RoleAgent,
                    isDefault: true
                },
                {
                    app: "pbx",
                    name: "Association",
                    link: "crm-database-record",
                    roles: RoleAgent,
                    isDefault: false
                },
            ],
        },
        channel: {
            "AVM-IVR": [
                {
                    app: "channel",
                    name: "Register IVR",
                    link: "channel-avm-register-audio",
                    roles: RoleSuper,
                    isDefault: true
                },{
                    app: "channel",
                    name: "Send Message",
                    link: "channel-avm-send-message",
                    roles: RoleAdmin,
                    isDefault: true
                },
            ]
        },
        agenda: [
            {
                name: "Owner",
                app: "agenda",
                hasMenu: false,
                link: "agenda-owner",
                roles: RoleAdmin,
                isDefault: true
            },
            {
                name: "Category",
                app: "agenda",
                hasMenu: false,
                link: "agenda-category",
                roles: RoleAdmin,
                isDefault: false
            },
            {
                name: "Status",
                app: "agenda",
                hasMenu: false,
                link: "agenda-status",
                roles: RoleAdmin,
                isDefault: false
            },

        ],
    }
}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SET_APP_NAME:
            newState.CurrentApp = action.payload;
            localStorage.setItem("@pbx-app", action.payload);
            return Object.assign({}, newState);
        case SET_APP_MODULE:
            newState.CurrentModule = action.payload;
            localStorage.setItem("@pbx-module", action.payload);
            return Object.assign({}, newState);
        case SET_APP_MENU:
            newState.CurrentMenu = action.payload;
            localStorage.setItem("@pbx-menu", action.payload);
            return Object.assign({}, newState);
        case SET_APP_CONTENT:
            newState.CurrentMenu = action.payload;
            localStorage.setItem("@pbx-content", action.payload);
            return Object.assign({}, newState);
        case SET_APP_LINK:
            newState.CurrentLink = action.payload;
            localStorage.setItem("@pbx-link", action.payload);
            return Object.assign({}, newState);
        case SET_NAV_MENUS:
            newState.menus = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;

