import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default (props) =>{
    let {variant,color,label,type,onClick,styleIn}=props;
    const classes = useStyles();

    return (
        <div className={classes.root} style={{minWidth:"100%"}}>
            <Button
                variant={variant}
                color={color}
                onClick={()=>onClick()}
                style={styleIn}
            >
                {label}
            </Button>

        </div>
    );
}
