
import React from "react";
import {Paper} from "@material-ui/core";
import Page from "../../../layout/web/Page"
import Connector from "../../../redux/connector";
import Img404 from "../../../assets/img/page404animation.gif";
class WelcomePage extends React.Component {

    render() {
        return (
            <Page {...this.props}>
                <Paper style={styles.Paper}>
                    <img src={Img404}  alt={""} style={{minWidth:"50%",minHeight:"30%"}}/>
                </Paper>
            </Page>
        )
    }
}

export default Connector(WelcomePage)

const styles={
    Paper:{
        padding:20,
        marginTop: 10,
        marginBottom:10,
        marginLeft:10,
        minHeight:"90%",
    }
}
